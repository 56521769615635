import React, {
  FunctionComponent,
  ReactNode,
  useRef,
  useState,
  useEffect,
  AriaAttributes,
  useContext,
} from "react";
import classNames from "classnames";
import { Button } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers";
import uniqueid from "lodash.uniqueid";
import { AppContext } from "../app/store";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";

interface Props extends AriaAttributes {
  children: ReactNode;
  hasValue?: boolean;
  hideClear?: boolean;
  label: string;
  name?: string;
  onApply: () => void;
  onClear?: () => void;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  size?: "wide" | "widest";
  hideCloseButton?: boolean;
  hasError?: boolean;
  buttonClassName?: string;
  applyButtonClassName?: string;
  buttonType?: React.ComponentProps<"button">["type"];
  buttonLabelClassName?: string;
}

const CCXPBaseDrop: FunctionComponent<Props> = ({
  "aria-labelledby": ariaLabelledBy,
  applyButtonClassName,
  children,
  hasValue = false,
  hideClear = false,
  label,
  name,
  onApply,
  onClear,
  onMenuClose,
  onMenuOpen,
  size = "",
  hideCloseButton = false,
  hasError = false,
  buttonClassName,
  buttonType,
  buttonLabelClassName,
}) => {
  const [open, setOpen] = useState(false);
  const { dispatch } = useContext(AppContext);
  const menuRef = useRef<HTMLDivElement>(null);
  const t = useTranslate();

  const clickHandler = (e: MouseEvent): void => {
    const target = e.target as HTMLElement;

    if (!menuRef?.current?.contains(target)) {
      if (onMenuClose) {
        onMenuClose();
      }
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      if (onMenuOpen) {
        onMenuOpen();
      }
      document.addEventListener("click", clickHandler);
    } else {
      document.removeEventListener("click", clickHandler);
    }
  }, [open]);

  const controlLabel = label.split(" ").join("-");

  const buttonRef = useRef<HTMLButtonElement>(null);
  const buttonId = uniqueid("inner-CCXPBase-button-");

  const renderLabel = (label: string, labelClass?: string) => {
    return labelClass ? (
      <span className={labelClass}>{label}</span>
    ) : (
      <>{label} </>
    );
  };

  return (
    <div className="CCXPBase" ref={menuRef} data-name={name} data-testid={name}>
      <button
        className={classNames(
          "CCXPBase__button",
          { [`${buttonClassName}`]: !!buttonClassName },
          { "CCXPBase__button--withValue": hasValue && !open },
          { "CCXPBase__button--error": hasError && !open }
        )}
        onClick={(): void => setOpen(!open)}
        id={buttonId}
        aria-expanded={open}
        aria-labelledby={classNames(`${buttonId}`, {
          [`${ariaLabelledBy}`]: !!ariaLabelledBy,
        })}
        aria-controls={`control-${controlLabel}`}
        ref={buttonRef}
        {...(buttonType ? { type: buttonType } : {})}
      >
        {renderLabel(label, buttonLabelClassName)}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className={`ds-u-margin-left--1 ${open ? "CCXPBase__rotate" : ""}`}
        >
          <path
            fill="#323A45"
            fillRule="nonzero"
            d="M11.681 15.87l-5.549-5.39a.435.435 0 0 1 0-.624l.743-.727a.457.457 0 0 1 .637 0L12 13.48l4.488-4.35a.457.457 0 0 1 .637 0l.743.726a.435.435 0 0 1 0 .625l-5.55 5.39a.457.457 0 0 1-.637 0z"
          />
        </svg>
      </button>
      <div
        className={`CCXPBase__menu ${size ? `CCXPBase__menu--${size}` : ""} ${
          open ? "CCXPBase__menu--open" : ""
        }`}
        id={`control-${controlLabel}`}
      >
        {!hideCloseButton && (
          <div className="CCXPBase__menuButtonContainer">
            <button
              className="CCXPBase__closeMenuButton"
              onClick={(): void => {
                setOpen(false);
                buttonRef.current?.focus();
              }}
              aria-label={t("fancy_dropdown.close_filter")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  fillRule="nonzero"
                  d="M13.6 12l5.258-5.257a.485.485 0 0 0 0-.687l-.914-.914a.485.485 0 0 0-.687 0L12 10.4 6.743 5.142a.485.485 0 0 0-.687 0l-.914.914a.485.485 0 0 0 0 .687L10.4 12l-5.258 5.257a.485.485 0 0 0 0 .687l.914.914c.19.19.497.19.687 0L12 13.6l5.257 5.258c.19.19.497.19.687 0l.914-.914a.485.485 0 0 0 0-.687L13.6 12z"
                ></path>
              </svg>
            </button>
          </div>
        )}
        {children}

        <div className="CCXPBase__menuButtonContainer">
          {!hideClear && (
            <Button
              variation="ghost"
              className="ds-u-margin-top--2"
              onClick={(): void => {
                if (onClear) {
                  onClear();
                }
                dispatch({
                  type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                  settings: {
                    button: {
                      buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
                      buttonType: AnalyticsButtonType.BUTTON,
                      text: t("filter_clear"),
                    },
                  },
                });
              }}
            >
              {t("filter_clear")}
            </Button>
          )}
          <Button
            variation="solid"
            isAlternate
            className={classNames("ds-u-margin-top--2", {
              [`${applyButtonClassName}`]: !!applyButtonClassName,
            })}
            onClick={(): void => {
              setOpen(false);
              buttonRef.current?.focus();
              dispatch({
                type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                settings: {
                  button: {
                    buttonStyle: AnalyticsButtonStyle.SECONDARY,
                    buttonType: AnalyticsButtonType.BUTTON,
                    text: t("pharm_selection.apply"),
                  },
                },
              });
              onApply();
            }}
            data-testid="apply-btn"
          >
            {t("pharm_selection.apply")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CCXPBaseDrop;
