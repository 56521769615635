import { useEffect, useState } from "react";

export type BreakpointValues = {
  xs: string | null;
  sm: string | null;
  md: string | null;
  lg: string | null;
  xl: string | null;
};

/**
 * Retrieves Design System breakpoint values from CSS custom properties
 * @returns BreakpointValues
 */
export const getBreakpoints = () => {
  const breakpointTemplate: BreakpointValues = {
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
  };
  Object.keys(breakpointTemplate).forEach(key => {
    if (!breakpointTemplate[key]) {
      breakpointTemplate[key] = getComputedStyle(
        document.documentElement
      ).getPropertyValue(`--media-width-${key}`);
    }
  });
  return breakpointTemplate;
};

/**
 * Hook that retrieves Design System breakpionts from CSS custom properties.
 * Can be used with any destructured breakpiont value, e.g.
 * `const { sm } = useBreakPoints();`
 */
export const useBreakpoints = () => {
  const breakpointTemplate: BreakpointValues = {
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
  };
  const [breakpoints, setBreakpoints] = useState(breakpointTemplate);
  useEffect(() => {
    setBreakpoints(getBreakpoints());
  }, []);
  return breakpoints;
};
