import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIdleTimer, PresenceType, IIdleTimerProps } from "react-idle-timer";
import { AppContext } from "../../app/store";
import { useIsCsrSession, useLoggedIn, useLogout } from "../../helpers";
import { useLocation } from "react-router-dom";
import routes from "../../app/routes";
import {
  AnalyticsActionType,
  Ga4Event,
} from "../../app/contexts/Analytics/types";
import {
  CSR_FORCE_LOGOUT_TIMEOUT_MS,
  EVENT_LISTENER_THROTTLE_MS,
  FORCE_LOGOUT_TIMEOUT_MS,
  ONE_MINUTE_MS,
  ONE_SECOND_MS,
} from "./constants";
import { EndSessionParams, IdleTimeoutActivityState } from "./types";
import {
  endSession,
  getActivityStateFromSessionRemainingMs,
  getForceLogoutTimeoutMs,
  getTimeoutTimesFromSessionRemainingMs,
  handlePresenceChange,
  handleUserAction,
} from "./helpers";
import { LogInfo } from "./LogInfo";
import { isObject } from "../../@types/guards";
import { config } from "../../config";
import { ForceLogoutModal } from "./ForceLogoutModal";
import { usePlaywrightEnv } from "../../helpers/testUtils/usePlaywrightEnv";

/**
 * Uses `react-idle-timer` as its base (`useIdleTimer`), but, except for CSRs, uses
 * SLS session values to actually control prompting and logging out the bene.
 *
 * When the timer signals a presence state change (with some exceptions), based
 * on its own, internal timers, a call is made to SLS and timeout values updated.
 *
 * Component `activityState` values are derived from SLS's time remaining and a
 * default time before session expires to prompt the bene to extend the session
 * or log out.
 *
 * The IdleTImer also tracks user actions, triggering `keepAlive` calls to `beneinfo`.
 * SLS's values will be updated on every successful `keepAlive` call and this component
 * will be updated with those updated values the next time it sends a presence
 * change signal.
 */
export const IdleTimeout: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const {
    state: { beneficiary },
    dispatch,
  } = useContext(AppContext);
  const location = useLocation();
  const isMbpLandingRoute = location.pathname === routes.mbpLandingPage;
  const isLogoutRoute = location.pathname === routes.logout;
  const playwrightEnv = usePlaywrightEnv();
  const isCsr = useIsCsrSession();
  const logout = useLogout();
  const isLoggedIn = useLoggedIn();
  const disableIdleTimout =
    !!playwrightEnv.DISABLE_IDLE_TIMEOUT || config.DISABLE_IDLE_TIMEOUT;

  // Derived app state
  /**
   * - Run `IdleTimeout` only when logged in and not actively disabled
   * - The `mbpLandingRoute` always redirects, so don't run on that route
   * - The `logout` route should run no session management code, at all
   * - Nothing here runs on SLS callback route (@see `GlobalSessionHandler`)
   */
  const doNotRun =
    !isLoggedIn || isMbpLandingRoute || isLogoutRoute || disableIdleTimout;

  // Component state
  const [showForceLogoutModal, setShowForceLogoutModal] = useState(false);
  // start off with the timeUntilSession set to one minute over prompt time,
  // to be updated on login
  const [timeUntilSessionExpiresMs, setTimeUntilSessionExpiresMs] = useState(
    (isCsr ? CSR_FORCE_LOGOUT_TIMEOUT_MS : FORCE_LOGOUT_TIMEOUT_MS) +
      ONE_MINUTE_MS
  );

  // Derived component state
  const { idleTimeoutMs, forceLogoutTimeoutMs } =
    getTimeoutTimesFromSessionRemainingMs({ isCsr, timeUntilSessionExpiresMs });
  const promptBeforeIdle: IIdleTimerProps["promptBeforeIdle"] = useMemo(() => {
    const forceLogoutTimeoutMs = getForceLogoutTimeoutMs({ isCsr });

    return timeUntilSessionExpiresMs > forceLogoutTimeoutMs
      ? forceLogoutTimeoutMs
      : timeUntilSessionExpiresMs === 0
      ? // this value must always be less than the `timeout` value
        // timeUntilSessionExpiresMs - ONE_SECOND_MS; // see above
        timeUntilSessionExpiresMs - ONE_SECOND_MS
      : undefined;
  }, [timeUntilSessionExpiresMs, isCsr]);

  // Refs
  /**
   * References the timer for logging out after prompting the bene, which is
   * created and destroyed as needed
   */
  const logoutTimeoutStartTimeRef = useRef(Date.now());
  const timerStartedRef = useRef(false);
  const loginEffectRunRef = useRef(false);
  // Boolean `forceLogoutModalShownRef` indicates that the modal has been shown at least once
  const forceLogoutModalShownRef = useRef(false);
  // Keep the first timeout time fetched on login, to use after calling `keep-alive`
  const initialSessionTimeoutMsRef = useRef(0);

  // Timer instantiation
  const {
    getElapsedTime,
    getLastActiveTime,
    getRemainingTime,
    isIdle,
    isLeader,
    message,
    pause: pauseTimer,
    start,
  } = useIdleTimer({
    crossTab: true,
    disabled: doNotRun,
    eventsThrottle: promptBeforeIdle
      ? EVENT_LISTENER_THROTTLE_MS
      : timeUntilSessionExpiresMs,
    leaderElection: true,
    onAction: async (event: Event | undefined, idleTimer) => {
      const { shouldEndSession, shouldUpdateRemainingTime } =
        await handleUserAction({
          activityState,
          dispatch,
          event,
          idleTimer,
          isCsr,
          isLoggedIn,
        });
      if (shouldEndSession) {
        const timeUntilSessionExpiresMs = (
          await endSession({ ...endSessionParams, syncWithSls: !isCsr })
        )?.timeUntilSessionExpiresMs;
        if (typeof timeUntilSessionExpiresMs === "number") {
          updateRemainingTime(timeUntilSessionExpiresMs);
        }
      }
      if (shouldUpdateRemainingTime) {
        console.debug(
          `⏲️ keep-alive succeeded, resetting timeout to initial value of ${initialSessionTimeoutMsRef.current}`
        );
        updateRemainingTime(initialSessionTimeoutMsRef.current);
      }
    },
    onMessage: data => {
      console.debug(
        `🦉 idle timer ${
          isLeader() ? "leader " : ""
        }tab has received a message`,
        data
      );
      if (isObject(data)) {
        if (
          data["timerStarted"] &&
          data["isLoggedIn"] &&
          // a tab that hasn't logged in, getting a message from one that has
          !isLoggedIn
        ) {
          window.location.reload(); // Will log the tab in and start the timer
        }
        if (typeof data["timeUntilSessionExpiresMs"] === "number") {
          setTimeUntilSessionExpiresMs(data.timeUntilSessionExpiresMs);
        }
      }
    },
    onPresenceChange: async (presence: PresenceType) => {
      const { timeUntilSessionExpiresMs } = await handlePresenceChange({
        activityState,
        isCsr,
        isLoggedIn,
        presence,
      });
      if (typeof timeUntilSessionExpiresMs === "number") {
        updateRemainingTime(timeUntilSessionExpiresMs);
      }
    },
    promptBeforeIdle,
    // syncTimers: 200, // @TODO - Test not synchronizing timers and instead
    // relying on `message` to communicate all changes to timeout time
    timeout: timeUntilSessionExpiresMs,
  });

  // Memos
  /**
   * This value is derived solely from the SLS timeout value, and is not the
   * same (necessarily) as what the `IdleTimer` is reporting (`presence`)
   */
  const activityState = useMemo(() => {
    if (doNotRun) {
      return;
    }
    return getActivityStateFromSessionRemainingMs({
      isCsr,
      timeUntilSessionExpiresMs,
    });
  }, [doNotRun, isCsr, timeUntilSessionExpiresMs]);

  // Methods
  /**
   * Starts the `IdleTimer` and sets `timerStartedRef.current` to `true`
   */
  const startTimer = useCallback(
    (onStartTimer: () => void = () => {}) => {
      console.debug(`🐸 starting idle timer`);
      timerStartedRef.current = true;
      onStartTimer();
      start();
    },
    [start]
  );

  /**
   * Sets the time that controls `IdleTimeoutActivityState` and the timer's `timeout`
   * and `promptBeforeIdle` values. Triggers starting the timer if it hasn't started yet.
   */
  const updateRemainingTime = useCallback(
    (timeUntilSessionExpiresMs: number) => {
      setTimeUntilSessionExpiresMs(() => {
        if (!timerStartedRef.current) {
          const { idleTimeoutMs } = getTimeoutTimesFromSessionRemainingMs({
            isCsr,
            timeUntilSessionExpiresMs,
          });
          if (idleTimeoutMs > 0) {
            startTimer(() => message({ timerStarted: true, isLoggedIn }));
          }
        }
        // communicate timeout update across tabs
        message({ timeUntilSessionExpiresMs });
        return timeUntilSessionExpiresMs;
      });
    },
    [isCsr, isLoggedIn, message, startTimer]
  );

  // More memos (that depend on methods)
  const endSessionParams: EndSessionParams = useMemo(
    () => ({
      beneficiary,
      forceLogoutModalShownRef,
      forceLogoutTimeoutMs,
      getLastActiveTime,
      idleTimeoutMs,
      isCsr,
      isLoggedIn,
      logout,
      // Multiple tabs with synchronized timers should all log out
      onBeforeLogout: () => {
        updateRemainingTime(0);
        pauseTimer();
      },
    }),
    [
      beneficiary,
      forceLogoutTimeoutMs,
      getLastActiveTime,
      idleTimeoutMs,
      isCsr,
      isLoggedIn,
      logout,
      pauseTimer,
      updateRemainingTime,
    ]
  );

  // Effects
  /**
   * 1. Watch for login (`isLoggedIn`) and kick off the timer
   * This should only run when `isLoggedIn` changes, the bene is logged in, and
   * the timer hasn't been started
   */
  useEffect(() => {
    (async () => {
      if (
        doNotRun ||
        timerStartedRef.current ||
        loginEffectRunRef.current ||
        activityState !== IdleTimeoutActivityState.ACTIVE
      ) {
        return;
      }
      console.debug(
        `🐣 ${
          isLeader() ? "Leader tab " : ""
        }effect for starting session timer called, activityState is ${activityState}`
      );
      loginEffectRunRef.current = true;
      const response = await handlePresenceChange({
        activityState,
        isCsr,
        isLoggedIn,
        presence: { type: "active", prompted: false },
      });
      if (typeof response?.timeUntilSessionExpiresMs === "number") {
        // Set `iniitialSessionTimeoutMs` to the full value provided after login (round up)
        initialSessionTimeoutMsRef.current =
          Math.round(response.timeUntilSessionExpiresMs / 1000 / 60) *
          1000 *
          60;
        updateRemainingTime(response.timeUntilSessionExpiresMs);
      }
    })();
  }, [
    activityState,
    doNotRun,
    endSessionParams,
    isCsr,
    isLeader,
    isLoggedIn,
    message,
    updateRemainingTime,
  ]);

  // 2. IdleTimeoutActivityState change effects
  /**
   * Show the modal when the state changes to prompt
   */
  useEffect(() => {
    if (!activityState) {
      return;
    }
    setShowForceLogoutModal(activityState === IdleTimeoutActivityState.PROMPT);
  }, [activityState]);

  /**
   * End session when the user's gone idle
   */
  useEffect(() => {
    if (!activityState) {
      return;
    }
    // idle activity state is derived from a remaining session time of 0
    if (activityState === IdleTimeoutActivityState.IDLE) {
      console.debug(
        `🚙 User has gone idle; endSession called with params`,
        endSessionParams
      );
      endSession(endSessionParams);
    }
  }, [activityState, endSessionParams]);

  /**
   * 3. Do things when the force logout modal is shown
   */
  useEffect(() => {
    if (!showForceLogoutModal) {
      return;
    }
    dispatch({
      type: AnalyticsActionType.SEND_GA4_EVENT,
      settings: {
        event_name: Ga4Event.SHOW_IDLE_SESSION_MODAL,
      },
    });

    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_label: "mct_plan_finder_show_idle_session_modal",
        event_action: "idle session - modal impression",
        other_props: {
          beneficiary_key: beneficiary?.meta_data.beneficiary_key,
          csr_id: beneficiary?.csr_id,
        },
      },
    });

    forceLogoutModalShownRef.current = true;
    logoutTimeoutStartTimeRef.current = Date.now();
  }, [
    showForceLogoutModal,
    beneficiary?.csr_id,
    beneficiary?.meta_data.beneficiary_key,
    dispatch,
  ]);

  /**
   * Debugging activity state changes
   */
  useEffect(() => {
    if (activityState) {
      console.debug(`🛎️ activityState change to ${activityState}`);
    }
  }, [activityState]);

  /**
   * Debugging time until session expires
   */
  useEffect(() => {
    if (doNotRun) {
      return;
    }
    const { idleTimeoutMs, forceLogoutTimeoutMs, sessionExpired } =
      getTimeoutTimesFromSessionRemainingMs({
        isCsr,
        timeUntilSessionExpiresMs,
      });
    console.debug(
      `⏳ Time until session expires has changed. New timeout values: idleTimeoutMs: ${idleTimeoutMs}, forceLogoutTimeoutMs: ${forceLogoutTimeoutMs}, sessionExpired: ${sessionExpired}`
    );
  }, [doNotRun, isCsr, timeUntilSessionExpiresMs]);

  return doNotRun ? (
    <>{children}</>
  ) : (
    <>
      {config.FORCE_LOGOUT_TIMEOUT_INFO && (
        <LogInfo
          activityState={activityState}
          isIdle={isIdle}
          getElapsedTime={getElapsedTime}
          getLastActiveTime={getLastActiveTime}
          getRemainingTime={getRemainingTime}
          isLoggedIn={isLoggedIn}
          logoutTimeoutStartTime={logoutTimeoutStartTimeRef.current}
          promptBeforeIdle={promptBeforeIdle || 0}
          forceLogoutTimeoutMs={forceLogoutTimeoutMs}
          showForceLogoutModal={showForceLogoutModal}
        />
      )}
      {/* The "Your session will expire" prompt */}
      {showForceLogoutModal && (
        <ForceLogoutModal
          forceLogoutTimeoutMs={forceLogoutTimeoutMs}
          initialSessionTimeoutMsRef={initialSessionTimeoutMsRef}
          message={message}
          pauseTimer={pauseTimer}
          setShowForceLogoutModal={setShowForceLogoutModal}
          updateRemainingTime={updateRemainingTime}
        />
      )}
      {children}
    </>
  );
};
