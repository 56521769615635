import React, { useState, ChangeEvent } from "react";
import { Button, Dropdown } from "@cmsgov/ds-medicare-gov";
import { useAppContext, useTranslate } from "../helpers";
import {
  DropdownProps,
  DropdownOptions,
} from "@cmsgov/design-system/dist/types/Dropdown/Dropdown";
import { ButtonProps } from "@cmsgov/design-system/dist/types/Button/Button";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";
import classnames from "classnames";
import reactNodeToString from "react-node-to-string";

export interface SelectWithButtonProps
  extends Omit<
      DropdownProps,
      "children" | "onClick" | "className" | "onChange" | "ref"
    >,
    Partial<Pick<ButtonProps, "children">>,
    Pick<React.ComponentPropsWithoutRef<"div">, "className"> {
  buttonClassName?: string;
  onSelectApplied: (value: DropdownOptions["value"]) => void;
}

export const SelectWithButton = ({
  buttonClassName,
  children: buttonText,
  className = "",
  fieldClassName,
  labelClassName,
  label,
  name,
  onSelectApplied = () => {},
  options,
  ...props
}: SelectWithButtonProps) => {
  const t = useTranslate();
  const { dispatch } = useAppContext();

  const firstOption = options[0];

  // * State
  const [appliedValue, setAppliedValue] = useState(firstOption.value);
  const [fieldValue, setFieldValue] = useState(firstOption.value);
  const disableButton = fieldValue === appliedValue;

  const buttonTextValue = buttonText || t("select");
  const baseComponentClassName = "mct-c-select-with-button";
  const defaultSelectFieldClassName = `${baseComponentClassName}__select`;
  const defaultDrowdownComponentClassName = `${baseComponentClassName}__dropdown`;
  const defaultButtonClassName = `${baseComponentClassName}__button`;

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(event.target.value);
  };

  const updateAppliedValue = (value: DropdownOptions["value"]) => {
    setAppliedValue(value);
    onSelectApplied(value);
  };

  const onClickHandler = (): void => {
    updateAppliedValue(fieldValue);
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
          buttonType: AnalyticsButtonType.BUTTON,
          text: reactNodeToString(buttonTextValue),
        },
      },
    });
  };

  return (
    <div className={classnames(baseComponentClassName, className)} role="group">
      <Dropdown
        className={defaultDrowdownComponentClassName}
        value={fieldValue}
        fieldClassName={classnames(defaultSelectFieldClassName, fieldClassName)}
        label={label || t("select_an_option")}
        labelClassName={labelClassName}
        name={name}
        onChange={onChangeHandler}
        options={options}
        {...props}
      />
      <Button
        className={classnames(defaultButtonClassName, buttonClassName)}
        disabled={disableButton}
        isAlternate
        onClick={onClickHandler}
        variation="solid"
      >
        {buttonTextValue}
      </Button>
    </div>
  );
};
