import React, { useContext } from "react";
import { Envs, SearchResultPlan } from "../../@types";
import {
  getEnvironment,
  isMMPlanCategory,
  makePlanDisplayId,
  PreviewContext,
  useTranslate,
} from "../../helpers";
import { StarRating } from "..";
import { AppStore } from "../../app/store";
import { getPlanName } from "../../helpers/planHelpers";

interface PlanCardHeaderProps {
  plan: SearchResultPlan;
}

const PlanCardHeader: React.FunctionComponent<PlanCardHeaderProps> = ({
  plan,
}) => {
  const t = useTranslate();
  const {
    state: { language },
  } = useContext(AppStore.AppContext);
  const previewMode = useContext(PreviewContext);
  const isIMPEnvironment = getEnvironment() === Envs.imp;

  return (
    <div className="ds-u-margin-bottom--3">
      <h2
        id={`${plan.id}-heading`}
        className="PlanCard__header"
        data-cy="plan-card-header"
      >
        {getPlanName(plan, language)}
      </h2>
      <div className="PlanCard__sub_header">
        <span>{plan.organization_name}</span>
        <span aria-hidden className="ds-u-color--gray-light">
          {" "}
          |{" "}
        </span>
        <span>
          {t("plan_terms.plan_id")} {makePlanDisplayId(plan)}
        </span>
      </div>
      {(!previewMode || isIMPEnvironment) &&
        !isMMPlanCategory(plan.category) && (
          <StarRating
            lowPerforming={plan.low_performing}
            highPerforming={plan.high_performing}
            stars={plan.overall_star_rating}
            plan={plan}
            planYear={plan.contract_year}
          />
        )}
    </div>
  );
};

export default PlanCardHeader;
