import URI from "urijs";
import {
  Envs,
  LocationWithOnlyPathnameRequired,
  LocationWithOptionalState,
  ManagedQueryParameters,
  QueryStringLanguage,
  UserLanguage,
} from "../@types";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { planComparePath } from "./CONSTANTS";

export const getEnvironment = (): Envs => {
  const hostname = window.location.hostname;
  if (
    hostname.includes("localhost") ||
    hostname.includes("127.0.0.1") ||
    hostname.includes("0.0.0.0")
  ) {
    return Envs.local;
  }

  if (hostname.includes("test")) {
    return Envs.test;
  }

  if (hostname.includes("dev")) {
    return Envs.dev;
  }

  if (hostname.includes("imp")) {
    return Envs.imp;
  }

  return Envs.prod;
};

export const getLinkWithLanguage = (
  url: string,
  language: UserLanguage
): string => {
  return `https://${language === UserLanguage.SPANISH ? "es" : "www"}.${url}`;
};

export const getLinkWithLanguageAndEnvironment = (
  url: string,
  language: UserLanguage
): string => {
  const environment = getEnvironment();
  const subdomainMap = {
    [Envs.local]: "test.",
    [Envs.test]: "test.",
    [Envs.dev]: "dev.",
    [Envs.imp]: "imp.",
    [Envs.prod]: "www.",
  };
  const subdomain = subdomainMap[environment];
  const link = new URI(`https://${subdomain}${url}`);

  link.addQuery("lang", userToQueryStringLangMap[language]);

  return link.toString();
};

export const userToQueryStringLangMap: Record<
  UserLanguage,
  QueryStringLanguage
> = {
  [UserLanguage.ENGLISH]: QueryStringLanguage.ENGLISH,
  [UserLanguage.SPANISH]: QueryStringLanguage.SPANISH,
};

/**
 * Takes a React Router location object and converts it to a URIJS object, retaining
 * the `pathname`, `search`, and `hash` values. Does not preserve `location.state`.
 *
 * Only `pathname` is required in the location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 */
export const convertLocationToUri = (
  location: LocationWithOnlyPathnameRequired
): ReturnType<typeof URI> =>
  new URI(location.pathname)
    .search(location?.search || "")
    .hash(location?.hash || "");

/**
 * Takes a URIJS object and converts it to a React Router location object, retaining
 * the `pathname`, `search`, and `hash` values
 */
export const convertUriToLocation = (
  uri: URI
): Omit<RouteComponentProps["location"], "state"> => ({
  pathname: uri.pathname(),
  search: uri.search(),
  hash: uri.hash(),
});

/**
 * Takes a React Router location object and converts it to a URIJS object, retaining
 * the `pathname`, `search`, and `hash` values, and also stripping managed query
 * parameters (`lang` and `year`) from the `search` value
 *
 * Only `pathname` is required in the location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 */
export const convertLocationToUriStripManagedParams = (
  location: LocationWithOnlyPathnameRequired
): ReturnType<typeof URI> => {
  const uri = convertLocationToUri(location);
  uri.removeSearch(Object.values(ManagedQueryParameters));
  return uri;
};

/**
 * Takes current a location object, and returns a URL string that has managed query
 * params (`lang` and `year`) stripped out.
 *
 * Only `pathname` is required in the location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 *
 * The returned string will contain `pathname`, `hash`, `search`, depending on what
 * was passed in
 */
export const convertLocationToStringStripManagedParams = (
  location: LocationWithOnlyPathnameRequired
): string => convertLocationToUriStripManagedParams(location).valueOf();

/**
 * Takes current a location object, and returns a modified object that has managed
 * query params (`lang` and `year`) stripped out.
 *
 * Only `pathname` is required in the location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 *
 * The returned object or will contain `pathname`, `hash`, `search`, and
 * optionally `state` and/or `key`, depending on what was passed in
 */
export const stripManagedQueryParamsFromLocation = (
  location: LocationWithOnlyPathnameRequired
): LocationWithOptionalState => {
  const uri = convertLocationToUriStripManagedParams(location);
  return {
    ...location,
    search: uri.search(),
    hash: uri.hash(), // normalizes hash, prefixed with `#`
  };
};

/**
 * If a `returnTo` value is set in query (search) params, this hook will return
 * it. Useful to control or override where a "Back" button will take someone (as
 * opposed to the browser's back button)
 *
 * The `returnTo` URL string (`pathname` plus `search` and `hash`, if there are any)
 * will have managed query params (`lang` and `year`) stripped out from the `search`
 * portion of the string. This is to prevent locking down values that can be changed
 * by a user on any page (or a limited set of pages, in the case of `year`)
 *
 * Note: This would be cleaner if it used `history.push.state`, but that option
 * does not exist when using `HashRouter`
 */
export const useReturnTo = () => {
  const location = useLocation();
  const uri = convertLocationToUriStripManagedParams(location);
  const { returnTo } = uri.search(true);
  const hash = uri.hash();
  const [returnToVal, setReturnToVal] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (returnTo) {
      setReturnToVal(`${returnTo}${hash}`);
    }
  }, [returnTo, hash]);
  return returnToVal;
};

/**
 * Takes current and link target location objects, and creates modified target location
 * in which the `search` string contains a `returnTo` URL that will bring the user
 * back to the place where they used the link
 *
 * Only `pathname` is required in either location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 */
export const createTargetLocationWithReturnTo = ({
  currentLocation,
  targetLocation,
}: {
  currentLocation: LocationWithOnlyPathnameRequired;
  targetLocation: LocationWithOnlyPathnameRequired;
}): LocationWithOptionalState => {
  const returnToUri = convertLocationToUriStripManagedParams(currentLocation);
  const targetUriSearch = URI(targetLocation.search || "");
  targetUriSearch.addSearch("returnTo", returnToUri);
  const search = targetUriSearch.search();
  return {
    ...targetLocation,
    hash: targetLocation.hash || "",
    search,
  };
};

export const getIsInternalAnchorLinkHref = (href: string) => {
  const env = getEnvironment();
  const isLocal = env === Envs.local;
  // link is internal to MCT and contains two hashes - Aka, a named anchor
  const internalAnchorLinkRe = new RegExp(
    `.*${isLocal ? location.hostname : planComparePath}/#.*#`
  );
  return internalAnchorLinkRe.test(href);
};

/**
 * Gets the value to explicitly set as the `referrer` header in MCTAPI
 * requests. This value must end in a trailing slash for an API key to be generated.
 * Along with setting `referrerPolicy` to `strict-origin-when-cross-origin`, we
 * hope to minimize or prevent the `referer` (sic) header from being truncated (i.e.,
 * the pathname being removed), which causes 401 errors
 */
export const getReferrer = () => {
  let referrer = `${window.location.origin}${window.location.pathname}`;
  if (referrer.charAt(referrer.length - 1) !== "/") {
    referrer = `${referrer}/`;
  }
  return referrer;
};
