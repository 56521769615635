import React, { FunctionComponent, ChangeEvent } from "react";
import { Alert, Choice } from "@cmsgov/ds-medicare-gov";
import { CoverageType } from "../@types";
import { useTranslate } from "../helpers";
import { coverageTypeIdMappings } from "../helpers";

interface PlanQuestionsProps {
  handleChoice: (e: ChangeEvent<HTMLInputElement>) => void;
  coverageSelection: CoverageType;
  displayPlanDescription?: boolean;
}

export const PlanQuestions: FunctionComponent<PlanQuestionsProps> = ({
  handleChoice,
  coverageSelection,
  displayPlanDescription = false,
}) => {
  const t = useTranslate();

  const mapdSelected = coverageSelection === CoverageType.MAPD;
  const pdpSelected = coverageSelection === CoverageType.PDP;
  const medigapSelected = coverageSelection === CoverageType.MedigapPolicy;

  return (
    <>
      <Choice
        type="radio"
        name="what-coverage"
        id={coverageTypeIdMappings[CoverageType.MAPD]}
        value={CoverageType.MAPD}
        onChange={handleChoice}
        checked={mapdSelected}
        checkedChildren={
          displayPlanDescription && (
            <Alert
              role="alert"
              hideIcon={true}
              data-testid={coverageTypeIdMappings[CoverageType.MAPD]}
            >
              {t("landing_page.coverage_selection.mapd.text")}
            </Alert>
          )
        }
        label={t("coverage_selector.help_drawer.part_c.sub_heading")}
      />

      <Choice
        type="radio"
        name="what-coverage"
        id={coverageTypeIdMappings[CoverageType.PDP]}
        value={CoverageType.PDP}
        onChange={handleChoice}
        checked={pdpSelected}
        checkedChildren={
          displayPlanDescription && (
            <Alert
              role="alert"
              hideIcon={true}
              data-testid={coverageTypeIdMappings[CoverageType.PDP]}
            >
              {t("landing_page.coverage_selection.pdp.text")}
            </Alert>
          )
        }
        label={t("coverage_selector.help_drawer.part_d.sub_heading")}
      />

      <Choice
        type="radio"
        name="what-coverage"
        id={coverageTypeIdMappings[CoverageType.MedigapPolicy]}
        value={CoverageType.MedigapPolicy}
        onChange={handleChoice}
        checked={medigapSelected}
        checkedChildren={
          displayPlanDescription && (
            <Alert
              role="alert"
              hideIcon={true}
              data-testid={coverageTypeIdMappings[CoverageType.MedigapPolicy]}
            >
              {t("landing_page.coverage_selection.medigap.text")}
            </Alert>
          )
        }
        label={t("coverage_selector.help_drawer.medigap.sub_heading")}
      />
    </>
  );
};
