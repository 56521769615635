import { useCallback, useMemo } from "react";
import { useAppContext } from "./useAppContext";
import { ActionType, EnrollFormData, Gender, Plan, Sex } from "../../@types";
import { MCTDateFromString } from "../dateHelpers";
import {
  EnrollmentPages,
  emptyEnrollData,
  getValidationObject,
} from "../enrollmentFormHelpers";
import states from "../states";
import { useFlags } from "launchdarkly-react-client-sdk";

export const useEnrollmentData = () => {
  // * Context
  const {
    state: { enrollData, beneficiary, zipcode, county, selectedPlan },
    dispatch,
  } = useAppContext();

  // * Flags
  const flags = useFlags();

  // * Constants
  const medicareNumber = beneficiary?.mbi || "";
  const uninitializedBene = medicareNumber && !enrollData.medicareNumber;
  const uninitializedZip = zipcode && !enrollData.zip;
  const uninitializedCounty = county && !enrollData.county;
  const hasUninitializedEnrollData =
    uninitializedBene || uninitializedZip || uninitializedCounty;

  const initialEnrollData: EnrollFormData = useMemo(() => {
    // TODO: This logic can be condensed and cleaned up.
    // TODO: It would be a good idea to remove beneficiary as a dependency and use only what we need in the array.
    let defaultEnrollData: EnrollFormData = emptyEnrollData;

    if (beneficiary) {
      let sex = Sex.NONE;

      if (beneficiary.gender === Gender.FEMALE) {
        sex = Sex.FEMALE;
      } else if (beneficiary.gender === Gender.MALE) {
        sex = Sex.MALE;
      }

      let address1 = "";
      let address2 = "";
      let city = "";
      let state = "";

      if (beneficiary.mailing_address) {
        const {
          address_1,
          address_2,
          city: beneCity,
          state: beneState,
        } = beneficiary.mailing_address;
        address1 = address_1;
        address2 = address_2;
        city = beneCity;

        const stateObj = states.find(state => state.abbreviation === beneState);

        if (stateObj) {
          state = stateObj.fipsCode;
        }
      }

      defaultEnrollData = {
        ...defaultEnrollData,
        firstName:
          beneficiary.firstname && beneficiary.firstname.length
            ? beneficiary.firstname[0]
            : "",
        middleInitial:
          beneficiary.firstname.length > 1 && beneficiary.firstname[1].length
            ? beneficiary.firstname[1][0].trim()
            : "",
        lastName: beneficiary.lastname,
        dob: MCTDateFromString(beneficiary.birthdate),
        medicareNumber,
        ssn: {
          areaNumber: beneficiary.ssn?.substring(0, 3) || "",
          groupNumber: beneficiary.ssn?.substring(3, 5) || "",
          serialNumber: beneficiary.ssn?.substring(5, 9) || "",
        },
        sex,
        address1,
        address2,
        city,
        state,
        email: beneficiary.email || "",
      };
    }

    if (zipcode) {
      defaultEnrollData = {
        ...defaultEnrollData,
        zip: zipcode,
        county,
      };
    }
    return { ...defaultEnrollData };
  }, [beneficiary, county, medicareNumber, zipcode]);

  // * Callbacks
  const setEnrollData = useCallback(
    (payload: EnrollFormData) => {
      dispatch({
        type: ActionType.UPDATE_ENROLL_DATA,
        payload: {
          ...payload,
        },
      });
    },
    [dispatch]
  );

  const resetEnrollDataWithConfirmation = useCallback(
    (confirmationNumber: string) => {
      const { firstName, lastName } = enrollData;
      setEnrollData({
        ...initialEnrollData,
        firstName,
        lastName,
        confirmationNumber,
      });
    },
    [enrollData, initialEnrollData, setEnrollData]
  );

  const updateEnrollData = useCallback(
    (payload: Partial<EnrollFormData>) => {
      setEnrollData({
        ...enrollData,
        ...payload,
      });
    },
    [enrollData, setEnrollData]
  );

  const initializeEnrollData = useCallback(() => {
    setEnrollData(initialEnrollData);
  }, [initialEnrollData, setEnrollData]);

  const getEnrollmentValidation = useCallback(
    (enrollmentPage: EnrollmentPages) => {
      return getValidationObject(
        enrollData,
        selectedPlan as Plan,
        enrollmentPage,
        flags
      );
    },
    [enrollData, flags, selectedPlan]
  );

  return {
    enrollData,
    /** returns validation object for a specific enrollment page. */
    getEnrollmentValidation,
    /** resets `enrollData` to clear out all information except data from beneficiary and zip/county */
    initializeEnrollData,
    /** sets `enrollData` context with full EnrollFormData object. */
    setEnrollData,
    /** adds confirmation number to the EnrollFormData object, while clearing everything else. */
    resetEnrollDataWithConfirmation,
    /** updates `enrollData` context with partial object. */
    updateEnrollData,
    /** if true, we should initialize the data */
    hasUninitializedEnrollData,
  };
};
