import React, { FunctionComponent, useContext } from "react";
import { hasGlobalSession, getRegistrationUrl } from "../helpers/loginHelpers";
import { useTranslate } from "../helpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LoginLogoutButton } from ".";
import { AppStore } from "../app/store";
import { AnalyticsActionType } from "../app/contexts/Analytics/types";

const LoggedOutFooter: FunctionComponent = () => {
  const t = useTranslate();
  const { feEnableAuthentication } = useFlags();
  const {
    state: { language },
    state,
    dispatch,
  } = useContext(AppStore.AppContext);
  const isLoggedIn = hasGlobalSession(state);

  return !isLoggedIn && feEnableAuthentication ? (
    <div className="LoggedOutFooter">
      <h2 className="ds-text-heading--xl">{t("logged_out_footer.header")}</h2>
      <p className="ds-u-color--gray">
        {t("logged_out_footer.when_logged_in")}
      </p>
      <ul>
        <li>{t("logged_out_footer.feature_list.1")}</li>
        <li>{t("logged_out_footer.feature_list.2")}</li>
        <li>{t("logged_out_footer.feature_list.3")}</li>
        <li>{t("logged_out_footer.feature_list.4")}</li>
      </ul>
      <div className="LoggedOutFooter__actions">
        <LoginLogoutButton
          className="LoggedOutFooter__login"
          onClick={(): void => {
            dispatch({
              type: AnalyticsActionType.SEND_TEALIUM_EVENT,
              settings: {
                event_action: "Routing",
                event_label: "Log in",
              },
            });
          }}
        />
        <span>
          {t("logged_out_footer.no_account")}{" "}
          <a
            href={getRegistrationUrl(language)}
            className="LoggedOutFooter__createLink"
            onClick={(): void => {
              dispatch({
                type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                settings: {
                  event_action: "Routing",
                  event_label: "Create account",
                },
              });
            }}
          >
            {t("logged_out_footer.create")}
          </a>
        </span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default LoggedOutFooter;
