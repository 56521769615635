import React from "react";
import { Alert } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../../helpers";
import { CoverageSelectorErrorState } from "../../@types";

export const CoverageSelectorAlert: React.FunctionComponent<{
  errorState: {
    zipcode?: CoverageSelectorErrorState;
    planType?: CoverageSelectorErrorState;
    counties?: CoverageSelectorErrorState;
  };
}> = ({ errorState }) => {
  const t = useTranslate();
  return (
    <>
      {(errorState.planType || errorState.zipcode || errorState.counties) && (
        <div data-testid="alert" className="ds-u-margin-bottom--2">
          <Alert
            heading={t("coverage_selector.alert.heading")}
            variation="error"
            className="mct-c-coverage-selector-v2__alert"
          >
            <span>{t("coverage_selector.alert.message")}</span>
          </Alert>
        </div>
      )}
    </>
  );
};
