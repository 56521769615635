import { useEffect, useRef } from "react";

export const useAddRequired = (
  cb: (input: HTMLInputElement) => void = () => {},
  cleanup: (input: HTMLInputElement) => void = () => {}
): React.MutableRefObject<HTMLInputElement | null> => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    if (!inputRef.current.required) {
      inputRef.current.required = true;
    }
    cb(inputRef.current);
    return () => {
      inputRef.current && cleanup(inputRef.current);
    };
  }, [inputRef.current]);
  return inputRef;
};
