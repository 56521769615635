import {
  Beneficiary,
  PrescriptionFrequency,
  LowIncomeSubsidyStatus,
  Gender,
  NewToMedicareStatus,
  PlanType,
} from "../@types";

const bene: Beneficiary = {
  request_id: "007E5EE1-6596D12F-58278701-D6391DC8",
  firstname: ["Jane", "X"],
  lastname: "Doe",
  birthdate: "2014-06-01",
  part_a_start: "2015-10-10",
  part_b_start: "2016-11-11",
  activity_id: "987",
  csr_id: "678",
  lis_level: LowIncomeSubsidyStatus.LIS_LEVEL_2,
  future_lis_level: LowIncomeSubsidyStatus.LIS_LEVEL_4_50,
  coverage_current: [
    {
      contract_effective_date: "2020-09-05",
      contract_id: "H1225",
      coverage_year: "2019",
      disenrollment_date: "2020-09-05",
      enrollment_effective_date: "2020-09-05",
      name_esp: "Un Plan Elegante",
      name: "A Spiffy Plan",
      plan_id: "002",
      segment_id: "0",
      plan_type: PlanType.PDP,
    },
  ],
  coverage_future: [],
  employer_coverage_current: [],
  employer_coverage_future: [],
  future_lis_status_start_date: "2021-10-16",
  pdes: [
    {
      pde_status: "",
      drug: {
        rxcui: "1234",
        ndc: "12345",
        name: "Amoxicillin - AMOXICILLIN",
        strength: "500",
        strength_units: "mg",
        insulin: false,
        quantity: "30",
        package_type: "PACKAGE_TYPE_BOTTLE",
        package_size: "1",
        package_units: "30",
        form: "DRUG_FORM_CAPSULE",
        frequency: PrescriptionFrequency.DAYS_30,
        is_generic: true,
        package_quantity: "2",
        use_package_quantity: true,
        drug_pap_id: "",
      },
    },
    {
      pde_status: "",
      drug: {
        rxcui: "2345",
        ndc: "23456",
        name: "Ibuprofen - IBUPROFEN",
        quantity: "60",
        package_type: "PACKAGE_TYPE_BLISTER",
        package_size: "1",
        insulin: false,
        package_units: "60",
        strength: "200",
        strength_units: "mg",
        form: "DRUG_FORM_TABLET",
        frequency: PrescriptionFrequency.DAYS_30,
        is_generic: true,
        package_quantity: "2",
        use_package_quantity: false,
        drug_pap_id: "",
      },
    },
    {
      pde_status: "",
      drug: {
        rxcui: "3456",
        ndc: "34567",
        name: "Wonder Drug",
        quantity: "60",
        package_type: "PACKAGE_TYPE_BLISTER",
        package_size: "1",
        package_units: "60",
        insulin: false,
        strength: "200",
        strength_units: "mg",
        form: "DRUG_FORM_TABLET",
        frequency: PrescriptionFrequency.DAYS_30,
        is_generic: true,
        package_quantity: "2",
        use_package_quantity: false,
        drug_pap_id: "",
      },
    },
  ],
  plans: ["99999-999"],
  mbi: "23456789",
  hicn: "HICN",
  mailing_address: {
    address_1: "28 BIMINI",
    address_2: "APT 3211",
    address_3: "",
    city: "SUGAR LAND",
    fips_state_county: "48530",
    state: "TX",
    zipcode: "21224",
  },
  gender: Gender.UNKNOWN,
  ssn: "701512806",
  email: "jane.doe@email.com",
  new_to_medicare: NewToMedicareStatus.NO,
  meta_data: {
    beneficiary_key: "1234567890",
    last_login_date: "01-01-1970",
    is_logged_in: true,
    is_csr: false,
    is_synthetic: false,
  },
  medicare_info: {
    new_to_medicare: NewToMedicareStatus.NO,
    iep_start_date: "2004-01-12",
    iep_end_date: "2004-07-12",
    early_beneficiary: false,
    part_a: {
      start_date: "2020-09-01",
      stop_date: "2021-05-31",
    },
    part_b: {
      start_date: "2020-09-01",
      stop_date: "2021-07-31",
    },
  },
};

export default bene;
