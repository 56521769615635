import {
  PlanType,
  SearchResultPlan,
  PlanCategoryType,
  PlanSNPType,
  StarRatingError,
  PlanEnrollmentStatus,
  PackageServiceAvailability,
} from "../@types";

const searchResultPlan = (
  attrs: Record<string, unknown> = {}
): SearchResultPlan => ({
  enrollment_opt_in_status: true,
  remaining_premium_and_drugs: 1249.38,
  msa_annual_deposit: 3240,
  enrollment_status: PlanEnrollmentStatus.AVAILABLE,
  id: 1589,
  name: "Harvard Pilgrim Stride Basic Rx (HMO)",
  name_esp: "",
  url: "www.harvardpilgrim.org/medicare",
  contract_year: "2019",
  contract_id: "H1660",
  plan_id: "015",
  segment_id: "0",
  organization_name: "Harvard Pilgrim Health Care, Inc.",
  plan_type: PlanType.MAPD,
  category: PlanCategoryType.HMO,
  does_not_support_mail_order: false,
  annual_deductible: 0,
  drug_plan_deductible: 415,
  partb_premium_reduction: 0,
  partc_premium: 210,
  partd_premium: 90,
  redactions: [],
  package_services: {
    doctor_choice: PackageServiceAvailability.PLAN_DOCTORS_MOST_SERVICES,
    outpatient_prescription: PackageServiceAvailability.PROVIDED,
    additional_physical_exams: PackageServiceAvailability.PROVIDED,
    dental_services: PackageServiceAvailability.NOT_PROVIDED,
    vision_services: PackageServiceAvailability.PROVIDED,
    hearing_services: PackageServiceAvailability.PROVIDED,
    ms_hearing_services: true,
    ms_dental_services: false,
  },
  silver_sneakers: true,
  lis: {
    level_100: 100,
    level_75: 75,
    level_50: 50,
    level_25: 25,
  },
  overall_star_rating: {
    category: "STAR_OVERALL_RATING",
    rating: 3.5,
    error: StarRatingError.STAR_ERROR_NO_ERROR,
  },
  low_performing: true,
  high_performing: false,
  annual_drugs_total: 30,
  emergency_care_cost: "$60",
  primary_doctor_visit_cost: "$20 copay",
  specialist_doctor_visit_cost: "$30 copay",
  maximum_oopc: "$2000",
  covered_drugs: 4,
  pharmacies: [
    { in_network: true, npi: "123" },
    { in_network: false, npi: "456" },
  ],
  transportation: true,
  snp_type: PlanSNPType.SNP_TYPE_NOT_SNP,
  otc_drugs: true,
  worldwide_emergency: false,
  telehealth: true,
  in_home_support: false,
  home_safety_devices: true,
  emergency_response_device: false,
  total_remaining_premium: 12.6,
  calculated_monthly_premium: 42.4,
  remaining_premium_and_drugs_mail_order: 496.8,
  remaining_premium_and_drugs_retail: 375.7,
  ...attrs,
});

export default searchResultPlan;
