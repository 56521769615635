import React, { ReactHTML } from "react";
import { CheckmarkIcon } from "..";
import { useTranslate } from "../../helpers";
import { TranslationKey } from "../../helpers/intlHooks";

interface PlanBenefitCheckmarkProps {
  phraseKey: TranslationKey;
  checked: boolean;
  WrapperEl?: keyof ReactHTML;
}

export const PlanBenefitCheckmark = ({
  phraseKey,
  checked,
  WrapperEl = "div",
}: PlanBenefitCheckmarkProps) => {
  const t = useTranslate();

  return (
    <WrapperEl>
      <CheckmarkIcon checked={checked} /> {t(phraseKey)}
      <span className="ds-u-visibility--screen-reader">
        {checked
          ? ` ${t("plan_card.benefit.is_available")}`
          : ` ${t("plan_card.benefit.is_not_available")}`}
      </span>
    </WrapperEl>
  );
};
