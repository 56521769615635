import React, { useContext } from "react";
import { SearchResultPlan } from "../../@types";
import { isAvailable, useTranslate, PreviewContext } from "../../helpers";
import CollapsibleBox from "../CollapsibleBox";
import { PlanBenefitCheckmark } from "./PlanBenefitCheckmark";
import { hasDentalService } from "../../helpers/planBenefitHelpers";

interface PlanBenefitsProps {
  plan: SearchResultPlan;
}

const PlanBenefits: React.FunctionComponent<PlanBenefitsProps> = ({ plan }) => {
  const t = useTranslate();
  const previewMode = useContext(PreviewContext);
  const {
    package_services: services,
    transportation,
    silver_sneakers,
    worldwide_emergency,
    telehealth,
    otc_drugs,
    in_home_support,
    home_safety_devices,
    emergency_response_device,
    contract_year,
  } = plan;

  if (!services) {
    return null;
  }

  return (
    <div className="PlanCard__section" data-testid="benefits">
      <h3 className="PlanCard__section_heading">
        {t("plan_card.section_heading.plan_benefits")}
      </h3>
      <ul className="PlanCard__benefits">
        <PlanBenefitCheckmark
          WrapperEl="li"
          phraseKey="plan_terms.vision"
          checked={isAvailable(services.vision_services)}
        />
        <PlanBenefitCheckmark
          WrapperEl="li"
          phraseKey="plan_terms.dental"
          checked={hasDentalService(+contract_year, services)}
        />
        <PlanBenefitCheckmark
          WrapperEl="li"
          phraseKey="plan_terms.hearing"
          checked={services.ms_hearing_services}
        />
        <PlanBenefitCheckmark
          WrapperEl="li"
          phraseKey="plan_terms.transportation"
          checked={transportation}
        />
        <PlanBenefitCheckmark
          WrapperEl="li"
          phraseKey="plan_terms.fitness_benefits"
          checked={silver_sneakers}
        />
        <PlanBenefitCheckmark
          WrapperEl="li"
          phraseKey="plan_terms.worldwide_emergency"
          checked={worldwide_emergency}
        />
        <PlanBenefitCheckmark
          WrapperEl="li"
          phraseKey="plan_terms.telehealth"
          checked={telehealth}
        />
      </ul>
      <CollapsibleBox
        labelId="plan_terms.see_more_benefits"
        titleClassname="PlanCard__collapsible"
        open={previewMode}
      >
        <ul className="PlanCard__benefits">
          <PlanBenefitCheckmark
            WrapperEl="li"
            phraseKey="plan_terms.otc_drugs"
            checked={otc_drugs}
          />
          <PlanBenefitCheckmark
            WrapperEl="li"
            phraseKey="plan_terms.in_home_support"
            checked={in_home_support}
          />
          <PlanBenefitCheckmark
            WrapperEl="li"
            phraseKey="plan_terms.home_safety_devices"
            checked={home_safety_devices}
          />
          <PlanBenefitCheckmark
            WrapperEl="li"
            phraseKey="plan_terms.emergency_response_device"
            checked={emergency_response_device}
          />
        </ul>
      </CollapsibleBox>
    </div>
  );
};

export default PlanBenefits;
