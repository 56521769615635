import { ReactElement, ReactNode } from "react";

export * from "./tooltip-analytics";

export const getReactNodeText = (node: ReactNode): string => {
  if (["string", "number"].includes(typeof node))
    return `${node as string | number}`;
  if (node instanceof Array) return node.map(getReactNodeText).join("");
  if (typeof node === "object" && node)
    return getReactNodeText((node as ReactElement).props.children);
  return "";
};
