import React from "react";
import { SearchResultPlan } from "../../@types";
import { useTranslate } from "../../helpers";
import SuppressionHandler from "../SuppressionHandler";

interface CopaysProps {
  plan: SearchResultPlan;
}

const Copays: React.FunctionComponent<CopaysProps> = ({ plan }) => {
  const t = useTranslate();

  return (
    <div className="PlanCard__section" data-testid="copays">
      <h3 className="PlanCard__section_heading">{t("plan_terms.copays")}</h3>
      <div>
        <div className="PlanCard__info_group">
          <span>{t("plan_terms.primary")}: </span>
          <SuppressionHandler
            redactions={plan.redactions}
            className="PlanCard__copay"
          >
            {plan.primary_doctor_visit_cost}
          </SuppressionHandler>
        </div>
        <div className="PlanCard__info_group">
          <span>{t("plan_terms.specialist")}: </span>
          <SuppressionHandler
            redactions={plan.redactions}
            className="PlanCard__copay"
          >
            {plan.specialist_doctor_visit_cost}
          </SuppressionHandler>
        </div>
      </div>
    </div>
  );
};

export default Copays;
