import { useCallback } from "react";
import { useAppContext } from ".";
import { useCurrentPlanYear } from "../yearFlagHelpers";
import { LowIncomeSubsidyStatus } from "../../@types";
import { assert } from "../devHelpers";

export const useLIS = () => {
  // * Context
  const {
    state: { futureLis, lis },
  } = useAppContext();

  // * Flags
  const mctCurrentPlanYear = useCurrentPlanYear();

  const getLISForYear = useCallback(
    (year: number) => {
      switch (year) {
        // @TODO - think we may need to also look at `beneficiary.future_lis_status_start_date`
        // for everything to be entirely accurate from this hook
        case mctCurrentPlanYear:
          return lis || LowIncomeSubsidyStatus.LIS_NO_HELP;
        case mctCurrentPlanYear + 1:
          // use current `lis` for an anon user that does not have `futureLis`
          return futureLis || lis || LowIncomeSubsidyStatus.LIS_NO_HELP;
        default:
          assert(
            false,
            `We have attempted to call getLISForYear for a year (${year}) other than the current (${mctCurrentPlanYear}) or next (${
              mctCurrentPlanYear + 1
            }) plan year.`
          );
          return LowIncomeSubsidyStatus.LIS_NO_HELP; // we should never reach this.
      }
    },
    [futureLis, lis, mctCurrentPlanYear]
  );

  const isLIS = !!(lis && lis !== LowIncomeSubsidyStatus.LIS_NO_HELP);
  const isFutureLIS = !!(
    futureLis && futureLis !== LowIncomeSubsidyStatus.LIS_NO_HELP
  );

  return {
    lis,
    futureLis,
    /** function that returns `lis` for a given year
     * falls back to using `state.year` if not passed a year
     * falls back to returning `lis` if `futureLis` does not exist
     */
    getLISForYear,
    isLIS,
    isFutureLIS,
  };
};
