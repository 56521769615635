import {
  Plan,
  PlanType,
  PlanCategoryType,
  PlanSNPType,
  StarRatingError,
  PlanEnrollmentStatus,
  PackageServiceAvailability,
  MABenefitType,
  PlanBenefitCategory,
  HearingAidsService,
  DentalComprehensiveService,
  DentalPreventiveService,
} from "../@types";
import { CONSTANTS } from "../helpers/CONSTANTS";
const {
  abstractBenefitTierNames: {
    COST_SHARE_TIER_GENERIC,
    COST_SHARE_TIER_PREFERRED_GENERIC,
    COST_SHARE_TIER_NON_PREFERRED_BRAND,
    COST_SHARE_TIER_SPECIALTY_TIER,
    COST_SHARE_TIER_PREFERRED_BRAND,
    COST_SHARE_TIER_VACCINES,
    COST_SHARE_TIER_PREFERRED_SPECIALTY_TIER,
  },
} = CONSTANTS;

const plan: Plan = {
  non_zero_dollar_dsnp: false,
  enrollment_status: PlanEnrollmentStatus.AVAILABLE,
  address_1: "1600 Crown Colony Drive",
  address_2: "",
  annual_deductible: "$0",
  msa_annual_deposit: 3240,
  enrollment_opt_in_status: true,
  calculated_monthly_premium: 786,
  category: PlanCategoryType.HMO,
  city: "Quincy",
  contract_id: "H1660",
  contract_year: "2020",
  drug_plan_deductible: 415,
  high_performing: false,
  id: 1589,
  low_performing: true,
  name: "Harvard Pilgrim Stride Basic Rx (HMO)",
  name_esp: "Harvard Peregrino Primero de Paso Rx (HMO)",
  organization_name: "Harvard Pilgrim Health Care, Inc.",
  partb_premium_reduction: 0,
  partc_premium: 210,
  partd_premium: 90,
  plan_id: "015",
  plan_type: PlanType.MAPD,
  redactions: [],
  segment_id: "0",
  silver_sneakers: true,
  worldwide_emergency: true,
  snp_type: PlanSNPType.SNP_TYPE_NOT_SNP,
  state: "MA",
  total_remaining_premium: 9432,
  url: "www.harvardpilgrim.org/medicare",
  provider_network_web_address: "www.harvardpilgrim.org/medicare",
  zipcode: "02169",
  additional_supplemental_benefits: {
    mmp_benefits: [
      {
        category: "SB_CAT_HOME_HEALTH_SERVICES",
        benefits: [
          {
            benefit: "SB_OTHER2",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_ADDITIONAL_HOURS_OF_CARE",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_HOME_PERSONAL_CARE_SERVICES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_OTHER1",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
        ],
      },
      {
        category: "SB_CAT_PROSTHETICS_MEDICAL_SUPPLIES",
        benefits: [
          {
            benefit: "SB_NON_MEDICARE_COVERED_PROSTHETICS_MEDICAL_SUPPLIES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_ADDITIONAL_SERVICES",
        benefits: [
          {
            benefit: "SB_EARLY_AND_PERIODIC_SCREENING",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_PRIVATE_DUTY_NURSING_SERVICES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_CASE_MANAGEMENT_LONG_TERM_CARE",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit:
              "SB_INSTITUTION_FOR_MENTAL_DISEASE_SERVICES_FOR_65_OR_OLDER",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit:
              "SB_SERVICES_IN_AN_INTERMEDIATE_CARE_FACILITY_FOR_DISABILITIES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_CASE_MANAGEMENT",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_TOBACCO_CESSATION_COUNSELING_FOR_PREGNANT_WOMEN",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_FREESTANDING_BIRTH_CENTER_SERVICES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_RESPIRATORY_CARE_SERVICES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_FAMILY_PLANNING_SERVICES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_NURSING_HOME_SERVICES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_HOME_AND_COMMUNITY_BASED_SERVICES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_PERSONAL_CARE_SERVICES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_SELF_DIRECTED_PERSONAL_ASSISTANCE_SERVICES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
    ],
    special_benefits: [
      {
        category: "SB_CAT_NON_PRIMARILY_HEALTH_RELATED_BENEFITS",
        benefits: [
          {
            benefit: "SB_TRANSITIONAL_TEMPORARY_SUPPORTS",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_FOOD_AND_PRODUCE",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_MEALS_BEYOND_LIMITED_BASIS",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_PEST_CONTROL",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_TRANSPORTATION_FOR_NON_MEDICAL_NEEDS",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_INDOOR_AIR_QUALITY_EQUIPMENT_AND_SERVICES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_SOCIAL_NEEDS_BENEFIT",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_COMPLEMENTARY_THERAPIES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_SERVICES_SUPPORTING_SELF_DIRECTION",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_STRUCTURAL_HOME_MODIFICATIONS",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
        ],
      },
    ],
    other_benefits: [
      {
        category: "SB_CAT_DEFINED_SUPPLEMENTAL_BENEFITS",
        benefits: [
          {
            benefit: "SB_COUNSELING_SERVICES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_HEALTH_EDUCATION",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_SUPPORT_FOR_CAREGIVERS_OF_ENROLLEES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_PERSONAL_EMERGENCY_RESPONSE_SYSTEM_PERS",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_IN_HOME_SUPPORT_SERVICES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_FITNESS_BENEFIT",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_BATHROOM_SAFETY_DEVICES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_ALTERNATIVE_THERAPIES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_THERAPEUTIC_MASSAGE",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_ANNUAL_PHYSICAL_EXAM",
        benefits: [
          {
            benefit: "SB_ANNUAL_PHYSICAL_EXAM",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_MEAL_BENEFIT",
        benefits: [
          {
            benefit: "SB_MEAL_BENEFIT",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
        ],
      },
      {
        category: "SB_CAT_OTC_ITEMS",
        benefits: [
          {
            benefit: "SB_OTC_ITEMS",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_TRANSPORTATION_SERVICES",
        benefits: [
          {
            benefit: "SB_PLAN_APPROVED_HEALTH_RELATED_LOCATION",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_ANY_HEALTH_RELATED_LOCATION",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
        ],
      },
      {
        category: "SB_CAT_OPIOID_TREATMENT_SERVICES",
        benefits: [
          {
            benefit: "SB_MEDICARE_COVERED_BENEFITS",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_ADDITIONAL_TELEHEALTH_SERVICES",
        benefits: [
          {
            benefit: "SB_MEDICARE_COVERED_VISITS",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_WORLDWIDE_EMERGENCY_URGENT_COVERAGE",
        benefits: [
          {
            benefit: "SB_WORLDWIDE_EMERGENCY_TRANSPORTATION",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_WORLDWIDE_EMERGENCY_COVERAGE",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_WORLDWIDE_URGENT_COVERAGE",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_NON_OPIOID_PAIN_MANAGEMENT",
        benefits: [
          {
            benefit: "SB_ACUPUNCTURE",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_CHIROPRACTIC_SERVICES",
        benefits: [
          {
            benefit: "SB_ROUTINE_CARE",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
    ],
  },

  package_services: {
    doctor_choice: PackageServiceAvailability.PLAN_DOCTORS_MOST_SERVICES,
    outpatient_prescription: PackageServiceAvailability.PROVIDED,
    additional_physical_exams: PackageServiceAvailability.PROVIDED,
    dental_services: PackageServiceAvailability.NOT_PROVIDED,
    vision_services: PackageServiceAvailability.PROVIDED,
    hearing_services: PackageServiceAvailability.PROVIDED,
    ms_hearing_services: true,
    ms_dental_services: false,
  },
  member_phone: {
    number: "1-888-609-0692",
    extension: "",
  },
  prospect_phone: {
    number: "1-877-431-4742",
    extension: "",
  },
  package_benefits: {
    BENEFIT_AMBULANCE: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "$275",
        },
      },
      benefit_services: {},
    },
    BENEFIT_COMPREHENSIVE_DENTAL: {
      network_costs: {},
      benefit_services: {
        SERVICE_DIAGNOSTIC_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_ENDODONTICS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_EXTRACTIONS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_NONROUTINE_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_PERIODONTICS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_PROSTHODONTICS_AND_OTHER_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_RESTORATIVE_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
      },
    },
    BENEFIT_DIAGNOSTIC_PROCEDURES: {
      network_costs: {},
      benefit_services: {
        SERVICE_DIAGNOSTIC_RADIOLOGY_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$20-225",
            },
          },
        },
        SERVICE_DIAGNOSTIC_TESTS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$20-60",
            },
          },
        },
        SERVICE_LAB_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$20",
            },
          },
        },
        SERVICE_OUTPATIENT_XRAYS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$20",
            },
          },
        },
      },
    },
    BENEFIT_DOCTOR_VISITS: {
      network_costs: {},
      benefit_services: {
        SERVICE_PRIMARY: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$15 copay per visit",
            },
          },
        },
        SERVICE_SPECIALIST: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: false,
              cost_share: "$40 per visit",
            },
          },
        },
      },
    },
    BENEFIT_EMERGENCY_CARE: {
      network_costs: {},
      benefit_services: {
        SERVICE_EMERGENCY: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$90 per visit (always covered)",
            },
          },
        },
        SERVICE_URGENT_CARE: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$65 per visit (always covered)",
            },
          },
        },
      },
    },
    BENEFIT_FOOT_CARE: {
      network_costs: {},
      benefit_services: {
        SERVICE_FOOT_EXAMS_AND_TREATMENT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
        SERVICE_ROUTINE_FOOT_CARE: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
      },
    },
    BENEFIT_HEALTH_PLAN_DEDUCTIBLE: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "$0",
        },
      },
      benefit_services: {},
    },
    BENEFIT_HEARING: {
      network_costs: {},
      benefit_services: {
        SERVICE_FITTING_EVALUATION: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_HEARING_AIDS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$699-999",
            },
          },
        },
        SERVICE_HEARING_EXAM: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
      },
    },
    BENEFIT_INPATIENT_HOSPITAL: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share:
            "$310 per day for days 1 through 6\u003cbr /\u003e$0 per day for days 7 through 90",
        },
      },
      benefit_services: {},
    },
    BENEFIT_MAXIMUM_OOPC: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "$6,700 In-network",
        },
      },
      benefit_services: {},
    },
    BENEFIT_MEDICAL_EQUIPMENT: {
      network_costs: {},
      benefit_services: {
        SERVICE_DIABETES_SUPPLIES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_DURABLE_MEDICAL_EQUIPMENT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "20% per item",
            },
          },
        },
        SERVICE_PROSTHETICS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "20% per item",
            },
          },
        },
      },
    },
    BENEFIT_MEDICARE_PART_B: {
      network_costs: {},
      benefit_services: {
        SERVICE_CHEMOTHERAPY: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "20%",
            },
          },
        },
        SERVICE_OTHER_PART_B_DRUGS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "20%",
            },
          },
        },
      },
    },
    BENEFIT_MENTAL_HEALTH: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share:
            "$310 per day for days 1 through 5\u003cbr /\u003e$0 per day for days 6 through 90",
        },
      },
      benefit_services: {
        SERVICE_OUTPATIENT_GROUP_THERAPY_VISIT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
        SERVICE_OUTPATIENT_GROUP_THERAPY_VISIT_WITH_PSYCHIATRIST: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
        SERVICE_OUTPATIENT_INDIVIDUAL_THERAPY_VISIT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
        SERVICE_OUTPATIENT_INDIVIDUAL_THERAPY_VISIT_WITH_PSYCHIATRIST: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
      },
    },
    BENEFIT_OPTIONAL_SUPPLEMENTAL_BENEFITS: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "No",
        },
      },
      benefit_services: {},
    },
    BENEFIT_OTHER_DEDUCTIBLES: {
      network_costs: {
        NETWORK_TYPE_IN_NETWORK: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "No",
        },
      },
      benefit_services: {},
    },
    BENEFIT_OUTPATIENT_HOSPITAL: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share: "$60-280 per visit",
        },
      },
      benefit_services: {},
    },
    BENEFIT_PREVENTIVE_CARE: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "$0 copay",
        },
      },
      benefit_services: {},
    },
    BENEFIT_PREVENTIVE_DENTAL: {
      network_costs: {},
      benefit_services: {
        SERVICE_CLEANING: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_DENTAL_XRAYS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_FLUORIDE_TREATMENT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_ORAL_EXAM: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
      },
    },
    BENEFIT_REHABILITATION_SERVICES: {
      network_costs: {},
      benefit_services: {
        SERVICE_OCCUPATIONAL_THERAPY_VISIT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$30",
            },
          },
        },
        SERVICE_PHYSICAL_THERAPY_AND_SPEECH_AND_LANGUAGE_THERAPY_VISIT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$30",
            },
          },
        },
      },
    },
    BENEFIT_SKILLED_NURSING_FACILITY: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share:
            "$0 per day for days 1 through 20\u003cbr /\u003e$172 per day for days 21 through 100",
        },
      },
      benefit_services: {},
    },
    BENEFIT_TRANSPORTATION: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share: "$60",
        },
      },
      benefit_services: {},
    },
    BENEFIT_VISION: {
      network_costs: {},
      benefit_services: {
        SERVICE_CONTACT_LENSES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_EYEGLASSES_FRAMES_AND_LENSES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_EYEGLASS_FRAMES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_EYEGLASS_LENSES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_OTHER: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_ROUTINE_EYE_EXAM: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_UPGRADES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
      },
    },
    BENEFIT_WELLNESS_PROGRAMS: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "Covered",
        },
      },
      benefit_services: {},
    },
  },
  optional_benefits: [
    {
      package_number: 1,
      monthly_premium: "$35.20",
      deductible: "N/A",
      benefits: [
        "SUPPLEMENTAL_PREVENTIVE_DENTAL_SERVICES",
        "SUPPLEMENTAL_EYE_EXAMS",
        "SUPPLEMENTAL_EYEWEAR",
        "SUPPLEMENTAL_HEARING_EXAMS",
        "SUPPLEMENTAL_HEARING_AIDS",
      ],
    },
    {
      package_number: 2,
      monthly_premium: "$10.75",
      deductible: "$100.00",
      benefits: [
        "SUPPLEMENTAL_PREVENTIVE_DENTAL_SERVICES",
        "SUPPLEMENTAL_EYEWEAR",
        "SUPPLEMENTAL_HEARING_AIDS",
      ],
    },
    {
      package_number: 3,
      monthly_premium: "$20.00",
      deductible: "N/A",
      benefits: [
        "SUPPLEMENTAL_NOT_AVAILABLE",
        "SUPPLEMENTAL_INPATIENT_HOSPITAL_COVERAGE",
        "SUPPLEMENTAL_MENTAL_HEALTH_SERVICES",
        "SUPPLEMENTAL_SKILLED_NURSING_FACILITY",
        "SUPPLEMENTAL_CARDIAC_REHABILITATION_SERVICES",
        "SUPPLEMENTAL_INTENSIVE_CARDIAC_REHABILITATION_SERVICES",
        "SUPPLEMENTAL_PULMONARY_REHABILITATION_SERVICES",
        "SUPPLEMENTAL_EMERGENCY",
        "SUPPLEMENTAL_URGENT_CARE",
        "SUPPLEMENTAL_WORLDWIDE_EMERGENCY",
        "SUPPLEMENTAL_PARTIAL_HOSPITALIZATION",
        "SUPPLEMENTAL_HOME_HEALTH_CARE",
        "SUPPLEMENTAL_PRIMARY",
        "SUPPLEMENTAL_CHIROPRACTIC_CARE",
        "SUPPLEMENTAL_OCCUPATIONAL_THERAPY",
        "SUPPLEMENTAL_SPECIALIST",
        "SUPPLEMENTAL_OUTPATIENT_THERAPY",
        "SUPPLEMENTAL_FOOT_CARE",
        "SUPPLEMENTAL_OTHER_HEALTH_CARE_PROFESSIONAL",
        "SUPPLEMENTAL_OUTPATIENT_THERAPY_PSYCHIATRIST",
        "SUPPLEMENTAL_PHYSICAL_SPEECH_LANGUAGE_THERAPY_VISIT",
        "SUPPLEMENTAL_DIAGNOSTIC_PROCEDURES",
        "SUPPLEMENTAL_DIAGNOSTIC_RADIOLOGY",
        "SUPPLEMENTAL_OUTPATIENT_HOSPITAL",
        "SUPPLEMENTAL_AMBULATORY_SURGICAL_CENTER",
        "SUPPLEMENTAL_OUTPATIENT_SUBSTANCE_ABUSE",
        "SUPPLEMENTAL_BLOOD",
        "SUPPLEMENTAL_AMBULANCE",
        "SUPPLEMENTAL_TRANSPORTATION",
        "SUPPLEMENTAL_DURABLE_MEDICAL_EQUIPMENT",
        "SUPPLEMENTAL_PROSTHETICS",
        "SUPPLEMENTAL_DIABETES_SUPPLIES",
        "SUPPLEMENTAL_RENAL_DIALYSIS",
        "SUPPLEMENTAL_ACUPUNCTURE",
        "SUPPLEMENTAL_OVER_THE_COUNTER_ITEMS",
        "SUPPLEMENTAL_MEAL_BENEFIT",
        "SUPPLEMENTAL_OTHER_1",
        "SUPPLEMENTAL_OTHER_2",
        "SUPPLEMENTAL_OTHER_3",
        "SUPPLEMENTAL_DUAL_ELIGIBLE_SNP_WITH_HIGHLY_INTEGRATED_SERVICES",
        "SUPPLEMENTAL_PREVENTIVE_CARE",
        "SUPPLEMENTAL_ANNUAL_PHYSICAL_EXAM",
        "SUPPLEMENTAL_WELLNESS_PROGRAMS",
        "SUPPLEMENTAL_KIDNEY_DISEASE_EDUCATION_SERVICES",
        "SUPPLEMENTAL_OTHER_MEDICARE_COVERED_PREVENTIVE_SERVICES",
        "SUPPLEMENTAL_MEDICARE_PART_B_DRUGS",
        "SUPPLEMENTAL_PREVENTIVE_DENTAL_SERVICES",
        "SUPPLEMENTAL_COMPREHENSIVE_DENTAL_SERVICES",
        "SUPPLEMENTAL_EYE_EXAMS",
        "SUPPLEMENTAL_EYEWEAR",
        "SUPPLEMENTAL_HEARING_EXAMS",
        "SUPPLEMENTAL_HEARING_AIDS",
        "SUPPLEMENTAL_PRESCRIPTION_DRUGS_COST_PLANS_ONLY",
        "SUPPLEMENTAL_POS_OPTION",
        "SUPPLEMENTAL_VISITOR_TRAVEL_PROGRAM",
      ],
    },
  ],
  abstract_benefits: {
    initial_coverage: {
      tiers: [
        {
          label: COST_SHARE_TIER_PREFERRED_SPECIALTY_TIER,
          preferred_retail: null,
          standard_retail: {
            days_30: "23%",
            days_90: "23%",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "",
          tier_row_order: 1,
        },
        {
          label: COST_SHARE_TIER_PREFERRED_GENERIC,
          preferred_retail: null,
          standard_retail: {
            days_30: "$0.00 copay",
            days_90: "$0.00 copay",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "",
          tier_row_order: 2,
        },
        {
          label: COST_SHARE_TIER_GENERIC,
          preferred_retail: null,
          standard_retail: {
            days_30: "$15.00 copay",
            days_90: "$45.00 copay",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "",
          tier_row_order: 3,
        },
        {
          label: COST_SHARE_TIER_PREFERRED_BRAND,
          preferred_retail: null,
          standard_retail: {
            days_30: "$47.00 copay",
            days_90: "$141.00 copay",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "",
          tier_row_order: 4,
        },
        {
          label: COST_SHARE_TIER_NON_PREFERRED_BRAND,
          preferred_retail: null,
          standard_retail: {
            days_30: "$100.00 copay",
            days_90: "$300.00 copay",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "",
          tier_row_order: 5,
        },
        {
          label: COST_SHARE_TIER_SPECIALTY_TIER,
          preferred_retail: null,
          standard_retail: {
            days_30: "25%",
            days_90: "25%",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "",
          tier_row_order: 6,
        },
        {
          label: COST_SHARE_TIER_VACCINES,
          preferred_retail: null,
          standard_retail: {
            days_30: "$0.00 copay",
            days_90: "",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "",
          tier_row_order: 7,
        },
      ],
      footnote: "",
    },
    coverage_gap: {
      tiers: [
        {
          label: "COST_SHARE_TIER_PREFERRED_GENERIC",
          preferred_retail: null,
          standard_retail: {
            days_30: "$3.00 copay",
            days_90: "$9.00 copay",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: {
            days_30: "$3.00 copay",
            days_90: "$6.00 copay",
            days_any: "",
          },
          general: "",
          tier_row_order: 0,
        },
        {
          label: "COST_SHARE_TIER_GENERIC",
          preferred_retail: null,
          standard_retail: {
            days_30: "$12.00 copay",
            days_90: "$36.00 copay",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: {
            days_30: "$12.00 copay",
            days_90: "$24.00 copay",
            days_any: "",
          },
          general: "",
          tier_row_order: 0,
        },
        {
          label: "COST_SHARE_TIER_VACCINES",
          preferred_retail: null,
          standard_retail: {
            days_30: "$0.00 copay",
            days_90: "",
            days_any: "",
          },
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "",
          tier_row_order: 0,
        },
      ],
      footnote:
        "All drugs may not be offered with additional gap coverage, for all other drugs, you pay 44% for generic drugs and 35% for brand-name drugs.",
    },
    catastrophic: {
      tiers: [
        {
          label: "COST_SHARE_TIER_GENERIC_DRUGS",
          preferred_retail: null,
          standard_retail: null,
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "$3.40 copay or 5% (whichever costs more)",
          tier_row_order: 0,
        },
        {
          label: "COST_SHARE_TIER_BRAND_NAME_DRUGS",
          preferred_retail: null,
          standard_retail: null,
          preferred_mail_order: null,
          standard_mail_order: null,
          general: "$8.50 copay or 5% (whichever costs more)",
          tier_row_order: 0,
        },
      ],
      footnote:
        "For all other drugs, you pay 25% for generic drugs and 25% for brand-name drugs.",
    },
    lis: null,
    platino: null,
  },
  lis: {
    level_100: 100,
    level_75: 75,
    level_50: 50,
    level_25: 25,
  },
  overall_star_rating: {
    category: "STAR_OVERALL_RATING",
    rating: 3.5,
    error: StarRatingError.STAR_ERROR_NO_ERROR,
  },
  star_ratings: [
    {
      category: "STAR_DRUG_PLAN_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_PLAN_RATING",
      rating: 3.5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_OVERALL_RATING",
      rating: 3.5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_CUSTOMER_SERVICE_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_COMPLAINTS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_MEMBER_EXP_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_ACCURACY_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_STAYING_HEALTHY_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_CHRONIC_CONDITIONS_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_MEMBER_HEALTH_EXP_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_COMPLAINTS_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_SERVICE_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_TTY_AND_FL_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_PLAN_COMPLAINTS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_MEMBERS_LEAVING_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_IMPROVEMENT_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_PLAN_MEMBER_RATING",
      rating: 2,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_PRESCRIPTION_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_PROVIDES_PRICING_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DIABETES_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_BLOOD_PRESSURE_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_CHOLESTEROL_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_PHARMACIST_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DIABETES_CHOLESTEROL_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_BREAST_CANCER_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_COLORECTAL_CANCER_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_ANNUAL_FLU_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_PHYSICAL_MAINT_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_PLAN_TOO_SMALL,
    },
    {
      category: "STAR_MENTAL_MAINT_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_PLAN_TOO_SMALL,
    },
    {
      category: "STAR_PHYSICAL_ACTIVITY",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTHY_WEIGHT_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_ASSESSMENT_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_REQUIRED,
    },
    {
      category: "STAR_YEARLY_DRUG_REVIEW_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_REQUIRED,
    },
    {
      category: "STAR_DAILY_ACTIVITIES_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_REQUIRED,
    },
    {
      category: "STAR_YEARLY_PAIN_SCREEN_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_REQUIRED,
    },
    {
      category: "STAR_OSTEOPOROSIS_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_ENOUGH_DATA,
    },
    {
      category: "STAR_EYE_EXAM_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_KIDNEY_TESTING_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_SUGAR_CONTROL_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_ARTHRITIS_RATING",
      rating: 2,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_FALL_RISK_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_BLADDER_CONTROL_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_UPTODATE_RECORDS_RATING",
      rating: 2,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_READMISSION_RATING",
      rating: 1,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HD_CHOLESTEROL_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_SEEING_SPECIALISTS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_QUICK_APPOINTMENTS_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_INFORMATION_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_CARE_QUALITY_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_MEMBER_HEALTH_PLAN_RATING",
      rating: 2,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_COORDINATION_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_PLAN_COMPLAINTS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_LEAVING_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_IMPROVEMENT_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_APPEALS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_APPEALS_FAIR_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_TTY_AVAIL_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
  ],
  ma_benefits: [
    {
      category: "HEARING_AIDS",
      type: MABenefitType.MandatorySupplemental,
      authorization_required: false,
      referral_required: false,
      plan_limits: false,
      service: "ALL_HEARING_AIDS",
      cost_sharing: [
        {
          network_status: "IN_NETWORK",
          min_copay: 0,
          max_copay: 0,
          min_coinsurance: null,
          max_coinsurance: null,
        },
      ],
    },
  ],
};

const pdpPlan: Plan = {
  non_zero_dollar_dsnp: false,
  enrollment_opt_in_status: true,
  enrollment_status: PlanEnrollmentStatus.AVAILABLE,
  address_1: "1600 Crown Colony Drive",
  address_2: "",
  annual_deductible: "$0",
  calculated_monthly_premium: 786,
  category: PlanCategoryType.HMO,
  city: "Quincy",
  contract_id: "H1660",
  contract_year: "2020",
  drug_plan_deductible: 415,
  high_performing: false,
  id: 1589,
  low_performing: true,
  msa_annual_deposit: 3240,
  name_esp: "Harvard Pilgrim Stride Basic Rx (HMO)",
  name: "Harvard Pilgrim Stride Basic Rx (HMO)",
  organization_name: "Harvard Pilgrim Health Care, Inc.",
  partb_premium_reduction: 0,
  partc_premium: 210,
  partd_premium: 90,
  plan_id: "015",
  plan_type: PlanType.PDP,
  redactions: [],
  segment_id: "0",
  silver_sneakers: true,
  worldwide_emergency: true,
  snp_type: PlanSNPType.SNP_TYPE_NOT_SNP,
  state: "MA",
  total_remaining_premium: 9432,
  url: "www.harvardpilgrim.org/medicare",
  provider_network_web_address: "www.harvardpilgrim.org/medicare",
  zipcode: "02169",
  additional_supplemental_benefits: {
    mmp_benefits: [],
    other_benefits: [
      {
        category: "SB_CAT_DEFINED_SUPPLEMENTAL_BENEFITS",
        benefits: [
          {
            benefit: "SB_PERSONAL_EMERGENCY_RESPONSE_SYSTEM_PERS",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_IN_HOME_SUPPORT_SERVICES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_FITNESS_BENEFIT",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_BATHROOM_SAFETY_DEVICES",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_ANNUAL_PHYSICAL_EXAM",
        benefits: [
          {
            benefit: "SB_ANNUAL_PHYSICAL_EXAM",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_MEAL_BENEFIT",
        benefits: [
          {
            benefit: "SB_MEAL_BENEFIT",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
        ],
      },
      {
        category: "SB_CAT_OTC_ITEMS",
        benefits: [
          {
            benefit: "SB_OTC_ITEMS",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_TRANSPORTATION_SERVICES",
        benefits: [
          {
            benefit: "SB_PLAN_APPROVED_HEALTH_RELATED_LOCATION",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_ANY_HEALTH_RELATED_LOCATION",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
        ],
      },
      {
        category: "SB_CAT_OPIOID_TREATMENT_SERVICES",
        benefits: [
          {
            benefit: "SB_MEDICARE_COVERED_BENEFITS",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_ADDITIONAL_TELEHEALTH_SERVICES",
        benefits: [
          {
            benefit: "SB_MEDICARE_COVERED_VISITS",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
      {
        category: "SB_CAT_WORLDWIDE_EMERGENCY_URGENT_COVERAGE",
        benefits: [
          {
            benefit: "SB_WORLDWIDE_EMERGENCY_TRANSPORTATION",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_WORLDWIDE_EMERGENCY_COVERAGE",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_WORLDWIDE_URGENT_COVERAGE",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
        ],
      },
    ],
    special_benefits: [
      {
        category: "SB_CAT_NON_PRIMARILY_HEALTH_RELATED_BENEFITS",
        benefits: [
          {
            benefit: "SB_TRANSITIONAL_TEMPORARY_SUPPORTS",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_FOOD_AND_PRODUCE",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_MEALS_BEYOND_LIMITED_BASIS",
            coverage: "SB_COVERAGE_SOME_COVERAGE",
          },
          {
            benefit: "SB_PEST_CONTROL",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_TRANSPORTATION_FOR_NON_MEDICAL_NEEDS",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_INDOOR_AIR_QUALITY_EQUIPMENT_AND_SERVICES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_SOCIAL_NEEDS_BENEFIT",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_COMPLEMENTARY_THERAPIES",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_SERVICES_SUPPORTING_SELF_DIRECTION",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
          {
            benefit: "SB_STRUCTURAL_HOME_MODIFICATIONS",
            coverage: "SB_COVERAGE_NOT_COVERED",
          },
        ],
      },
    ],
  },
  package_services: {
    doctor_choice: PackageServiceAvailability.PLAN_DOCTORS_MOST_SERVICES,
    outpatient_prescription: PackageServiceAvailability.PROVIDED,
    additional_physical_exams: PackageServiceAvailability.PROVIDED,
    dental_services: PackageServiceAvailability.NOT_PROVIDED,
    vision_services: PackageServiceAvailability.PROVIDED,
    hearing_services: PackageServiceAvailability.PROVIDED,
    ms_hearing_services: true,
    ms_dental_services: false,
  },
  member_phone: {
    number: "1-888-609-0692",
    extension: "",
  },
  prospect_phone: {
    number: "1-877-431-4742",
    extension: "",
  },
  package_benefits: {
    BENEFIT_AMBULANCE: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "$275",
        },
      },
      benefit_services: {},
    },
    BENEFIT_COMPREHENSIVE_DENTAL: {
      network_costs: {},
      benefit_services: {
        SERVICE_DIAGNOSTIC_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_ENDODONTICS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_EXTRACTIONS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_NONROUTINE_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_PERIODONTICS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_PROSTHODONTICS_AND_OTHER_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_RESTORATIVE_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
      },
    },
    BENEFIT_DIAGNOSTIC_PROCEDURES: {
      network_costs: {},
      benefit_services: {
        SERVICE_DIAGNOSTIC_RADIOLOGY_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$20-225",
            },
          },
        },
        SERVICE_DIAGNOSTIC_TESTS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$20-60",
            },
          },
        },
        SERVICE_LAB_SERVICES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$20",
            },
          },
        },
        SERVICE_OUTPATIENT_XRAYS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$20",
            },
          },
        },
      },
    },
    BENEFIT_DOCTOR_VISITS: {
      network_costs: {},
      benefit_services: {
        SERVICE_PRIMARY: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$15 copay per visit",
            },
          },
        },
        SERVICE_SPECIALIST: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: false,
              cost_share: "$40 per visit",
            },
          },
        },
      },
    },
    BENEFIT_EMERGENCY_CARE: {
      network_costs: {},
      benefit_services: {
        SERVICE_EMERGENCY: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$90 per visit (always covered)",
            },
          },
        },
        SERVICE_URGENT_CARE: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$65 per visit (always covered)",
            },
          },
        },
      },
    },
    BENEFIT_FOOT_CARE: {
      network_costs: {},
      benefit_services: {
        SERVICE_FOOT_EXAMS_AND_TREATMENT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
        SERVICE_ROUTINE_FOOT_CARE: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
      },
    },
    BENEFIT_HEALTH_PLAN_DEDUCTIBLE: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "$0",
        },
      },
      benefit_services: {},
    },
    BENEFIT_HEARING: {
      network_costs: {},
      benefit_services: {
        SERVICE_FITTING_EVALUATION: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_HEARING_AIDS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$699-999",
            },
          },
        },
        SERVICE_HEARING_EXAM: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
      },
    },
    BENEFIT_INPATIENT_HOSPITAL: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share:
            "$310 per day for days 1 through 6\u003cbr /\u003e$0 per day for days 7 through 90",
        },
      },
      benefit_services: {},
    },
    BENEFIT_MAXIMUM_OOPC: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "$6,700 In-network",
        },
      },
      benefit_services: {},
    },
    BENEFIT_MEDICAL_EQUIPMENT: {
      network_costs: {},
      benefit_services: {
        SERVICE_DIABETES_SUPPLIES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_DURABLE_MEDICAL_EQUIPMENT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "20% per item",
            },
          },
        },
        SERVICE_PROSTHETICS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "20% per item",
            },
          },
        },
      },
    },
    BENEFIT_MEDICARE_PART_B: {
      network_costs: {},
      benefit_services: {
        SERVICE_CHEMOTHERAPY: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "20%",
            },
          },
        },
        SERVICE_OTHER_PART_B_DRUGS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: false,
              plan_limits: false,
              cost_share: "20%",
            },
          },
        },
      },
    },
    BENEFIT_MENTAL_HEALTH: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share:
            "$310 per day for days 1 through 5\u003cbr /\u003e$0 per day for days 6 through 90",
        },
      },
      benefit_services: {
        SERVICE_OUTPATIENT_GROUP_THERAPY_VISIT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
        SERVICE_OUTPATIENT_GROUP_THERAPY_VISIT_WITH_PSYCHIATRIST: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
        SERVICE_OUTPATIENT_INDIVIDUAL_THERAPY_VISIT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
        SERVICE_OUTPATIENT_INDIVIDUAL_THERAPY_VISIT_WITH_PSYCHIATRIST: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "$40",
            },
          },
        },
      },
    },
    BENEFIT_OPTIONAL_SUPPLEMENTAL_BENEFITS: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "No",
        },
      },
      benefit_services: {},
    },
    BENEFIT_OTHER_DEDUCTIBLES: {
      network_costs: {
        NETWORK_TYPE_IN_NETWORK: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "No",
        },
      },
      benefit_services: {},
    },
    BENEFIT_OUTPATIENT_HOSPITAL: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share: "$60-280 per visit",
        },
      },
      benefit_services: {},
    },
    BENEFIT_PREVENTIVE_CARE: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "$0 copay",
        },
      },
      benefit_services: {},
    },
    BENEFIT_PREVENTIVE_DENTAL: {
      network_costs: {},
      benefit_services: {
        SERVICE_CLEANING: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_DENTAL_XRAYS: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_FLUORIDE_TREATMENT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_ORAL_EXAM: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
      },
    },
    BENEFIT_REHABILITATION_SERVICES: {
      network_costs: {},
      benefit_services: {
        SERVICE_OCCUPATIONAL_THERAPY_VISIT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$30",
            },
          },
        },
        SERVICE_PHYSICAL_THERAPY_AND_SPEECH_AND_LANGUAGE_THERAPY_VISIT: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: true,
              referral_required: true,
              plan_limits: false,
              cost_share: "$30",
            },
          },
        },
      },
    },
    BENEFIT_SKILLED_NURSING_FACILITY: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share:
            "$0 per day for days 1 through 20\u003cbr /\u003e$172 per day for days 21 through 100",
        },
      },
      benefit_services: {},
    },
    BENEFIT_TRANSPORTATION: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: true,
          referral_required: false,
          plan_limits: false,
          cost_share: "$60",
        },
      },
      benefit_services: {},
    },
    BENEFIT_VISION: {
      network_costs: {},
      benefit_services: {
        SERVICE_CONTACT_LENSES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_EYEGLASSES_FRAMES_AND_LENSES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_EYEGLASS_FRAMES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_EYEGLASS_LENSES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_OTHER: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: false,
              cost_share: "Not covered",
            },
          },
        },
        SERVICE_ROUTINE_EYE_EXAM: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: true,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
        SERVICE_UPGRADES: {
          network_costs: {
            NETWORK_TYPE_NA: {
              authorization_required: false,
              referral_required: false,
              plan_limits: true,
              cost_share: "$0 copay",
            },
          },
        },
      },
    },
    BENEFIT_WELLNESS_PROGRAMS: {
      network_costs: {
        NETWORK_TYPE_NA: {
          authorization_required: false,
          referral_required: false,
          plan_limits: false,
          cost_share: "Covered",
        },
      },
      benefit_services: {},
    },
  },
  optional_benefits: [],
  lis: {
    level_100: 100,
    level_75: 75,
    level_50: 50,
    level_25: 25,
  },
  overall_star_rating: {
    category: "STAR_OVERALL_RATING",
    rating: 3.5,
    error: StarRatingError.STAR_ERROR_NO_ERROR,
  },
  star_ratings: [
    {
      category: "STAR_DRUG_PLAN_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_PLAN_RATING",
      rating: 3.5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_OVERALL_RATING",
      rating: 3.5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_CUSTOMER_SERVICE_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_COMPLAINTS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_MEMBER_EXP_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_ACCURACY_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_STAYING_HEALTHY_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_CHRONIC_CONDITIONS_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_MEMBER_HEALTH_EXP_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_COMPLAINTS_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_SERVICE_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_TTY_AND_FL_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_PLAN_COMPLAINTS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_MEMBERS_LEAVING_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_IMPROVEMENT_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DRUG_PLAN_MEMBER_RATING",
      rating: 2,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_PRESCRIPTION_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_PROVIDES_PRICING_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DIABETES_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_BLOOD_PRESSURE_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_CHOLESTEROL_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_PHARMACIST_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_DIABETES_CHOLESTEROL_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_BREAST_CANCER_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_COLORECTAL_CANCER_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_ANNUAL_FLU_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_PHYSICAL_MAINT_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_PLAN_TOO_SMALL,
    },
    {
      category: "STAR_MENTAL_MAINT_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_PLAN_TOO_SMALL,
    },
    {
      category: "STAR_PHYSICAL_ACTIVITY",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTHY_WEIGHT_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_ASSESSMENT_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_REQUIRED,
    },
    {
      category: "STAR_YEARLY_DRUG_REVIEW_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_REQUIRED,
    },
    {
      category: "STAR_DAILY_ACTIVITIES_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_REQUIRED,
    },
    {
      category: "STAR_YEARLY_PAIN_SCREEN_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_REQUIRED,
    },
    {
      category: "STAR_OSTEOPOROSIS_RATING",
      rating: 0,
      error: StarRatingError.STAR_ERROR_NOT_ENOUGH_DATA,
    },
    {
      category: "STAR_EYE_EXAM_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_KIDNEY_TESTING_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_SUGAR_CONTROL_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_ARTHRITIS_RATING",
      rating: 2,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_FALL_RISK_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_BLADDER_CONTROL_RATING",
      rating: 5,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_UPTODATE_RECORDS_RATING",
      rating: 2,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_READMISSION_RATING",
      rating: 1,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HD_CHOLESTEROL_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_SEEING_SPECIALISTS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_QUICK_APPOINTMENTS_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_INFORMATION_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_CARE_QUALITY_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_MEMBER_HEALTH_PLAN_RATING",
      rating: 2,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_COORDINATION_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_PLAN_COMPLAINTS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_LEAVING_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_IMPROVEMENT_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_APPEALS_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_APPEALS_FAIR_RATING",
      rating: 4,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
    {
      category: "STAR_HEALTH_TTY_AVAIL_RATING",
      rating: 3,
      error: StarRatingError.STAR_ERROR_NO_ERROR,
    },
  ],
  ma_benefits: [
    {
      category: "HEARING_AIDS",
      type: MABenefitType.MandatorySupplemental,
      authorization_required: false,
      referral_required: false,
      plan_limits: false,
      service: "ALL_HEARING_AIDS",
      cost_sharing: [
        {
          network_status: "IN_NETWORK",
          min_copay: 0,
          max_copay: 0,
          min_coinsurance: null,
          max_coinsurance: null,
        },
      ],
    },
  ],
};

const maPlan: Plan = {
  ...plan,
  plan_type: PlanType.MA,
};

const msaPlan: Plan = {
  ...plan,
  plan_type: PlanType.MA,
  category: PlanCategoryType.MSA,
};

const getTestPlan = (attrs: Record<string, unknown> = {}): Plan => {
  const contract_year = String(new Date().getFullYear());
  attrs = { ...attrs, contract_year };
  return {
    ...plan,
    ...attrs,
  };
};

const plans = [
  plan,
  {
    ...plan,
    segment_id: "1",
    name_esp: "Harvard Pilgrim Stride Basic Rx (HMO) 1",
    name: "Harvard Pilgrim Stride Basic Rx (HMO) 1",
  },
  {
    ...plan,
    segment_id: "2",
    name_esp: "Harvard Pilgrim Stride Basic Rx (HMO) 2",
    name: "Harvard Pilgrim Stride Basic Rx (HMO) 2",
  },
];
export default plan;
const mapdPlan = plan;
export { plans, plan, mapdPlan, pdpPlan, maPlan, msaPlan, getTestPlan };

const plan2025: Plan = {
  ...plan,
  contract_year: "2025",
  ma_benefits: [
    {
      category: PlanBenefitCategory.HearingAids,
      type: MABenefitType.MandatorySupplemental,
      authorization_required: false,
      referral_required: false,
      plan_limits: false,
      service: HearingAidsService.OTC,
      cost_sharing: [
        {
          network_status: "IN_NETWORK",
          min_copay: 0,
          max_copay: 123,
          min_coinsurance: null,
          max_coinsurance: null,
        },
      ],
    },
    {
      category: PlanBenefitCategory.HearingAids,
      type: MABenefitType.MandatorySupplemental,
      authorization_required: false,
      referral_required: false,
      plan_limits: false,
      service: HearingAidsService.RX,
      cost_sharing: [
        {
          network_status: "IN_NETWORK",
          min_copay: 0,
          max_copay: 234,
          min_coinsurance: null,
          max_coinsurance: null,
        },
      ],
    },

    {
      category: PlanBenefitCategory.DentalComprehensive,
      type: MABenefitType.MandatorySupplemental,
      authorization_required: false,
      referral_required: false,
      plan_limits: false,
      service: DentalComprehensiveService.Orthodontics,
      cost_sharing: [
        {
          network_status: "IN_NETWORK",
          min_copay: 0,
          max_copay: 345,
          min_coinsurance: null,
          max_coinsurance: null,
        },
      ],
    },
    {
      category: PlanBenefitCategory.DentalPreventive,
      type: MABenefitType.MandatorySupplemental,
      authorization_required: false,
      referral_required: false,
      plan_limits: false,
      service: DentalPreventiveService.FluorideTreatment,
      cost_sharing: [
        {
          network_status: "IN_NETWORK",
          min_copay: 0,
          max_copay: 456,
          min_coinsurance: null,
          max_coinsurance: null,
        },
      ],
    },
  ],
};

export const getPlan = (contractYear: string): Plan => {
  if (+contractYear < 2025) {
    return { ...plan, contract_year: contractYear };
  }
  return { ...plan2025, contract_year: contractYear };
};
