import React, { FunctionComponent, useContext } from "react";
import { Alert } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers";
import { AppStore } from "../app/store";

const DegradedIntegrationAlert: FunctionComponent = (): JSX.Element => {
  const t = useTranslate();
  const {
    state: { degradedIntegrations },
  } = useContext(AppStore.AppContext);

  return degradedIntegrations.length ? (
    <Alert
      className="DegradedIntegrationAlert ds-u-margin-bottom--2"
      hideIcon={true}
      heading={t("degraded_int.heading")}
      variation="warn"
    >
      {t("degraded_int.body")}
    </Alert>
  ) : (
    <></>
  );
};

export default DegradedIntegrationAlert;
