import React, { FunctionComponent, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { HelpDrawerWrapper } from "..";
import { AnalyticsActionType } from "../../app/contexts/Analytics/types";
import { AppStore } from "../../app/store";
import { useTranslate } from "../../helpers";
import { HelpDrawerWrapperProps } from "../HelpDrawerWrapper";
import routes from "../../app/routes";
import { Link } from "react-router-dom";

const CantFindDrugHelpDrawer: FunctionComponent<
  Partial<HelpDrawerWrapperProps> & {
    PAP?: boolean;
  }
> = ({ PAP = false, ...props }) => {
  const t = useTranslate();
  const { dispatch } = useContext(AppStore.AppContext);

  const sendSPAPTealiumEvent = () => {
    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: "Internal Link Click",
        event_label: "State Pharmaceutical Assistance Program",
        other_props: { event_category: "PAP" },
      },
    });
  };

  return (
    <HelpDrawerWrapper
      inlineToggle
      toggleText={t("drug_search.cant_find_drug")}
      drawerTitle={t("drug_search.cant_find_drug")}
      headingLevel="2"
      enableDefaultAnalytics={true}
      {...props}
    >
      <div className="ds-u-font-size--base">
        <p>{t("drug_search.cant_find.trouble_adding")}</p>
        <ul>
          <li>{t("drug_search.cant_find.check_spelling")}</li>
          {PAP ? (
            <>
              <li>{t("drug_search.cant_find.may_not_be_pap")}</li>
              <FormattedMessage
                id="drug_search.cant_find.check_for_pap"
                tagName="li"
                values={{
                  link: (
                    <Link
                      to={routes.spap.landingPage}
                      onClick={sendSPAPTealiumEvent}
                    >
                      {t("drug_search.cant_find.spap")}
                    </Link>
                  ),
                }}
              />
            </>
          ) : (
            <li>{t("drug_search.cant_find.check_otc")}</li>
          )}
        </ul>
      </div>
    </HelpDrawerWrapper>
  );
};

export default CantFindDrugHelpDrawer;
