import { getEnvironment } from ".";
import { Envs } from "../@types";

export const devDebug = (message: string): void => {
  const env = getEnvironment();
  const re = new RegExp(`${Envs.imp}|${Envs.test}|${Envs.local}`, "i");
  if (re.test(env)) {
    console?.debug(message);
  }
};

export const logMinutesFromMs = (ms: string | number): string =>
  `${parseFloat((parseInt(String(ms), 10) / (60 * 1000)).toFixed(2))} mins`;
