import React, { FC, useContext } from "react";
import { formatDate, useTranslate } from "../../../helpers";
import { FormattedMessage } from "react-intl";
import {
  LinkExtraHelp,
  TooltipCreditableDrugCoverage,
  TooltipExtraHelp,
} from "../";
import { AppContext } from "../../../app/store";

export const AvoidPartDPenaltyHelpContent: FC = () => {
  const t = useTranslate();
  const { state } = useContext(AppContext);
  const contentKeyPrefix = "ntm.help_drawer.avoid_part_d_penalty.";
  const itemsPrefix = `${contentKeyPrefix}items.`;
  let iep_end_date = "N/A";
  // @TODO - Revise when `wizardAnswer.iepEndDate` (or whatever it is) is available in app state
  if (state["wizardAnswer"] && state["wizardAnswer"]["iepEndDate"]) {
    iep_end_date = formatDate(state["wizardAnswer"]["iepEndDate"], "M/d/yyyy");
  }
  return (
    <>
      <p>
        <FormattedMessage
          id={`${contentKeyPrefix}subheading`}
          values={{
            aria_label_dee: chunks => <span aria-label="dee">{chunks}</span>,
          }}
        />
      </p>
      <ol>
        {["one", "two", "three"].map(item => (
          <li key={item}>
            <strong>{t(`${itemsPrefix}${item}.heading`)}</strong>
            <FormattedMessage
              id={`${itemsPrefix}${item}.content`}
              tagName="div"
              values={{
                tooltip_extra_help: chunks => (
                  <TooltipExtraHelp>{chunks}</TooltipExtraHelp>
                ),
                link_extra_help: chunks => (
                  <LinkExtraHelp>{chunks}</LinkExtraHelp>
                ),
                tooltip_creditable_drug_coverage: chunks => (
                  <TooltipCreditableDrugCoverage>
                    {chunks}
                  </TooltipCreditableDrugCoverage>
                ),
                p: chunks => (
                  // Using `div`s to avoid invalid DOM-nesting issues with tooltips
                  <div className="ds-u-margin-top--2 ds-u-margin-bottom--2">
                    {chunks}
                  </div>
                ),
                strong: chunks => <strong>{chunks}</strong>,
                iep_end_date,
                aria_label_dee: chunks => (
                  <span aria-label="dee">{chunks}</span>
                ),
              }}
            />
          </li>
        ))}
      </ol>
    </>
  );
};
