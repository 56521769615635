import React, { FunctionComponent, useEffect, useState } from "react";
import { TextField } from "@cmsgov/ds-medicare-gov";
import { County } from "../@types";
import * as api from "../api";
import { useTranslate } from "../helpers";
import { isValidZipcode, ZIP_LENGTH } from "../helpers/zipcodeHelpers";

export type SearchFunction = () => Promise<County[]>;

interface CountySearchFieldProps {
  className?: string;
  labelClassName?: string;
  label?: string;
  initialZip?: string;
  hasInvalidZipError: boolean;
  invalidZipErrorMessage?: string;
  searchErrorMessage?: string;

  // events & handlers
  autoSearchOnReady: boolean;
  onSearchReady?: (search: SearchFunction) => void;
  onSearchError?: () => void;
  onSearching?: () => void;
  onSearchComplete: (countyResults: County[], zipcode: string) => void;
}

const CountySearchField: FunctionComponent<CountySearchFieldProps> = ({
  className = "",
  label = "",
  labelClassName = "",
  initialZip,
  hasInvalidZipError = false,
  invalidZipErrorMessage = "",
  searchErrorMessage = "",
  autoSearchOnReady,
  onSearchReady,
  onSearchError,
  onSearching,
  onSearchComplete,
}) => {
  const t = useTranslate();
  const [countyRequestError, setCountyRequestError] = useState<boolean>(false);

  const searchForCounties = async (zipcode: string): Promise<County[]> => {
    if (onSearching) {
      onSearching();
    }

    try {
      // TODO: Replace this call with `useCounties` hook. https://jira.cms.gov/browse/MCT-9682
      const counties = await api.getCounties(zipcode);
      onSearchComplete(counties, zipcode);
      setCountyRequestError(false);
      return counties;
    } catch (error) {
      setCountyRequestError(true);
      if (onSearchError) {
        onSearchError();
      }
    }

    return [];
  };

  const setReadyOrSubmit = (zipcode: string): void => {
    if (autoSearchOnReady) {
      searchForCounties(zipcode);
    } else if (!autoSearchOnReady) {
      if (onSearchReady) {
        // provide the parent with a function bound to the zipcode
        onSearchReady(() => searchForCounties(zipcode));
      }
    }
  };

  const onZipChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const field = e.target;

    setCountyRequestError(false);

    if (field.value.length > ZIP_LENGTH) {
      field.value = field.value.substring(0, ZIP_LENGTH);
    }
    if (isValidZipcode(field.value)) {
      setReadyOrSubmit(field.value);
    } else {
      if (onSearchError) {
        onSearchError();
      }
    }
  };

  useEffect(() => {
    if (initialZip) {
      setReadyOrSubmit(initialZip);
    }
  }, []);

  const checkHasError = (): string => {
    let errorMessage = "";

    if (hasInvalidZipError) {
      errorMessage = invalidZipErrorMessage || t("state_picker.error_message");
    }

    if (countyRequestError) {
      errorMessage =
        searchErrorMessage || t("state_picker.error_fetching_message");
    }

    return errorMessage;
  };

  return (
    <TextField
      className={`${className} CountySearchField`}
      label={label || t("county_search.label")}
      labelClassName={`${labelClassName} ds-text-heading--sm`}
      name="county"
      onChange={onZipChange}
      errorMessage={checkHasError() || undefined}
    />
  );
};

export default CountySearchField;
