import { useRouteMatch } from "react-router-dom";
import { MedigapPlanType } from "../../@types";
import { LongPlanId } from "./types";

export interface RouteParams {
  medigapPlanType?: MedigapPlanType;
  stateAbbr?: string;
  drugId?: string;
  planId?: LongPlanId;
}

/**
 * This is a type-safe hook to access route params.
 */
export const useRouteParams = () => {
  const match = useRouteMatch();
  const {
    medigapPlanType: rawMedigapPlanType,
    state,
    drug,
    id,
  } = match.params as {
    medigapPlanType?: MedigapPlanType;
    state?: string;
    drug?: string;
    id?: LongPlanId;
  };

  /**
   * medigapPlanType derived from route parameters
   * converted into a proper format, then checked against the MedigapPlanType enum to ensure validity.
   * */
  const medigapPlanType = (() => {
    if (rawMedigapPlanType) {
      // Since the route could have different capitalization, this ensures that we have a proper `MedigapPlanType`
      const convertedPlanType = rawMedigapPlanType
        .trim()
        .toUpperCase() as MedigapPlanType;
      if (Object.values(MedigapPlanType).includes(convertedPlanType)) {
        return convertedPlanType;
      }
    }
    return;
  })();

  return {
    medigapPlanType,
    stateAbbr: state,
    drugId: drug,
    planId: id,
  } as RouteParams;
};
