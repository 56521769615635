import React, { FunctionComponent, useContext } from "react";
import { Button } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers";
import { AppContext } from "../app/store";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";

interface FootnoteMarkerProps {
  id: string;
  marker: string;
}

const FootnoteMarker: FunctionComponent<FootnoteMarkerProps> = ({
  id,
  marker,
}) => {
  const t = useTranslate();
  const { dispatch } = useContext(AppContext);
  const footnoteId = `${id}-footnote`;
  const markerId = `${id}-marker`;

  return (
    <Button
      size="small"
      variation="ghost"
      className="Footnote__marker"
      id={markerId}
      aria-label={t("footnote_marker.go_to_content")}
      onClick={(): void => {
        const footnote: HTMLDivElement | null = document.querySelector(
          `#${footnoteId}`
        );
        dispatch({
          type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
          settings: {
            button: {
              buttonStyle: AnalyticsButtonStyle.DEFAULT,
              buttonType: AnalyticsButtonType.LINK,
              text: marker,
            },
          },
        });
        footnote && footnote.scrollIntoView(false); // scroll bottom to bottom of viewport
        footnote?.focus();
      }}
    >
      <sup>{marker}</sup>
    </Button>
  );
};

export default FootnoteMarker;
