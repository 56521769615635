import React from "react";

/**
 * Use as a `keydown` event handler to prevent entering a zero as the first character
 * in an input
 */
export const preventLeadingZero = (
  e: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>
) => {
  const isInput = e.target && "selectionStart" in e.target;
  if (!isInput || e.type !== "keydown") {
    return;
  }
  if (e.key === "0" && e.target.selectionStart === 0) {
    e.preventDefault();
  }
};
