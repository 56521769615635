/* eslint-disable max-lines */
import URI from "urijs";
import * as T from "./@types";
import { getReferrer, sortByFormAndStrength } from "./helpers";
import { config } from "./config";
import cookies from "browser-cookies";
import { ApiError, ApiResponseError, logError } from "./helpers/errors";
import * as TG from "./@types/guards";

async function handleApiError(
  response: Response,
  body?: string
): Promise<ApiError> {
  const requestId = response.headers.get("x-request-id");
  const anonymousSessionId = cookies.get("mct-anonymous-session-id");
  const beneSessionId = cookies.get("sid");
  const { status, statusText, url } = response;

  const customInfo = {
    requestId,
    anonymousSessionId,
    beneSessionId,
    apiResponseStatus: status,
    apiResponseStatusText: statusText,
    apiRequestUrl: url,
    apiRequestBody: body,
  };

  try {
    const bodyJson: T.ApiErrorResponse = await response.json();

    const {
      code: apiErrorCode,
      error: apiError,
      message: apiErrorMessage,
      details,
    } = bodyJson;

    const apiResponseError = new ApiResponseError(undefined, apiErrorMessage);
    apiResponseError.info = {
      ...customInfo,
      apiError,
      apiErrorMessage,
      apiErrorCode,
      apiResponseDetails:
        Array.isArray(details) && details[0]?.serving_data
          ? details[0].serving_data
          : null,
    };

    return Promise.resolve(apiResponseError);
  } catch (e) {
    const apiResponseParseError = new ApiResponseError("apiResponseParseError");
    apiResponseParseError.info = customInfo;

    return Promise.resolve(apiResponseParseError);
  }
}

export interface ResWithHeaders {
  body: { [x: string]: unknown };
  headers: Headers;
}

const getRequestHeaders = ({
  headers = {},
  method = "GET",
}: {
  headers?: Request["headers"] | Record<string, unknown>;
  method?: Request["method"];
}) => {
  const requestHeaders = new Headers({
    "Fe-Ver": config.FE_VERSION,
    ...headers,
  });
  // Adds X-Requested-With: XMLHttpRequest for using local proxy, when an env
  // property is set
  // @see https://expressjs.com/en/api.html#req.xhr
  // @see /src/setupProxy.js
  if (config.USE_LOCAL_PROXY) {
    requestHeaders.append("X-Requested-With", "XMLHttpRequest");
  }
  if (method === "POST") {
    requestHeaders.append("Content-Type", "application/json");
  }
  return requestHeaders;
};

/**
 * This function does the 'heavy lifting' of querying resources from the API. There is a base URI brought in from [[config]], which params are added to, and then `fetch` grabs the resource.
 * Next, we check `response.ok` and either return an error or the JSON.
 * @param path The path to the sought-after resource
 * @param params Zero or more query params, added via URI.js
 * @param headers Zero or more headers to add to the request
 */
export async function get(
  path: string,
  params: Record<string, unknown> | T.PharmaciesRequest = {},
  headers: Record<string, string | undefined> = {}
): Promise<ResWithHeaders> {
  const url = URI(
    config.USE_LOCAL_PROXY ? path : `${config.MCTAPI_URI}${path}`
  );

  Object.entries(params).forEach(([key, value]) => {
    url.addSearch(key, value);
  });

  const interaction = window.newrelic?.interaction();
  interaction?.setName(url.toString()).save();

  const response = await fetch(url.toString(), {
    credentials: "same-origin",
    headers: getRequestHeaders({ headers }),
    referrerPolicy: "strict-origin-when-cross-origin",
    referrer: getReferrer(),
  });

  interaction?.end();

  if (!response.ok) {
    throw await handleApiError(response);
  }

  return { headers: response.headers, body: await response.json() };
}

export async function post(
  path: string,
  body: Record<string, unknown> | T.EnrollDataForAPI,
  params?: Record<string, unknown> | GetPlansParams
): Promise<ResWithHeaders> {
  const url = URI(
    config.USE_LOCAL_PROXY ? path : `${config.MCTAPI_URI}${path}`
  );

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      url.addSearch(key, value);
    });
  }

  const interaction = window.newrelic?.interaction();
  interaction?.setName(url.toString()).save();

  const method = "POST";

  const response = await fetch(url.toString(), {
    method,
    headers: getRequestHeaders({ method }),
    body: JSON.stringify(body),
    credentials: "same-origin",
    referrerPolicy: "strict-origin-when-cross-origin",
    referrer: getReferrer(),
  });

  interaction?.end();

  if (!response.ok) {
    throw await handleApiError(response, JSON.stringify(body));
  }

  return { headers: response.headers, body: await response.json() };
}

export interface APIChatInitParams {
  Language: string;
  CurrentView: string;
}

export async function getAPIChatInit({
  Language,
  CurrentView,
}: APIChatInitParams): Promise<T.ChatInit | undefined> {
  const res = await get("/beneinfo/init-chat", {
    Language,
    CurrentView,
  });

  return TG.isChatInit(res.body) ? res.body : undefined;
}

export async function getBeneDrugs(): Promise<T.PrescriptionDrug[]> {
  const res = await get("/beneinfo/get-drugs", {});

  return TG.hasPrescriptions(res.body.drugs) ? res.body.drugs : [];
}

export async function sendKeepAlive(): Promise<ResWithHeaders> {
  return await get("/beneinfo/keep-alive", {});
}

export async function saveBeneDrugs(
  drugs: T.PrescriptionDrug[]
): Promise<ResWithHeaders> {
  return await post("/beneinfo/save-drugs", { drugs });
}

export async function saveBenePharmacies(
  npis: string[]
): Promise<ResWithHeaders> {
  return post("/beneinfo/save-pharmacies", { npis });
}

export async function getBenePharmacies(): Promise<{
  pharmacies: T.Pharmacy[];
  mail_order: boolean;
}> {
  const {
    body: { pharmacies, mail_order },
  } = await get("/beneinfo/get-pharmacies", {});
  return TG.hasPharmacies(pharmacies)
    ? { pharmacies, mail_order: Boolean(mail_order) }
    : { pharmacies: [], mail_order: false };
}

export async function getCounty(fips: string): Promise<T.County | undefined> {
  const {
    body: { county },
  } = await get("/geography/fips", { fips });
  return TG.isCounty(county) ? county : undefined;
}

export async function getCounties(zipcode: string): Promise<T.County[]> {
  const {
    body: { counties },
  } = await get("/geography/counties", { zipcode });
  return TG.hasCounties(counties) ? counties : [];
}

export async function getDrugsByLetter(
  letter: string,
  year?: string
): Promise<T.AutocompleteDrug[]> {
  const query = year ? { year } : {};
  const {
    body: { drugs },
  } = await get(`/drugs/list/${letter}`, query);

  return TG.hasAutocompleteDrugs(drugs) ? drugs : [];
}

export async function getPharmacies(
  params: T.PharmaciesRequest
): Promise<T.PharmaciesResponse> {
  const { body } = await get(`/geography/pharmacies/address`, params);

  return TG.isPharmaciesResponse(body) ? body : Promise.reject("Server Error");
}

export async function getLdFlags(): Promise<T.LdFlagsResponse | undefined> {
  const { body } = await get("/ldflags");

  return TG.isLdFlagsResponse(body) ? body : undefined;
}

/**
 *  @param id The plan id with url parts: {year}/{contract_id}/{plan_id}/{segment_id}
 */
export async function getInNetworkPharmacies(
  id: string,
  params: T.PharmaciesRequest
): Promise<T.InNetworkPharmaciesResponse> {
  const { body } = await get(`/geography/plan/${id}/pharmacies`, params);

  return TG.isInNetworkPharmaciesResponse(body)
    ? body
    : Promise.reject("Server Error");
}

/**
 * This function queries the backend for a specific [[Plan]]
 * /plan/{year}/{contract_id}/{plan_id}/{segment_id}
 * @param id The plan id with url parts: {year}/{contract_id}/{plan_id}/{segment_id}
 * Example: 2021/H5652/002/0
 * @param queryParams an object containing extra parameters for the request
 * @param headers an object containing extra headers for the request
 */
export async function getPlan(
  id: string,
  queryParams: Record<string, string | undefined> = {},
  headers: Record<string, string | undefined> = {}
): Promise<T.Plan | undefined> {
  const {
    body: { plan_card },
  } = await get(`/plan/${id}`, queryParams, headers);
  return TG.isPlan(plan_card) ? plan_card : undefined;
}

export interface GetPlansParams {
  fips: string;
  plan_type?: T.PlanType | T.PlanType[];
  sort_order?: string;
  page?: number;
  year?: string;
  zip?: string;
  snp_type?: T.PlanSNPType[];
  sanctioned_only?: boolean;
}

/**
 * This function exists to get a collection of [[Plan]]s from the backend.
 * @default year The plan year, to make sure that only 'valid' plans are returned.
 */
export async function getPlans(
  params: GetPlansParams,
  body: Record<string, unknown> = {}
): Promise<T.PlanResults | undefined> {
  const defaultParams: Omit<GetPlansParams, "fips"> = {
    plan_type: T.PlanType.MAPD,
    page: 0,
    year: "2020",
  };
  params = { ...defaultParams, ...params };
  try {
    const { body: resBody } = await post("/plans/search", body, params);
    return TG.isPlanResults(resBody) ? resBody : undefined;
  } catch (apiError) {
    return undefined;
  }
}

/**
 * getPlanCard returns a [[SearchResultPlan]] for a single plan
 * @param id The plan id
 * @param npis The NPIS of the beneficiary's pharmacies
 * @param lis The beneficiary's [[LowIncomeSubsidyStatus]]
 * @param headers Any additional headers to add to the request
 */
export async function getPlanCard(
  id: string,
  npis: string[],
  lis: T.LowIncomeSubsidyStatus,
  headers: Record<string, string | undefined> = {}
): Promise<T.SearchResultPlan | undefined> {
  const {
    body: { plan },
  } = await get(
    `/plan/card/${id}`,
    {
      npis,
      lis: lis ?? T.LowIncomeSubsidyStatus.LIS_NO_HELP,
    },
    headers
  );
  return TG.isSearchResultPlan(plan) ? plan : undefined;
}

export async function getPlanInfoForNextYear(
  id: string
): Promise<T.RolloverPlanInfo[] | undefined> {
  const res = await get(`/plan/next/${id}`, {});
  if (res.body.plans && Array.isArray(res.body.plans)) {
    return res.body.plans.map(plan => {
      return {
        contract_id: plan.contract_id,
        contract_year: plan.plan_year,
        segment_id: plan.segment_id,
        plan_id: plan.plan_id,
        status: plan.status,
      };
    });
  }
  return undefined;
}

export async function getAutocompleteDrugList(
  name: string,
  year?: string
): Promise<T.AutocompleteDrug[] | undefined> {
  const query = year ? { name, year } : { name };
  const {
    body: { drugs },
  } = await get(`/drugs/autocomplete`, query);

  return TG.hasAutocompleteDrugs(drugs) ? drugs : undefined;
}

export async function getDrugDosages(
  rxcui: string,
  year?: string
): Promise<T.DrugDosageInfo | undefined> {
  const query = year ? { year } : {};
  const res = await get(`/drugs/${rxcui}/forms`, query);
  const { dosages } = res.body;

  if (TG.hasDrugDosages(dosages)) {
    let commonDosage = null;
    if (
      dosages.length &&
      dosages[0].packages.length &&
      dosages[0].packages[0].volume
    ) {
      commonDosage = dosages[0];
    }
    const dosageInfo: T.DrugDosageInfo = {
      commonDosage,
      dosages: dosages.sort(sortByFormAndStrength),
    };

    return dosageInfo;
  }
  return undefined;
}

export async function getLoggedInBeneInfo(): Promise<T.BeneficiaryAndApiHeaders> {
  const res = await get(`/beneinfo/userinfo`, {});
  const beneficiary = TG.isBeneficiary(res.body) ? res.body : undefined;
  return { headers: res.headers, beneficiary };
}

export async function getCSRBeneInfo(
  token: string
): Promise<T.BeneficiaryAndApiHeaders> {
  const res = await get(`/beneinfo/login-csr`, { token });
  const beneficiary = TG.isBeneficiary(res.body) ? res.body : undefined;

  return { headers: res.headers, beneficiary };
}

export async function getDrugCosts({
  npis,
  prescriptions,
  planIds,
  lis = T.LowIncomeSubsidyStatus.LIS_NO_HELP,
  fullYearPricing = false,
  retailOnly = false,
}: {
  npis: string[];
  prescriptions: T.DrugCostPrescriptionInfo[];
  planIds: T.FullPlanId[];
  lis?: T.LowIncomeSubsidyStatus;
  fullYearPricing?: boolean;
  retailOnly?: boolean;
}): Promise<T.DrugCostsResponse | undefined> {
  const res = await post(`/drugs/cost`, {
    npis,
    prescriptions,
    lis,
    plans: planIds,
    full_year: fullYearPricing,
    retailOnly,
  });

  return TG.isDrugCostsResponse(res.body) ? res.body : undefined;
}

export async function getOtherDrugInfo(
  ndcs: string[],
  planUrl: string
): Promise<T.DrugInfo[] | undefined> {
  const {
    body: { drugInfoList },
  } = await post(`/drugs/info/${planUrl}`, { ndcs });

  return TG.hasDrugInfo(drugInfoList) ? drugInfoList : undefined;
}

export async function sendLogoutRequest(): Promise<unknown> {
  return await get("/beneinfo/logout", {});
}

export async function getBeneOecStatus(
  year: string
): Promise<T.OecStatus | null> {
  try {
    const {
      body: { statuses },
    } = await get("/beneinfo/oec-status", { year });

    return TG.hasOecStatus(statuses) && statuses.length ? statuses[0] : null;
  } catch (_e) {
    const e = _e as ApiError;
    logError("Failed to get beneficiary OEC status (GlobalSessionHandler)", e);
    return null;
  }
}

export async function submitEnrollment(
  enrollData: T.EnrollDataForAPI
): Promise<T.EnrollResponse | undefined> {
  const { body } = await post(`/beneinfo/oec-enroll`, enrollData);
  const response = TG.isEnrollResponse(body) ? body : undefined;

  if (response?.status !== T.EnrollResponseCode.OEC_VALID) {
    const errorMessage =
      response?.status ?? T.EnrollResponseCode.OEC_UNKNOWN_ERROR;
    const error = new Error(errorMessage);
    logError("Failed to submit enrollment (EnrollmentFormPage/Review)", error);
    throw error;
  }

  return response;
}

export async function validateMbi(mbi = ""): Promise<{ valid: boolean }> {
  const {
    body: { valid },
  } = await post("/beneinfo/validate-mbi", { mbi });

  return TG.isBoolean(valid) ? { valid } : { valid: false };
}

export async function getMedigapPlanPremiumRanges(
  state: string,
  zipcode: string,
  demographics?: T.DemographicInfo
): Promise<T.MedigapPlanPremiumRange[]> {
  const {
    body: { plans },
  } = await get(`/medigap/plans`, {
    state,
    zipcode,
    ...demographics,
  });

  return TG.hasMedigapPlanPremiumRanges(plans) ? plans : [];
}

export async function getCwPremiumRanges(params: {
  year: string;
  zipcode: string;
  fips: string;
}): Promise<T.CwPremiumRanges | undefined> {
  const { body } = await get("/plans/premiumranges", {
    ...params,
    zip: params.zipcode,
  });

  return TG.isCwPremiumRangesResponse(body) ? body : undefined;
}

export async function getGlobalSessionInfo(): Promise<
  T.GlobalSessionInfo | undefined
> {
  const res = await fetch("/api/sls/session/info");

  if (!res.ok) {
    throw await handleApiError(res);
  }

  const body = await res.json();

  return TG.isGlobalSessionInfo(body) ? body : undefined;
}

export async function getMedigapPlanPolicies(
  state: string,
  zipcode: string,
  medigapPlanType: T.MedigapPlanType,
  demographics?: T.DemographicInfo
): Promise<T.MedigapPlanPolicy[]> {
  const {
    body: { policies },
  } = await get(`/medigap/policies`, {
    medigap_plan_type: medigapPlanType,
    state,
    zipcode,
    ...demographics,
  });

  return TG.hasMedigapPlanPolicies(policies) ? policies : [];
}

export async function getPlanMetadata(): Promise<T.PlanDataVersion[]> {
  const {
    body: { versions },
  } = await get("/plan/data", {});

  return TG.hasPlanDataVersions(versions) ? versions : [];
}

export async function getOriginalMedicareStarRatings(
  fips: string,
  year: string
): Promise<T.StarRating[]> {
  const {
    body: { star_ratings },
  } = await get("/plans/omstarratings", { fips, year });

  return TG.hasStarRatings(star_ratings) ? star_ratings : [];
}

export async function getPacePlanStates(year?: string): Promise<string[]> {
  const {
    body: { states },
  } = await get("/plans/pace/states", year ? { year } : {});

  return TG.hasStrings(states) ? states : [];
}

export async function getPacePlansForState(
  state: string,
  year?: string
): Promise<T.PacePlan[]> {
  const {
    body: { contracts },
  } = await get("/plans/pace/contracts", year ? { state, year } : { state });

  return TG.hasPacePlans(contracts) ? contracts : [];
}

export async function getAutocompletePapDrugList(
  name: string,
  year?: string
): Promise<T.AutocompletePapDrug[] | undefined> {
  const query = year ? { name, year } : { name };
  const {
    body: { drugs },
  } = await get(`/pap/drugs/autocomplete`, query);

  return TG.hasAutocompletePapDrugs(drugs) ? drugs : undefined;
}

export async function getPapDrugsByLetter(
  letter: string,
  year?: string
): Promise<T.AutocompletePapDrug[]> {
  const query = year ? { year } : {};
  const {
    body: { drugs },
  } = await get(`/pap/drugs/list/${letter}`, query);

  return TG.hasAutocompletePapDrugs(drugs) ? drugs : [];
}

export async function getPapPlansForDrug(
  drug_id: string,
  year?: string
): Promise<{ drugName: string; programs: T.PapPlan[] }> {
  const {
    body: { programs, drug_name },
  } = await get(`/pap/drug-programs`, { drug_id, year });

  return {
    drugName: drug_name as string,
    programs: TG.hasPapPlans(programs) ? programs : [],
  };
}

// SPAP (State Pharmaceutical Assistance Program)
// get list of states in SPAP.
export async function getSpapPlanStates(year?: string): Promise<string[]> {
  const {
    body: { states },
  } = await get("/pap/states", year ? { year } : {});

  return TG.hasStrings(states) ? states : [];
}

// SPAP (State Pharmaceutical Assistance Program)
// get a list of SPAP plans for the given state
export async function getPapPlansForState(
  state: string,
  year?: string
): Promise<T.SpapPlan[]> {
  const {
    body: { programs },
  } = await get(`/pap/state-programs`, year ? { state, year } : { state });

  return TG.hasSpapPlans(programs) ? programs : [];
}

/**
 * @deprecated this function should only be used in `useStatusSummary` */
export async function getPharmaciesStatusSummary(
  planIds: T.PlanID[]
): Promise<T.PlanPreferredPharmacyInfo[]> {
  try {
    const {
      body: { plans },
    } = await post(`/pharmacies/status-summary`, {
      plans: planIds,
    });
    return TG.hasPlanPreferredPharmacyInfo(plans) ? plans : [];
  } catch (e) {
    logError(
      `Failed to get preferred pharmacy status summary for plans. plans=${JSON.stringify(
        planIds
      )})}`,
      e as ApiError
    );
    throw e;
  }
}

export async function getBeneMedigapCoverages(
  status: T.BeneInfoOtherInsuranceStatusFilter = T
    .BeneInfoOtherInsuranceStatusFilter.OTHER_INSURANCE_STATUS_CURRENT
): Promise<T.MedigapCoverage[]> {
  const reqParams: T.BeneInfoOtherInsuranceInfoParams = {
    coba_type: T.BeneInfoOtherInsuranceCobaTypeFilter.COBA_TYPE_FILTER_MEDIGAP,
    status,
  };
  const {
    body: { other_insurances },
  } = (await get(`/beneinfo/other-insurance`, reqParams)) as Omit<
    ResWithHeaders,
    "body"
  > & {
    body: T.MedigapCoveragesResponseType;
  };
  return TG.hasMedigapCoverages(other_insurances) ? other_insurances : [];
}
