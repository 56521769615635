import { Button } from "@cmsgov/ds-medicare-gov";
import React, { useContext } from "react";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../../../app/contexts/Analytics/types";
import { AppContext } from "../../../app/store";
import { useTranslate } from "../../../helpers";

interface ZipcodeAndCoverageYearStepActionsProps {
  onClickContinue: () => void;
  isInModal?: boolean;
  handleCancel?: () => void;
}
export const ZipcodeAndCoverageYearStepActions: React.FunctionComponent<
  ZipcodeAndCoverageYearStepActionsProps
> = ({ handleCancel, isInModal, onClickContinue }) => {
  const { dispatch } = useContext(AppContext);
  const t = useTranslate();
  const continueButtonText = t("coverage_selector.continue");
  const cancelButtonText = t("coverage_selector.cancel");
  return (
    <>
      <Button
        variation="solid"
        className="mct-c-coverage-selector-v2__start-button"
        data-testid="continue-button"
        type="button"
        onClick={() => {
          onClickContinue();
          dispatch({
            type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
            settings: {
              button: {
                buttonStyle: AnalyticsButtonStyle.PRIMARY,
                buttonType: AnalyticsButtonType.BUTTON,
                text: continueButtonText,
              },
            },
          });
        }}
      >
        {continueButtonText}
      </Button>
      {isInModal && handleCancel && (
        <Button
          variation="ghost"
          className="mct-c-coverage-selector-v2__go-back-button"
          type="button"
          data-testid={"coverage-selector-cancel"}
          onClick={() => {
            handleCancel();
            dispatch({
              type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
              settings: {
                button: {
                  buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
                  buttonType: AnalyticsButtonType.BUTTON,
                  text: cancelButtonText,
                },
              },
            });
          }}
        >
          {cancelButtonText}
        </Button>
      )}
    </>
  );
};
