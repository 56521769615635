import {
  Plan,
  LowIncomeSubsidyStatus,
  SearchResultPlan,
  PlanCategoryType,
} from "../@types";
import { $$ } from "../helpers";
import { CONSTANTS } from "./CONSTANTS";

const {
  LIS_LEVEL_1A,
  LIS_LEVEL_1A_DN,
  LIS_LEVEL_1B,
  LIS_LEVEL_1C,
  LIS_LEVEL_2,
  LIS_LEVEL_3,
  LIS_LEVEL_4_100,
  LIS_LEVEL_4_25,
  LIS_LEVEL_4_50,
  LIS_LEVEL_4_75,
} = LowIncomeSubsidyStatus;

export const getPartDPremiumOrLIS = ({
  lisToUse,
  plan,
}: {
  lisToUse: LowIncomeSubsidyStatus;
  plan: Plan | SearchResultPlan;
}): number => {
  if (!plan.lis) {
    return plan.partd_premium;
  }

  if (
    [
      LIS_LEVEL_1A,
      LIS_LEVEL_1A_DN,
      LIS_LEVEL_1B,
      LIS_LEVEL_1C,
      LIS_LEVEL_2,
      LIS_LEVEL_3,
      LIS_LEVEL_4_100,
    ].includes(lisToUse)
  ) {
    return plan.lis.level_100;
  } else if ([LIS_LEVEL_4_75].includes(lisToUse)) {
    return plan.lis.level_75;
  } else if ([LIS_LEVEL_4_50].includes(lisToUse)) {
    return plan.lis.level_50;
  } else if ([LIS_LEVEL_4_25].includes(lisToUse)) {
    return plan.lis.level_25;
  } else {
    return plan.partd_premium;
  }
};

/**
 * For LIS 4 benes, up through CY23, returns the lesser of the `plan.drug_plan_deductible
 * or a hard-coded maxiumum deductible
 * @TODO - When CY23 plans are no longer avialable, this helper can be removed,
 * as well as the hard-coded `maxLisDrugDeductibles`. All helpers that depend on this
 * can be revised and simplified
 */
export const getLisDeductibleToUse = ({
  contractYear,
  drugPlanDeductible,
}: {
  contractYear: string;
  drugPlanDeductible: number;
}): number => {
  const contractYearNum = Number(contractYear);
  const cyLt24 = !isNaN(contractYearNum) && contractYearNum < 2024;
  const maxDrugDeductible: number =
    CONSTANTS.maxLisDrugDeductibles[contractYear] ||
    (cyLt24 ? drugPlanDeductible : 0);

  return drugPlanDeductible < maxDrugDeductible
    ? drugPlanDeductible
    : maxDrugDeductible;
};

/**
 * Used as the basis for what to display on plan cards and in overview sections of
 * plan details and plan compare
 * @TODO - When CY23 plans are no longer available, this helper can probably be
 * removed. The logic will be to return the `plan.drug_plan_deductible` for non-LIS
 * benes, and 0 for all LIS benes
 */
export const getAnnualDrugDeductible = ({
  lisToUse,
  drugPlanDeductible,
  contractYear,
}: {
  lisToUse: LowIncomeSubsidyStatus;
  drugPlanDeductible: number;
  contractYear: string;
}): number => {
  if (lisToUse === LowIncomeSubsidyStatus.LIS_NO_HELP) {
    return drugPlanDeductible;
  } else if (/LEVEL_[123]/.test(lisToUse)) {
    // LIS levels 1-3 always have a $0 deductible (will apply to all LIS levels in 2024)
    return 0;
  } else {
    return getLisDeductibleToUse({
      drugPlanDeductible,
      contractYear,
    });
  }
};

/**
 * Returns a dollar-formatted value to display for LIS and non-LIS benes. Can likely
 * be simplified or removed when CY23 plans are no longer available
 */
export const getDisplayAnnualDrugDeductible = ({
  lisToUse,
  drugPlanDeductible,
  contractYear,
}: {
  lisToUse: LowIncomeSubsidyStatus;
  drugPlanDeductible: number;
  contractYear: string;
}): string => {
  return $$(
    getAnnualDrugDeductible({
      lisToUse,
      drugPlanDeductible,
      contractYear,
    })
  );
};

export const getPriceForYear = (
  prices: { [key: string]: number },
  year?: string
): number => (year && prices[year]) || prices[Object.keys(prices).pop() || ""];

export const getPartBPremiumForYear = (year?: string | number): number =>
  getPriceForYear(CONSTANTS.partBPremium, `${year}`);

export const getPartBPremium = (plan: Plan | SearchResultPlan): number => {
  const premium = getPartBPremiumForYear(plan.contract_year);

  return plan.category === PlanCategoryType.MEDICARE_MEDICAID_PLAN
    ? 0
    : premium;
};

export const getPartBHealthDeductibleForYear = (year?: string): number =>
  getPriceForYear(CONSTANTS.partBHealthDeductible, year);

export const showMMPBenefits = (plan: Plan): boolean | null => {
  return (
    plan.additional_supplemental_benefits &&
    !!plan.additional_supplemental_benefits.mmp_benefits.length &&
    plan.category === PlanCategoryType.MEDICARE_MEDICAID_PLAN
  );
};
