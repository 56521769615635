import React, { useContext } from "react";
import { HashLink } from "react-router-hash-link";
import {
  SearchResultPlan,
  PlanCategoryType,
  PlanDetailsPageSection,
} from "../../@types";
import {
  isPdPlanType,
  isMaPlanType,
  isMapdPlanType,
  useTranslate,
} from "../../helpers";
import { CheckmarkIcon, PlanDetailsLink } from "..";
import { AppContext } from "../../app/store";
import {
  AnalyticsActionType,
  Ga4Event,
} from "../../app/contexts/Analytics/types";

interface DrugsProps {
  plan: SearchResultPlan;
  drugSelectionRoute: string;
  onGoToDrugSelection: () => void;
}

const Drugs: React.FunctionComponent<DrugsProps> = ({
  plan,
  drugSelectionRoute,
  onGoToDrugSelection,
}) => {
  const t = useTranslate();
  const {
    state: { prescriptions },
    dispatch,
  } = useContext(AppContext);

  const planDetailsLink = (
    <PlanDetailsLink
      ariaDescribedBy={`${plan.id}-heading ${plan.id}-drugs`}
      htmlId={`${plan.id}-drugs`}
      label={t("plan_terms.view_drugs_and_pharmacies")}
      plan={plan}
      onClick={() =>
        dispatch({
          type: AnalyticsActionType.SEND_GA4_EVENT,
          settings: {
            event_name: Ga4Event.VIEW_DRUG_COVERAGE,
            text: "View drugs and their costs",
          },
        })
      }
      section={PlanDetailsPageSection.DRUG_COVERAGE}
    />
  );

  let content;
  if (isMaPlanType(plan.plan_type)) {
    content = (
      <div>
        <CheckmarkIcon checked={false} />{" "}
        {t("plan_card.does_not_include_drugs")}
        {[
          PlanCategoryType.COST_1876,
          PlanCategoryType.PFFS,
          PlanCategoryType.MSA,
        ].includes(plan.category) && (
          <p className="ds-u-margin--0">
            {t("plan_card.pffs_cost.can_also_join")}
          </p>
        )}
      </div>
    );
  } else if (!prescriptions.length) {
    content = (
      <div>
        <HashLink to={drugSelectionRoute} onClick={onGoToDrugSelection}>
          {t("plan_card.add_your drugs")}
        </HashLink>
        <p className="ds-u-margin--0">{t("plan_card.add_your drugs_info")}</p>
      </div>
    );
  } else if (isPdPlanType(plan.plan_type)) {
    content = <div>{planDetailsLink}</div>;
  } else if (isMapdPlanType(plan.plan_type)) {
    content = (
      <div>
        <div>
          <CheckmarkIcon checked /> {t("plan_card.includes_drugs")}
        </div>
        <div>{planDetailsLink}</div>
      </div>
    );
  }

  return (
    <div className="PlanCard__section" data-testid="drugs">
      <h3 className="PlanCard__section_heading">
        {t("plan_card.section_heading.drugs")}
      </h3>
      {content}
    </div>
  );
};

export default Drugs;
