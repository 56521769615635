import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Dialog, DialogProps } from "@cmsgov/ds-medicare-gov";

export const MandatoryDialog: FunctionComponent<DialogProps> = ({
  children,
  ...otherProps
}) => {
  const handleEscapeButton = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeButton, { capture: true });
    return () => document.removeEventListener("keydown", handleEscapeButton);
  }, []);

  return <Dialog {...otherProps}>{children}</Dialog>;
};
