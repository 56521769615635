import React, { FC, useCallback } from "react";
import { DrugCostInfo, DrugInfo, PharmacyType, Plan } from "../../@types";
import {
  isMedicareAdvantageType,
  isPdPlanType,
  useTranslate,
} from "../../helpers";
import {
  getInNetworkPrimaryDoctorCost,
  getHealthDeductibles,
  formatCost,
  useTranslateHealthDeductibleLabel,
} from "../../helpers/benefitHelpers";
import { FeatureSubtitle } from "./FeatureSubtitle";
import { SuppressionHandler } from "..";
import { DrugCoverageLabel } from "./DrugCoverageLabel";
import { DrugCoverage } from "./DrugCoverage";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useAppContext } from "../../helpers/context-hooks";

export const whatYoullPayFeatureTestId = "what-youll-pay-feature";
export const whatYoullPayFeatureLabelTestId = "what-youll-pay-feature__label";
export const whatYoullPayFeatureValueTestId = "what-youll-pay-feature__value";

export type WhatYoullPayProps = {
  drugCostInfo: DrugCostInfo;
  drugInfo: DrugInfo[];
  plan: Plan;
  renderTitle?: boolean;
  showViewDrugCoverageLink?: boolean;
};

export const WhatYoullPay: FC<WhatYoullPayProps> = ({
  drugCostInfo,
  drugInfo,
  plan,
  renderTitle = true,
  showViewDrugCoverageLink = false,
}) => {
  // * Translation
  const t = useTranslate();

  // * Context
  const {
    state: { prescriptions, pharmacyType },
  } = useAppContext();

  // * Constants
  const { plan_type, contract_year, redactions } = plan;
  const isMAPlan = isMedicareAdvantageType(plan_type);
  const isPDPlan = isPdPlanType(plan_type);
  const numDrugsCovered =
    drugInfo?.reduce((prev, curr) => prev + (curr.on_formulary ? 1 : 0), 0) ??
    0;

  // * Callbacks

  const translatePrimaryDoctor = useCallback(
    (primaryDoctorLabel: string) => {
      switch (primaryDoctorLabel) {
        case "copay":
          return t("plan_terms.copay");
        case "copay per visit":
          return t("primary_doctor.copay_per_visit");
        case "coinsurance per visit":
          return t("primary_doctor.coinsurance_per_visit");
        case "per visit":
          return t("primary_doctor.per_visit");
        case "copay after you pay your deductible":
          return t("primary_doctor.copay_after_you_pay_your_deductible");
        default:
          return primaryDoctorLabel;
      }
    },
    [t]
  );

  const translateHealthDeductibleLabel = useTranslateHealthDeductibleLabel();

  // * Features
  /**
   * TODO: Turn `features` in a stable const.
   * The downside of assigning `features` as an array with `let` is that:
   * - we're recalculating this on every render
   * - changes to `features` are not triggering re-renders. This is *okay* in its current state,
   * since all the logic is in one place, back-to-back. But it's fragile.
   * - a memo ensures that our logic is all kept in one place, which is good for code clarity.
   */
  let features: {
    label: string | JSX.Element;
    value: number | string | JSX.Element;
  }[] = [];

  features.push({
    label: t("what_youll_pay.total_monthly_premium"),
    value: (
      <SuppressionHandler
        redactions={plan.redactions}
        checkShouldSuppressDrugPricing
        className="mct-c-what-youll-pay__cost"
      >
        {formatCost(plan.calculated_monthly_premium)}
      </SuppressionHandler>
    ),
  });

  // @TODO - handle situation if plan can't be fetched, set some kind of message
  // that health deductible and primary doctor can't be determined
  if (isMAPlan && plan) {
    const primaryDoctor = getInNetworkPrimaryDoctorCost(plan, t);
    const healthDeductibles = getHealthDeductibles(plan);
    features = [
      ...features,
      {
        label: t("what_youll_pay.health_deductible"),
        value: (
          <SuppressionHandler redactions={plan.redactions}>
            {healthDeductibles.map((deductible, i) => (
              <React.Fragment key={i}>
                <div
                  className={`mct-c-what-youll-pay__cost${
                    i > 0 ? " ds-u-margin-top--2" : ""
                  }`}
                >
                  {deductible.cost}
                </div>
                {deductible.label && (
                  <FeatureSubtitle>
                    {translateHealthDeductibleLabel(deductible.label)}
                  </FeatureSubtitle>
                )}
              </React.Fragment>
            ))}
          </SuppressionHandler>
        ),
      },
      {
        label: t("what_youll_pay.primary_doctor"),
        value: (
          <SuppressionHandler redactions={plan.redactions}>
            <div className="mct-c-what-youll-pay__cost">
              {primaryDoctor.cost}
            </div>
            {primaryDoctor.label && (
              <FeatureSubtitle>
                {translatePrimaryDoctor(primaryDoctor.label)}
              </FeatureSubtitle>
            )}
          </SuppressionHandler>
        ),
      },
    ];
  }

  if (prescriptions.length) {
    const hasRetail = [
      PharmacyType.RETAIL,
      PharmacyType.MAIL_AND_RETAIL,
    ].includes(pharmacyType);
    const hasMail = [PharmacyType.MAIL, PharmacyType.MAIL_AND_RETAIL].includes(
      pharmacyType
    );

    const renderDrugCoverageFeature = ({
      pdpUseRetailMessaging = false,
    }: {
      pdpUseRetailMessaging?: boolean;
    } = {}) => {
      features = [
        ...features,
        {
          label: (
            <DrugCoverageLabel
              {...{
                contract_year,
                hasMail,
                hasRetail,
                plan_type,
                pdpUseRetailMessaging,
              }}
            />
          ),
          value: (
            <DrugCoverage
              {...{
                drugCostInfo,
                drugsCovered: (
                  <FormattedMessage
                    id="what_youll_pay.num_covered"
                    values={{
                      numDrugsCovered: numDrugsCovered,
                      totalDrugs: prescriptions.length,
                      b: chunks => <b>{chunks}</b>,
                    }}
                  />
                ),
                numDrugsCovered,
                pharmacyType,
                plan_type,
                prescriptions,
                redactions,
                pdpUseRetailMessaging,
                showViewDrugCoverageLink,
              }}
            />
          ),
        },
      ];
    };

    if (isMAPlan) {
      renderDrugCoverageFeature();
    }

    if (isPDPlan) {
      if (hasRetail) {
        renderDrugCoverageFeature({ pdpUseRetailMessaging: true });
      }
      if (hasMail) {
        renderDrugCoverageFeature();
      }
    }
  }

  return (
    <div className="mct-c-what-youll-pay">
      <div className="mct-c-what-youll-pay__contents">
        {renderTitle && (
          <div className="mct-c-what-youll-pay__titleContainer">
            <h3 className="ds-text-heading--2xl ds-u-margin--0">
              {t("what_youll_pay.title")}
            </h3>
          </div>
        )}
        <div className="mct-c-what-youll-pay__features">
          {features.map((feature, fIndex, features) => (
            <div
              className={classNames("mct-c-what-youll-pay__feature-container", {
                "mct-c-what-youll-pay__feature-container--single-feature":
                  features.length === 1,
                "mct-c-what-youll-pay__feature-container--multiple-features":
                  features.length > 1,
              })}
              key={fIndex}
            >
              <dl
                className={classNames("mct-c-what-youll-pay__feature", {
                  "mct-c-what-youll-pay__feature--single-feature":
                    features.length === 1,
                  "mct-c-what-youll-pay__feature--multiple-features":
                    features.length > 1,
                })}
                data-testid={`${whatYoullPayFeatureTestId}-${fIndex}`}
              >
                <dt
                  className={classNames("mct-c-what-youll-pay__feature-label", {
                    "mct-c-what-youll-pay__feature-label--single-feature":
                      features.length === 1,
                    "mct-c-what-youll-pay__feature-label--multiple-features":
                      features.length > 1,
                  })}
                  data-testid={`${whatYoullPayFeatureLabelTestId}-${fIndex}`}
                >
                  {feature.label}
                </dt>
                <dd
                  className={classNames("mct-c-what-youll-pay__feature-value", {
                    "mct-c-what-youll-pay__feature-value--single-feature":
                      features.length === 1,
                    "mct-c-what-youll-pay__feature-value--multiple-features":
                      features.length > 1,
                  })}
                  data-testid={`${whatYoullPayFeatureValueTestId}-${fIndex}`}
                >
                  {feature.value}
                </dd>
              </dl>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
