import {
  Beneficiary,
  ExtendedCoverageInfo,
  IEPStatus,
  NewToMedicareAlertInfo,
  MedicarePartInfo,
  PlanType,
  OecStatus,
  OecStatuses,
  MedigapCoverage,
  PlanCoverageStatus,
} from "../@types";
import { addMonths, addYears, lastDayOfMonth, startOfMonth } from "date-fns";
import { isValidApiDate } from "./dateHelpers";
import { AppContext } from "../app/store";
import { useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Location } from "history";
import {
  EnrollmentPages,
  hasGlobalSession,
  isMedicareAdvantageType,
  parseSearchParams,
  planTypeHasDrugCoverage,
} from ".";
import routes from "../app/routes";
import URI from "urijs";
import { TranslationKey } from "./intlHooks";
import { usePendingEnrollments } from "./query-hooks/usePendingEnrollments";
import { useNewToMedicareAlertInfo } from "./context-hooks/useNewToMedicareAlertInfo";

export const createNewToMedicareAlertInfo = ({
  bene,
  oecStatuses = { currentYear: undefined, nextYear: undefined },
  medigapCoverages = [],
}: {
  bene: Beneficiary;
  oecStatuses?: OecStatuses;
  medigapCoverages: MedigapCoverage[];
}): NewToMedicareAlertInfo => {
  const currentDate = new Date();
  const birthdate = isValidApiDate(bene.birthdate)
    ? new Date(bene.birthdate)
    : undefined;

  const iepStatus = getIEPStatusFromAPI(
    bene.medicare_info.iep_start_date,
    bene.medicare_info.iep_end_date
  );

  const hasMedigap = medigapCoverages.length > 0;

  const pendingPlans: OecStatus[] = [];
  if (oecStatuses.currentYear) {
    pendingPlans.push(oecStatuses.currentYear);
  }
  if (oecStatuses.nextYear) {
    pendingPlans.push(oecStatuses.nextYear);
  }

  const hasPDPPlan =
    containsPDPPlan(bene.coverage_current) ||
    containsPDPPlan(bene.coverage_future) ||
    containsPDPPlan(pendingPlans);
  const hasMAPDPlan =
    containsMAPDPlan(bene.coverage_current) ||
    containsMAPDPlan(bene.coverage_future) ||
    containsMAPDPlan(pendingPlans);
  const hasPartAandB =
    hasMedicarePart(currentDate, bene.medicare_info.part_a) &&
    hasMedicarePart(currentDate, bene.medicare_info.part_b);

  return {
    birthdate,
    iepStatus,
    newToMedicareResult: undefined,
    hasMedigap,
    hasPDPPlan,
    hasMAPDPlan,
    hasPartAandB,
  };
};

/**
 * Calculates the Initial Enrollment Period (IEP) for a bene
 * @param birthdate the birthdate
 * @returns the Initial Enrollment Period
 */
export const calculateIEP = (
  birthdate: Date
): {
  startDate: Date;
  endDate: Date;
} => {
  // offsets are in months
  let startOffset = -3;
  let endOffset = 3;

  if (birthdate.getDate() === 1) {
    // birthdate is the first day of the month
    startOffset = -4;
    endOffset = 2;
  }
  const MEDICARE_ELIGIBILITY_AGE = 65;
  const firstDay = startOfMonth(birthdate);
  const sixyFiveDate = addYears(firstDay, MEDICARE_ELIGIBILITY_AGE);
  const iepStart = addMonths(sixyFiveDate, startOffset);
  const iepEnd = lastDayOfMonth(addMonths(sixyFiveDate, endOffset));

  return {
    startDate: iepStart,
    endDate: iepEnd,
  };
};

/**
 * Gets the IEPStatus from the API IEP dates
 * @param iepStart API IEP start date
 * @param iepEnd API IEP end end
 * @returns IEPStatus or undefined if the IEP dates are invalid
 */
export const getIEPStatus = (
  iepStartDate: Date,
  iepEndDate: Date
): IEPStatus => {
  const currentDate = new Date();
  if (currentDate < iepStartDate) {
    return IEPStatus.IEP_STATUS_BEFORE_IEP;
  }
  if (currentDate > iepEndDate) {
    return IEPStatus.IEP_STATUS_PAST_IEP;
  }
  return IEPStatus.IEP_STATUS_WITHIN_IEP;
};

/**
 * Gets the IEPStatus from the API IEP dates
 * @param iepStart API IEP start date
 * @param iepEnd API IEP end end
 * @returns IEPStatus or undefined if the IEP dates are invalid
 */
export const getIEPStatusFromAPI = (
  iepStart: string,
  iepEnd: string
): IEPStatus | undefined => {
  if (isValidApiDate(iepStart) && isValidApiDate(iepEnd)) {
    const iepStartDate = new Date(iepStart);
    const iepEndDate = new Date(iepEnd);
    return getIEPStatus(iepStartDate, iepEndDate);
  }
  return undefined;
};

export const hasPlanType = (
  coverageInfo: (ExtendedCoverageInfo | OecStatus)[],
  planType: PlanType
): boolean => {
  return !!coverageInfo.find(coverage => coverage.plan_type === planType);
};

export const containsMAPDPlan = (
  coverageInfo: (ExtendedCoverageInfo | OecStatus)[]
): boolean => {
  return hasPlanType(coverageInfo, PlanType.MAPD);
};

export const containsPDPPlan = (
  coverageInfo: (ExtendedCoverageInfo | OecStatus)[]
): boolean => {
  return hasPlanType(coverageInfo, PlanType.PDP);
};

export const hasMedicarePart = (
  currentDate: Date,
  partInfo: MedicarePartInfo
): boolean => {
  if (isValidApiDate(partInfo.start_date)) {
    const partStartDate = new Date(partInfo.start_date);

    if (partInfo.stop_date === "") {
      return true;
    } else if (isValidApiDate(partInfo.stop_date)) {
      const partEndDate = new Date(partInfo.stop_date);
      return partEndDate > partStartDate && !(partEndDate < currentDate);
    }
  }
  return false;
};

type RouteKeys = keyof typeof routes;
type SummaryRouteKeys = keyof typeof routes.summary;
type MedigapRoutes = keyof typeof routes.medigap;
export type PossibleRoutes =
  | (typeof routes)[RouteKeys]
  | (typeof routes.summary)[SummaryRouteKeys]
  | (typeof routes.medigap)[MedigapRoutes];

/**
 * Simple wrapper over React Router's `match` helper, to determine whether the
 * current location matches (a) given route(s)
 * If there are two routes that are functionally equivalent for an LI bene coming
 * from the summary page or from elsewhere in the app, match against either of those
 * routes. E.g., `/summary/search-results` or `/search-results`
 */
export const isNtmAlertPathMatch = ({
  route,
  location,
}: {
  route: PossibleRoutes;
  location: Location;
}) => {
  const routeString = route as string;
  let alternateRoute = null;
  const liRoutePrefix = routes.summary.landingPage;
  // If there are two routes that are functionally equivalent for an LI bene coming from
  // the summary page or from elsewhere in the app, match against either of those
  // routes. E.g., `/summary/search-results` or `/search-results`
  const nestedRoute = !routeString.includes(liRoutePrefix)
    ? `${liRoutePrefix}${routeString}`
    : null;
  const unnestedRoute = routeString.includes(liRoutePrefix)
    ? routeString.replace(liRoutePrefix, "")
    : null;
  if (
    nestedRoute &&
    Object.values(routes.summary).some(route => route === nestedRoute)
  ) {
    alternateRoute = nestedRoute;
  }
  if (
    unnestedRoute &&
    Object.values(routes).some(route => route === unnestedRoute)
  ) {
    alternateRoute = unnestedRoute;
  }
  return [routeString, alternateRoute].some(
    route =>
      route &&
      !!matchPath(location.pathname, {
        path: route,
        exact: true,
        strict: true,
      })
  );
};

export const getIsSepPage = (location: Location) => {
  const uri = new URI(location.search);
  const searchParams = parseSearchParams(uri.search(true));
  return (
    isNtmAlertPathMatch({ route: routes.enroll, location }) &&
    searchParams.page === EnrollmentPages.Sep
  );
};

export const getNeedsMedicareABAlert = ({
  hasPartAandB,
  newToMedicareResult,
  isLoggedIn,
  location,
  planType,
}: {
  hasPartAandB: NewToMedicareAlertInfo["hasPartAandB"] | undefined;
  newToMedicareResult:
    | NewToMedicareAlertInfo["newToMedicareResult"]
    | undefined;
  isLoggedIn: boolean;
  location: Location;
  planType: PlanType | undefined;
}): {
  bodyKey: TranslationKey;
  headingKey: TranslationKey;
  shouldShow: boolean;
  showAsError: boolean;
} => {
  let shouldShow = false;
  let showAsError = false;
  // default to Search Results keys
  let headingKey: TranslationKey =
    "ntm.alert.parts_ab_required.heading.search_results_page";
  let bodyKey: TranslationKey =
    "ntm.alert.parts_ab_required.body.search_results_page";

  switch (true) {
    case isNtmAlertPathMatch({ route: routes.summary.landingPage, location }):
      shouldShow = !hasPartAandB;
      headingKey = "ntm.alert.parts_ab_required.heading.summary_page";
      bodyKey = "ntm.alert.parts_ab_required.body.summary_page";
      break;
    case isNtmAlertPathMatch({ route: routes.searchResults, location }):
      shouldShow = isLoggedIn
        ? !!planType && isMedicareAdvantageType(planType) && !hasPartAandB
        : !!newToMedicareResult;
      break;
    case getIsSepPage(location):
      // Only needed on the Sep step of enrollment, when that step is rendered
      if (isLoggedIn) {
        shouldShow =
          !!planType && isMedicareAdvantageType(planType) && !hasPartAandB;
        showAsError = true;
        headingKey = "ntm.alert.parts_ab_required.heading.enrollment_sep_page";
        bodyKey = "ntm.alert.parts_ab_required.body.enrollment_sep_page";
      } else {
        shouldShow = !!newToMedicareResult;
      }
      break;
    case isNtmAlertPathMatch({ route: routes.medigap.plans, location }):
      shouldShow = isLoggedIn ? !hasPartAandB : !!newToMedicareResult;
      headingKey = "ntm.alert.parts_ab_required.heading.medigap";
      bodyKey = "ntm.alert.parts_ab_required.body.medigap";
      break;
    case isNtmAlertPathMatch({ route: routes.medigap.policies, location }):
      shouldShow = isLoggedIn ? !hasPartAandB : !!newToMedicareResult;
      headingKey = "ntm.alert.parts_ab_required.heading.medigap";
      bodyKey = "ntm.alert.parts_ab_required.body.medigap";
      break;
    default:
      break;
  }
  return { shouldShow, showAsError, headingKey, bodyKey };
};

export const useNeedsMedicareABAlert = () => {
  const {
    state,
    state: { planType },
  } = useContext(AppContext);
  const newToMedicareAlertInfo = useNewToMedicareAlertInfo();
  const location = useLocation();
  const isLoggedIn = hasGlobalSession(state);
  return getNeedsMedicareABAlert({
    hasPartAandB: newToMedicareAlertInfo?.hasPartAandB,
    newToMedicareResult: newToMedicareAlertInfo?.newToMedicareResult,
    isLoggedIn,
    planType,
    location,
  });
};

/**
 * Rendering and content logic for Pard D LEP Alerts, for NtM initiative
 * @param args
 * @param args.isSepEnrollmentAlert - Whether to render special content on enrollment SEP section
 * @param args.isIepEnrollmentAlert - Whether to render special content on enrollment IEP section
 */
export const getAvoidPartDPenaltyAlertContent = ({
  iepStatus,
  isLoggedIn,
  isSepEnrollmentAlert = false,
  isIepEnrollmentAlert = false,
  location,
}: {
  iepStatus: IEPStatus | undefined;
  isLoggedIn: boolean;
  isIepEnrollmentAlert?: boolean;
  isSepEnrollmentAlert?: boolean;
  location: Location;
}): {
  bodyKey: TranslationKey;
  renderHeading: boolean;
} => {
  const isSepPage = getIsSepPage(location);
  let renderHeading = !isSepPage;
  // Alert body depends on LI/anon and iepStatus, default to generic for anon
  let bodyKey: TranslationKey = "ntm.alert.avoid_part_d_penalty.body.generic";
  if (isSepPage) {
    renderHeading = false;
    if (isSepEnrollmentAlert) {
      bodyKey = "ntm.alert.avoid_part_d_penalty.body.enrollment_sep";
    }
    if (isIepEnrollmentAlert) {
      bodyKey = "ntm.alert.avoid_part_d_penalty.body.enrollment_iep";
    }
  } else {
    if (isLoggedIn) {
      switch (iepStatus) {
        case IEPStatus.IEP_STATUS_WITHIN_IEP:
          bodyKey = "ntm.alert.avoid_part_d_penalty.body.within_iep";
          break;
        case IEPStatus.IEP_STATUS_PAST_IEP:
          bodyKey = "ntm.alert.avoid_part_d_penalty.body.past_iep";
          break;
        case IEPStatus.IEP_STATUS_BEFORE_IEP:
          bodyKey = "ntm.alert.avoid_part_d_penalty.body.before_iep";
          break;
      }
    }
  }
  return {
    bodyKey,
    renderHeading,
  };
};

/**
 * Hook providing rendering and content logic for Pard D LEP Alerts, for NtM initiative
 * @param args
 * @param args.isSepEnrollmentAlert - Whether to render special content on enrollment SEP section
 * @param args.isIepEnrollmentAlert - Whether to render special content on enrollment IEP section
 */
export const useAvoidPartDPenaltyAlertContent = ({
  isSepEnrollmentAlert = false,
  isIepEnrollmentAlert = false,
}: {
  isSepEnrollmentAlert?: boolean;
  isIepEnrollmentAlert?: boolean;
} = {}) => {
  const { state } = useContext(AppContext);
  const isLoggedIn = hasGlobalSession(state);
  const location = useLocation();

  const newToMedicareAlertInfo = useNewToMedicareAlertInfo();
  return getAvoidPartDPenaltyAlertContent({
    iepStatus: newToMedicareAlertInfo?.iepStatus,
    isLoggedIn,
    isIepEnrollmentAlert,
    isSepEnrollmentAlert,
    location,
  });
};

// @TODO - New helper and hook added to handle rendering and content logic for
// these alerts. These and the associated tests should be removed if no code is
// referencing. Existing test logic should be re-used (if it's correct) and expanded
// for tests of the new helper, `getAvoidPartDPenaltyAlert`
export const shouldShowAvoidPartDPenaltyAlert = ({
  isLoggedIn,
  location,
  newToMedicareAlertInfo,
  planType,
}: {
  isLoggedIn: boolean;
  location: Location;
  newToMedicareAlertInfo: NewToMedicareAlertInfo | undefined;
  planType: PlanType | undefined;
}): boolean => {
  let shouldShow = false;
  const isSepPage = getIsSepPage(location);
  const {
    hasMedigap,
    hasPartAandB,
    hasPDPPlan,
    hasMAPDPlan,
    newToMedicareResult,
  } = newToMedicareAlertInfo || {
    hasMedigap: false,
    hasPartAandB: false,
    hasPDPPlan: false,
    hasMAPDPlan: false,
    newToMedicareResult: undefined,
  };
  const isSummaryPage = isNtmAlertPathMatch({
    route: routes.summary.landingPage,
    location,
  });
  const isPlanDetailsPage = isNtmAlertPathMatch({
    route: routes.planDetails,
    location,
  });
  const isSearchResults = isNtmAlertPathMatch({
    route: isLoggedIn ? routes.summary.searchResults : routes.searchResults,
    location,
  });

  const planForEnrollmentHasDrugCoverage =
    !!planType && planTypeHasDrugCoverage(planType);

  if (isSepPage) {
    shouldShow =
      planForEnrollmentHasDrugCoverage &&
      (!isLoggedIn || (!hasPDPPlan && !hasMAPDPlan));
  } else {
    if (isLoggedIn) {
      const shouldShowOnSummaryPage =
        !hasPDPPlan &&
        !hasMAPDPlan &&
        ((hasMedigap && hasPartAandB) ||
          (!hasMedigap && hasPartAandB) ||
          (!hasMedigap && !hasPartAandB));
      shouldShow = isSummaryPage
        ? shouldShowOnSummaryPage
        : (isSearchResults || isPlanDetailsPage) && !hasMAPDPlan && !hasPDPPlan;
    } else {
      shouldShow =
        !!newToMedicareResult &&
        (isSearchResults ||
          (isPlanDetailsPage && planForEnrollmentHasDrugCoverage));
    }
  }
  return shouldShow;
};

export const useShowAvoidPartDPenaltyAlert = (planType?: PlanType): boolean => {
  const { state } = useContext(AppContext);

  const newToMedicareAlertInfo = useNewToMedicareAlertInfo();
  const isLoggedIn = hasGlobalSession(state);
  const location = useLocation();
  return shouldShowAvoidPartDPenaltyAlert({
    isLoggedIn,
    location,
    newToMedicareAlertInfo,
    planType,
  });
};

/**
 * The return value of this hook is a proxy for considering a bene to be New to
 * Medicare (NtM), based on the premise that any bene who should see some
 * portion of NtM messaging is in some way NtM.
 *
 * Note that this definition may have limitations, and the name of this hook may
 * need to change in the future if other factors have to be taken into account for
 * a bene to be considered NtM in specific circumstances.
 */
export const useIsNewToMedicare = (): boolean => {
  const { shouldShow: showNeedsMedicareAbNtmAlert } = useNeedsMedicareABAlert();
  const showAvoidPartDPenaltyNtmAlert = useShowAvoidPartDPenaltyAlert();
  const { showMOEPMessaging: showMedigapNtmAlert } = useMOEPAlert();
  const showNewToMedicareCta =
    showNeedsMedicareAbNtmAlert ||
    showAvoidPartDPenaltyNtmAlert ||
    showMedigapNtmAlert;

  return showNewToMedicareCta;
};

// Custom hooks

/**
 * simple hook to check IEP status.
 * This could be extended to include other age related logic.
 */
export function useIEPStatus() {
  const {
    state: { beneficiary },
  } = useContext(AppContext);
  const { medicare_info } = beneficiary || {};
  const { iep_end_date, iep_start_date } = medicare_info || {};

  const iepStatus =
    iep_start_date && iep_end_date
      ? getIEPStatusFromAPI(iep_start_date, iep_end_date)
      : undefined;

  return {
    iepStatus,
  };
}

/** hook to determine if we should show Medigap Open Enrollment Period Alerts (for New To Medicare) */
export function useMOEPAlert() {
  // * Logged In
  const { state } = useContext(AppContext);
  const isLoggedIn = hasGlobalSession(state);

  const newToMedicareAlertInfo = useNewToMedicareAlertInfo();

  // * Bene Coverage
  const { hasAnyCoverage } = useBeneCoverage();

  /** we do not want to show alerts if the user has certain plans already. */
  const hasProperMOEPCoverage =
    // does not have current or future Medigap
    !hasAnyCoverage(
      PlanType.MEDIGAP,
      PlanCoverageStatus.Current,
      PlanCoverageStatus.Future
    ) &&
    // does not have current, future, or pending Medicare Advantage
    !hasAnyCoverage(PlanType.MA) &&
    // does not have current, future, or pending Medicare Advantage + Part D
    !hasAnyCoverage(PlanType.MAPD);

  // * IEP Status

  const { iepStatus } = useIEPStatus();

  const isWithinOrBeforeIEP =
    iepStatus &&
    [IEPStatus.IEP_STATUS_BEFORE_IEP, IEPStatus.IEP_STATUS_WITHIN_IEP].includes(
      iepStatus
    );

  const showForLoggedInBene =
    isLoggedIn && isWithinOrBeforeIEP && hasProperMOEPCoverage;
  const showForAnonBene =
    !isLoggedIn && !!newToMedicareAlertInfo?.newToMedicareResult;

  const showMOEPMessaging = showForLoggedInBene || showForAnonBene;

  return { showMOEPMessaging };
}

/**
 * simple hook to check coverage of a beneficiary.
 * automatically pulls logged in bene info to avoid needing to pass props or use additional hooks.
 * this could be extended to include more logic based on the beneficiary's coverage.
 */
export function useBeneCoverage() {
  const {
    state: { beneficiary },
  } = useContext(AppContext);
  const { coverage_current, coverage_future } = beneficiary || {};
  const { oecStatuses } = usePendingEnrollments();

  const pendingPlans: OecStatus[] = [];
  if (oecStatuses.currentYear) {
    pendingPlans.push(oecStatuses.currentYear);
  }
  if (oecStatuses.nextYear) {
    pendingPlans.push(oecStatuses.nextYear);
  }

  /** returns `true` if logged in bene has current coverage for the plan type. */
  function hasCurrentCoverage(planType: PlanType) {
    return coverage_current && hasPlanType(coverage_current, planType);
  }

  /** returns `true` if logged in bene has future coverage for the plan type. */
  function hasFutureCoverage(planType: PlanType) {
    return coverage_future && hasPlanType(coverage_future, planType);
  }

  /** returns `true` if logged in bene has pending coverage for the plan type. */
  function hasPendingCoverage(planType: PlanType) {
    return pendingPlans && hasPlanType(pendingPlans, planType);
  }

  /** returns `true` if logged in bene has any coverage for the plan type and specified coverage types
   * @example hasAnyCoverage(PlanType.MA) // returns `true` if they have current, future, or pending MA plans.
   * @example hasAnyCoverage(PlanType.MA, PlanCoverageStatus.Future, PlanCoverageStatus.Pending) // returns `true` if they have future or pending MA plans.*/
  function hasAnyCoverage(
    planType: PlanType,
    ...coverageTypes: PlanCoverageStatus[]
  ) {
    const checkAll = coverageTypes.length === 0;
    function shouldCheck(coverageType: PlanCoverageStatus) {
      return checkAll || coverageTypes.includes(coverageType);
    }
    return (
      (shouldCheck(PlanCoverageStatus.Current) &&
        hasCurrentCoverage(planType)) ||
      (shouldCheck(PlanCoverageStatus.Future) && hasFutureCoverage(planType)) ||
      (shouldCheck(PlanCoverageStatus.Pending) && hasPendingCoverage(planType))
    );
  }

  return {
    hasCurrentCoverage,
    hasFutureCoverage,
    hasPendingCoverage,
    hasAnyCoverage,
  };
}
