import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { Action, Beneficiary } from "../../@types";
import { AnalyticsActions } from "../../app/contexts/Analytics/types";
import { IIdleTimer, PresenceType } from "react-idle-timer";
import { useLogout } from "../../helpers";

export enum IdleTimeoutActivityState {
  ACTIVE = "ACTIVE",
  PROMPT = "PROMPT",
  IDLE = "IDLE",
}

export interface UpdateSlsTimeoutValue {
  setTimeUntilSessionExpiresMs: Dispatch<SetStateAction<number>>;
}

export interface EndSessionParams {
  beneficiary: Beneficiary | undefined;
  forceLogoutModalShownRef: MutableRefObject<boolean>;
  getLastActiveTime: IIdleTimer["getLastActiveTime"];
  isCsr: boolean;
  isLoggedIn: boolean;
  logout: ReturnType<typeof useLogout>;
  onBeforeLogout?: () => void;
  /**
   * Passed in if not called directly after calling SLS (e.g., after calling
   * `keepAlive`). If not a CSR session, a call to SLS will check whether if session
   * timeout has changed.
   */
  syncWithSls?: boolean;
}

export interface HandleCsrPresenceChangeParams {
  presence: PresenceType;
}

export interface HandlePresenceChangeParams
  extends HandleCsrPresenceChangeParams {
  activityState: IdleTimeoutActivityState | undefined;
  isCsr: boolean;
  isLoggedIn: boolean;
}

export interface HandleUserActionParams {
  activityState: IdleTimeoutActivityState | undefined;
  dispatch: Dispatch<Action>;
  event: Event | undefined;
  idleTimer: IIdleTimer | undefined;
  isCsr: boolean;
  isLoggedIn: boolean;
}

export interface PresenceChangeHandlerResponse {
  timeUntilSessionExpiresMs: number;
}

export interface ExtendSessionParams {
  beneficiary: Beneficiary | undefined;
  dispatch: Dispatch<AnalyticsActions>;
  isCsr: boolean;
  initialSessionTimeoutMsRef: React.MutableRefObject<number>;
  /** `true` indicates the bene clicked "Continue" in the logout timeout prompt */
  userInitiated?: boolean;
}

export interface UseIdleTimeoutTestHelperParams {
  forceLogoutTimeoutMs: number;
  getElapsedTime: IIdleTimer["getElapsedTime"];
  getLastActiveTime: IIdleTimer["getLastActiveTime"];
  getRemainingTime: IIdleTimer["getRemainingTime"];
  isLoggedIn: boolean;
  logoutTimeoutStartTime: number;
  showForceLogoutModal: boolean;
}

export interface SlsSessionInfo {
  /**
   * Milliseconds until the bene will be shown a prompt that they will be logged
   * out if they remain idle
   */
  idleTimeoutMs: number;
  /**
   * Milliseconds until the bene will be forcibly logged out after being prompted,
   * with no further action
   */
  forceLogoutTimeoutMs: number;
  /** If `true`, the bene's session has already expired on SLS */
  sessionExpired: boolean;
  /**
   * The full time left in the SLS session, taken from the `/sls/session/info`
   * response and subtracting the current time
   */
  timeUntilSessionExpiresMs: number;
}
