import React, {
  ReactNode,
  useState,
  useEffect,
  useContext,
  useRef,
  FC,
  useCallback,
} from "react";
import { Button, CloseIconThin } from "@cmsgov/ds-medicare-gov";
import classNames from "classnames";
import { getRouteName, trackLdEvent, useTranslate } from "../../helpers";
import { AppContext } from "../../app/store";
import { ActionType } from "../../@types";
import {
  AlertMessageConditions,
  AlertMessageContext,
} from "../../app/contexts/AlertMessaging/types";
import {
  alertMessageConditionTopicMap,
  alertMessageTestId,
  alertMessageTitleTestId,
  alertMessageBodyTestId,
  alertMessageActionTestId,
} from "./constants";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
  Ga4Event,
  Ga4EventType,
  LinkType,
} from "../../app/contexts/Analytics/types";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";
import { sendAnalyticsEvent } from "../../app/contexts/Analytics";

export interface AlertMessageProps {
  action?: string | ReactNode;
  body: string | ReactNode;
  className?: string;
  condition?: AlertMessageConditions;
  dataTestId?: string;
  hideCloseButton?: boolean;
  icon?: ReactNode;
  narrowWidth?: boolean;
  onClose?: () => void;
  title?: string;
}

const ldTrackMessageImpression = (
  alertMessageContext: AlertMessageContext & {
    mctAlertTopic: string;
    currentLocation: string;
  }
) => {
  const { ldClient } = alertMessageContext;
  trackLdEvent({
    ldClient,
    data: alertMessageContext,
    eventName: "cmas_coachmark.lcp.messages.impression",
  });
};

const AlertMessage: FC<AlertMessageProps> = ({
  action,
  body,
  className = "",
  condition,
  dataTestId = "",
  hideCloseButton = false,
  icon,
  narrowWidth = false,
  onClose,
  title,
}) => {
  const t = useTranslate();
  const {
    state: { trackedEvents },
    dispatch,
  } = useContext(AppContext);
  const location = useLocation();
  const currentLocation = getRouteName(location.pathname);
  const [active, setActive] = useState(true);
  const ldClient = useLDClient();
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const hasCondition = typeof condition !== "undefined";
  const mctAlertTopic = hasCondition
    ? alertMessageConditionTopicMap[condition]
    : "";
  const cmasAlertCondition =
    hasCondition &&
    ![
      AlertMessageConditions.STALE_ACCOUNT_DRUGS,
      AlertMessageConditions.STALE_ACCOUNT_PHARMACIES,
    ].includes(condition);
  const newDesign = cmasAlertCondition;

  // Refs
  const observerRef = useRef<IntersectionObserver | null>(null);
  const alertMessageElRef = useRef<HTMLDivElement | null>(null);

  const lcpAlertClosedAnalytics = () => {
    if (mctAlertTopic) {
      dispatch({
        type: AnalyticsActionType.SEND_GA4_EVENT,
        settings: {
          event_name: Ga4Event.LOWEST_COST_PHARMA_ALERT_CLOSED,
          event_type: Ga4EventType.UI_INTERACTION,
          mct_alert_topic: mctAlertTopic,
          heading: title,
          alert_type: "warn", // Variation is a guess. Docs suggest there should be an
          // "informational," "lightweight," and "status" option (which aren't
          // existing variations). We aren't using DS Alert, either
          text: bodyRef.current?.textContent || undefined,
        },
      });
      dispatch({
        type: AnalyticsActionType.SEND_TEALIUM_EVENT,
        settings: {
          event_action: "lowest cost pharmacy alert closed",
          event_label: `${mctAlertTopic}${title ? `: ${title}` : ""}`,
        },
      });
    }
  };

  const handleOnClose = () => {
    onClose && onClose();
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
          buttonType: AnalyticsButtonType.BUTTON,
          text: t("close"),
        },
      },
    });
    lcpAlertClosedAnalytics();
    setActive(false);
  };

  const trackEvents = useCallback(
    (condition: AlertMessageConditions) => {
      const trackedLcpAlertImpressions =
        trackedEvents[Ga4Event.LOWEST_COST_PHARMA_ALERT_IMPRESSION] || {};
      const needsUpdate =
        typeof condition !== "undefined" &&
        !trackedLcpAlertImpressions[condition];
      if (!needsUpdate || !mctAlertTopic) {
        return;
      }
      dispatch({
        type: ActionType.EVENT_TRACKED,
        payload: {
          event: Ga4Event.LOWEST_COST_PHARMA_ALERT_IMPRESSION,
          value: { ...trackedLcpAlertImpressions, [condition]: true },
        },
      });
      sendAnalyticsEvent({
        event_name: Ga4Event.LOWEST_COST_PHARMA_ALERT_IMPRESSION,
        alert_topic: mctAlertTopic,
        heading: title,
        alert_type: "warn", // Variation is a guess. Docs suggest there should be an
        // "informational," "lightweight," and "status" option (which aren't
        // existing variations). We aren't using DS Alert, either
        text: bodyRef.current?.textContent || undefined,
        link_type: LinkType.LINK_OTHER,
      });
      dispatch({
        type: AnalyticsActionType.SEND_TEALIUM_EVENT,
        settings: {
          event_action: "lowest cost pharmacy alert impression",
          event_label: `${mctAlertTopic}${title ? `: ${title}` : ""}`,
        },
      });
      if (cmasAlertCondition) {
        ldTrackMessageImpression({
          mctAlertTopic,
          currentLocation,
          ldClient,
        });
      }
    },
    [
      cmasAlertCondition,
      currentLocation,
      dispatch,
      ldClient,
      mctAlertTopic,
      title,
      trackedEvents,
    ]
  );

  useEffect(() => {
    if (
      !bodyRef.current ||
      !alertMessageElRef.current ||
      typeof condition === "undefined"
    ) {
      return;
    }
    const iocb: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          trackEvents(condition);
        }
      });
    };
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    observerRef.current = new IntersectionObserver(iocb, { threshold: 1.0 });
    observerRef.current?.observe(alertMessageElRef.current);
    () => {
      observerRef.current?.disconnect();
    };
  }, [condition, trackEvents]);

  return (
    <div
      className={classNames("mct-c-alert-message", className, {
        "mct-c-alert-message--hidden": !active,
        "mct-c-alert-message--narrow": narrowWidth,
        "mct-c-alert-message--variant": newDesign,
        "mct-c-alert-message--variant-narrow": newDesign && narrowWidth,
        "mct-c-alert-message--variant-cta": newDesign,
        "mct-c-alert-message--variant-cta-narrow": newDesign && narrowWidth,
        "mct-c-alert-message--with-title": !!title,
      })}
      data-testid={dataTestId}
      data-cy={alertMessageTestId}
      ref={alertMessageElRef}
    >
      {icon && <div className="mct-c-alert-message__icon">{icon}</div>}
      {title && (
        <h3
          className="mct-c-alert-message__title"
          data-cy={alertMessageTitleTestId}
        >
          {title}
        </h3>
      )}

      <div
        className={classNames("mct-c-alert-message__body", {
          "mct-c-alert-message__body--no-title": !title,
        })}
        ref={bodyRef}
        data-cy={alertMessageBodyTestId}
      >
        {body}
      </div>
      {!narrowWidth && (
        <div className="mct-c-alert-message__combined-content">
          {title && (
            <h3
              className="mct-c-alert-message__combined-title"
              data-cy={alertMessageTitleTestId}
            >
              {title}
            </h3>
          )}
          <div
            className={classNames("mct-c-alert-message__combined-body", {
              "mct-c-alert-message__combined-body--no-title": !title,
            })}
            data-cy={alertMessageBodyTestId}
          >
            {body}
          </div>
        </div>
      )}
      {action && (
        <div
          className="mct-c-alert-message__action"
          data-cy={alertMessageActionTestId}
        >
          {action}
        </div>
      )}

      {!hideCloseButton && (
        <div className="mct-c-alert-message__close-button">
          <Button
            variation="ghost"
            onClick={handleOnClose}
            aria-label={t("close")}
          >
            <CloseIconThin className="ds-u-font-size--xl ds-u-color--gray-light" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default AlertMessage;
