import React from "react";
import {
  Plan,
  SearchResultPlan,
  StarRatingError,
  StarRating as StarRatingType,
} from "../@types";
import { isMMPlanCategory, useTranslate } from "../helpers";

export const StarRatingErr = ({
  stars,
  plan,
}: {
  stars?: StarRatingType;
  plan?: Plan | SearchResultPlan;
}): JSX.Element => {
  const t = useTranslate();

  if (!(stars && stars.error)) {
    return <></>;
  }

  const { error } = stars;
  let testId = "";
  let errMessage = "";

  if (error === StarRatingError.STAR_ERROR_PLAN_TOO_NEW) {
    testId = "star-rating-plan-too-new";
    errMessage = t("star_rating.plan_too_new");
  } else if (error === StarRatingError.STAR_ERROR_NOT_ENOUGH_DATA) {
    testId = "star-rating-not-enough-data";
    errMessage =
      plan && isMMPlanCategory(plan.category)
        ? t("star_rating.not_available_for_mmp")
        : t("star_rating.not_enough_data");
  } else if (error === StarRatingError.STAR_ERROR_NOT_REQUIRED) {
    testId = "star-rating-not-required";
    errMessage = t("star_rating.not_required");
  } else if (error === StarRatingError.STAR_ERROR_NO_DATA) {
    testId = "star-rating-no-data-available";
    errMessage = t("star_rating.no_data_available");
  } else if (error === StarRatingError.STAR_ERROR_PLAN_TOO_SMALL) {
    testId = "star-rating-plan-too-small";
    errMessage = t("star_rating.plan_too_small");
  } else if (error === StarRatingError.STAR_ERROR_NOT_OFFERED) {
    testId = "star-rating-not-offered";
    errMessage = t("star_rating.not_offered");
  } else if (error === StarRatingError.STAR_ERROR_NOT_REQUIRED_TO_REPORT) {
    testId = "star-rating-not-required-to-report";
    errMessage = t("star_rating.not_required_to_report");
    // temp work around for data issue
  } else if (
    error === StarRatingError.STAR_ERROR_NO_ERROR &&
    stars.rating === 0
  ) {
    testId = "star-rating-plan-too-new";
    errMessage = t("star_rating.plan_too_new");
  }

  return (
    <span
      data-testid={testId}
      className="ds-u-md-font-size--small ds-u-color--gray StarRating__message"
    >
      {errMessage}
    </span>
  );
};
