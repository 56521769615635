import { format } from "date-fns";
import {
  FullPlanId,
  Plan,
  SearchResultPlan,
  IdParts,
  SearchResultsFilters,
  NewRelicPageActionValuesMap,
  NewRelicPageActionInterface,
  IdPartsWithYear,
} from "../@types";
import { LongPlanId } from "./routing-hooks/types";

export function getFullPlanId(plan: Plan | SearchResultPlan): FullPlanId {
  return {
    contract_id: plan.contract_id,
    contract_year: plan.contract_year,
    plan_id: plan.plan_id,
    segment_id: plan.segment_id,
  };
}

export function makePlanDisplayId(plan: IdParts): string {
  const { contract_id, plan_id, segment_id } = plan;
  return `${contract_id}-${plan_id}-${segment_id}`;
}

/**
 * Combines a year part and 3 ID parts
 *
 * NOTE: Due to legacy typing, one of three optional properties defines the year part.
 * - `coverage_year`
 * - `contract_year`
 * - `year` (from `OecStatus`)
 *
 * Be sure one will be defined when calling `makePlanLongId`
 */
export function makePlanLongId(idParts: IdPartsWithYear): LongPlanId {
  const {
    contract_year,
    coverage_year,
    year,
    contract_id,
    plan_id,
    segment_id,
  } = idParts;
  return `${
    contract_year || coverage_year || year
  }-${contract_id}-${plan_id}-${segment_id}`;
}

export function makePlanUrlPartForApi(plan: IdParts): string {
  const { contract_year, contract_id, plan_id, segment_id } = plan;
  return `${contract_year}/${contract_id}/${plan_id}/${segment_id}`;
}

/**
 * Parses plan long id into Url parts for API consumption
 * @param longId the plan id. The plan parts are delimited by "-". Example: 2021-H5652-002-0
 * @returns the plan id as path components delimited by "/". Example: 2021/H5652/002/0
 */
export function parsePlanLongIdIntoUrlPartForApi(longId: string): string {
  const partsMap = {
    0: "contract_year",
    1: "contract_id",
    2: "plan_id",
    3: "segment_id",
  };
  const parts = longId
    .split("-")
    .reduce((idSegments, currentValue, currentIndex) => {
      idSegments[partsMap[currentIndex]] = currentValue;
      return idSegments;
    }, {} as IdParts);
  const apiURL = Object.values(parts).join("/");
  return apiURL;
}

export function addProtocolToUrl(url: string): string {
  if (url.indexOf("http") !== 0) {
    return `http://${url}`;
  }
  return url;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function safeMatches(elem: any, comparison: string): boolean {
  if (elem.matches) {
    return elem.matches(comparison);
  } else if (elem.msMatchesSelector) {
    return elem.msMatchesSelector(comparison);
  } else {
    return false;
  }
}

export function stripParens(str: string): string {
  return str.replace(/[()]/g, "");
}

export function isMobileWidth(): boolean {
  return window.innerWidth < 1024;
}

export function isWindowMobileOuterWidth(window: Window): boolean {
  return window.outerWidth < 1024;
}

export type IsBetweenValidator = (
  value: number,
  low: number,
  high: number
) => boolean;

export const inputIsBetween: IsBetweenValidator = (value, low, high) => {
  const tooLow = value < low;
  const tooHigh = value > high;
  return !tooLow && !tooHigh;
};

export function limitInputLengthCustomSelector(
  selector: string,
  limit: number
): void {
  const field = document.querySelector(selector) as HTMLInputElement;

  if (field) {
    const { value } = field;

    if (value.length > limit) {
      field.value = value.substring(0, limit);
    }
  }
}

export const limitInputValueLength = ({
  inputVal,
  limit,
}: {
  inputVal: string;
  limit: number;
}): string =>
  inputVal.length > limit ? inputVal.substring(0, limit) : inputVal;

export function limitInputLength(
  fieldName: string | null,
  limit: number,
  inputIndex = 0
): void {
  const field = document.querySelectorAll(`.ds-c-field--${fieldName}`)[
    inputIndex
  ] as HTMLInputElement;

  if (field) {
    const { value } = field;

    if (value.length > limit) {
      field.value = value.substring(0, limit);
    }
  }
}

export function limitDateInputs(inputIndex = 0): void {
  const fields: [string, number][] = [
    ["month", 2],
    ["day", 2],
    ["year", 4],
  ];
  fields.forEach(([fieldName, limit]) =>
    limitInputLength(fieldName, limit, inputIndex)
  );
}

export function containsLetters(value: string): boolean {
  const regEx = /\D+/;
  return regEx.test(value);
}

export function filterUndefinedOut<T>(payload: (T | undefined)[]): T[] {
  return payload.reduce((acc, item) => {
    if (item) acc.push(item);
    return acc;
  }, [] as T[]);
}

const iNewRelicPageAction: NewRelicPageActionInterface<NewRelicPageActionValuesMap> =
  {
    addNewRelicPageAction: (name, attributes) => {
      window.newrelic?.addPageAction(name, attributes);
    },
  };

/**
 * Typed alias for global `window.newrelic.addPageAction`
 * @see newrelic.addPageAction
 * @see newrelic.SimpleType
 */
export const addNewRelicPageAction = iNewRelicPageAction.addNewRelicPageAction;

export function isValidEmail(email: string): boolean {
  return (
    email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/) !== null
  );
}

export function doFiltersExist({
  drugCoverageFilter,
  planCoveragesFilter,
  insuranceCarrierFilter,
  planCategoriesFilter,
  starRatingFilter,
}: SearchResultsFilters): boolean {
  if (!planCoveragesFilter) {
    return false;
  }

  return (
    !!Object.keys(planCoveragesFilter).filter(f => planCoveragesFilter[f])
      .length ||
    !!insuranceCarrierFilter ||
    !!starRatingFilter ||
    !!drugCoverageFilter ||
    !!planCategoriesFilter.length
  );
}

/**
 * Given a set of query params, if one is an array, use the first in the array
 * @TODO - To fix typing
 * @see MCT-9170
 * Not any random query param name is defined, so cast the return value to an
 * object where the values could be either `string`s or `undefined`
 */
export function parseSearchParams(params: {
  [x: string]: string | (string | null)[] | null;
}): {
  [x: string]: string | undefined;
} {
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      params[key] = value[0] as string;
    } else {
      params[key] = value as string;
    }
  });
  return params as {
    [x: string]: string;
  };
}

// given a 24 hour time, format to a 12 hour time
export function formatTime(time: string): string {
  const d = new Date(0, 0, 0, 0);
  d.setHours(Number(time.substring(0, 2)));
  d.setMinutes(Number(time.substring(2, 4)));
  return format(d, "p");
}

/** returns a copy of an object with no references to the source*/
export const cloneObject = <T extends object>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};
