import React, { useContext, useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Button,
  TextField,
} from "@cmsgov/ds-medicare-gov";
import UseGenericDialog from "./UseGenericDialog";
import { getAutocompleteDrugList, getAutocompletePapDrugList } from "../api";
import { useTranslate } from "../helpers";
import { AppContext } from "../app/store";
import { AtoZDrugList } from "./";
import { AutocompleteDrug, AutocompletePapDrug, UserLanguage } from "../@types";
import CantFindDrugHelpDrawer from "./HelpDrawers/CantFindDrugHelpDrawer";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";

interface DrugAutocompleteProps {
  className?: string;
  PAP?: boolean;
  onSelectDrug: (addedDrug: AutocompleteDrug | AutocompletePapDrug) => void;
  submitButtonText: string;
}

/**
 * DrugAutocomplete is used in
 *   - PAP web site to look up a drug (AutocompletePapDrug) and
 *   - Medicare Plan Finder to look up a drug (AutocompleteDrug) which may have a generic alternative.
 */
const DrugAutocomplete: React.FunctionComponent<
  DrugAutocompleteProps
> = props => {
  const t = useTranslate();
  const { state, dispatch } = useContext(AppContext);
  const { year, language } = state;
  const [drugList, setDrugList] = useState(
    [] as AutocompleteDrug[] | AutocompletePapDrug[]
  );
  const [selectedDrug, setSelectedDrug] = useState(
    {} as AutocompleteDrug | AutocompletePapDrug
  );
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [addedFromAzList, setAddedFromAzList] = useState(false);
  /** this is the drug search API error */
  const [apiError, setApiError] = useState("");
  /** this is displayed when the user does not select a drug */
  const [validationError, setValidationError] = useState("");
  const [showGenericPrompt, setShowGenericPrompt] = useState(false);
  const colWidths =
    props.PAP || language !== UserLanguage.ENGLISH
      ? { input: 6, button: 4 }
      : { input: 7, button: 3 };

  const onDrugSelect = (drug: AutocompleteDrug): void => {
    setSelectedDrug(drug || {});
  };

  let throttle: NodeJS.Timeout;
  const throttleInMs = 300;
  const minCharactersRequired = 3;

  // onUseGenericDialogSelectDrug is a UseGenericDialog onSelectDrug callback to indicate
  //  whether brand drug or generic drug is selected
  const onUseGenericDialogSelectDrug = (
    genericDlgSelectedDrug: AutocompleteDrug
  ) => {
    setShowGenericPrompt(false);

    setSelectedDrug(genericDlgSelectedDrug);
    props.onSelectDrug(genericDlgSelectedDrug);
  };

  const onDrugSelectionInputChange = (input: string): void => {
    // Only perform autocomplete if at least 3 characters of input for meaningful results
    if (input.length < minCharactersRequired) {
      setDrugList([]);
      setNoResultsMessage("");
      setValidationError("");
      return;
    }

    // Cancel last timeout because new input was added to search field
    if (throttle) {
      clearTimeout(throttle);
    }

    // Only send request to API after throttleInMs milliseconds to prevent spamming the server
    throttle = setTimeout(async () => {
      try {
        setApiError("");
        const list = props.PAP
          ? await getAutocompletePapDrugList(input)
          : await getAutocompleteDrugList(input, year);

        if (list) {
          // The autocomplete component requires each item to have a string ID. We cannot make use of the RXCUI value because there can be duplicates.
          const drugsWithIds = props.PAP
            ? list
            : (list as Array<AutocompleteDrug | AutocompletePapDrug>).map(
                (drug, i) => {
                  return { ...drug, id: i.toString() };
                }
              );

          if (!drugsWithIds.length) {
            setNoResultsMessage(t("add_drugs.no_results"));
          } else {
            setNoResultsMessage("");
          }

          setDrugList(drugsWithIds);

          // work around for strange VO bug where the first item is not selectable
          // with control option space
          if (drugsWithIds.length) {
            const firstItem = document.querySelector(
              ".DrugAutocomplete__autocomplete li:first-child"
            );
            firstItem?.setAttribute("aria-selected", "true");
            firstItem?.setAttribute("aria-selected", "false");
          }
        }
      } catch (_e) {
        setApiError(t("drug_search.error"));
      }
    }, throttleInMs);
  };

  const submit = (): void => {
    if (
      !props.PAP &&
      !(selectedDrug as AutocompleteDrug).is_generic &&
      (selectedDrug as AutocompleteDrug).generic
    ) {
      setShowGenericPrompt(true);
    } else if (
      !(
        (selectedDrug as AutocompleteDrug).rxcui ||
        (selectedDrug as AutocompletePapDrug).id
      )
    ) {
      setValidationError(t("drug_search.drug_required"));
    } else {
      dispatch({
        type: AnalyticsActionType.SEND_TEALIUM_EVENT,
        settings: {
          event_action: props.PAP ? "See Programs" : "Find Plans - Add Drugs",
          event_label: props.PAP ? selectedDrug.name : "Search",
          other_props: props.PAP ? { event_category: "PAP" } : undefined,
        },
      });
      props.onSelectDrug(selectedDrug);
    }
  };

  useEffect(() => {
    if (
      addedFromAzList &&
      ((selectedDrug as AutocompleteDrug).rxcui ||
        (selectedDrug as AutocompletePapDrug).id)
    ) {
      submit();
      setAddedFromAzList(false);
    }
  }, [selectedDrug, addedFromAzList]);

  const onExit = () => setShowGenericPrompt(false);

  return (
    <div className={`DrugAutocomplete ${props.className || ""}`}>
      {showGenericPrompt && (selectedDrug as AutocompleteDrug).generic && (
        <UseGenericDialog
          onExit={onExit}
          brandDrug={selectedDrug as AutocompleteDrug}
          onSelectDrug={onUseGenericDialogSelectDrug}
        />
      )}

      {apiError && (
        <div className="ds-u-margin-bottom--2">
          <Alert variation="error" role="alert">
            {apiError}
          </Alert>
        </div>
      )}

      <div className="ds-l-container ds-u-padding-x--0 ds-u-margin-x--0">
        <div className="ds-l-form-row ds-u-align-items--end">
          <div className={`ds-l-col--12 ds-l-md-col--${colWidths.input}`}>
            <Autocomplete
              items={drugList}
              className="DrugAutocomplete__autocomplete mct-u-max-width--full"
              clearInputText={t("app.terms.clear_search")}
              onInputValueChange={onDrugSelectionInputChange}
              onChange={onDrugSelect}
              data-testid="drug-autocomplete"
              noResultsMessage={noResultsMessage}
            >
              <TextField
                label={t("drug_search.begin_typing")}
                errorMessage={validationError}
                labelClassName="mct-u-min-width--full ds-u-margin-top--0"
                name="Drug search autocomplete"
                fieldClassName="DrugAutocomplete__autocompleteField e2e-drug-selection-autocomplete mct-u-max-width--full ds-u-margin-bottom--0 ds-u-margin-top--1"
              />
            </Autocomplete>
          </div>
          <div
            className={`ds-l-col--12 ds-l-md-col--${colWidths.button} ds-u-margin-top--2 ds-u-md-margin-top--0`}
          >
            <Button
              variation="solid"
              className="DrugAutocomplete__addDrugButton e2e-add-drug-button mct-u-min-width--full"
              data-testid="add-drugs"
              onClick={() => {
                dispatch({
                  type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                  settings: {
                    button: {
                      buttonStyle: AnalyticsButtonStyle.PRIMARY,
                      buttonType: AnalyticsButtonType.SUBMIT,
                      text: props.submitButtonText,
                    },
                  },
                });
                submit();
              }}
            >
              {props.submitButtonText}
            </Button>
          </div>
        </div>
        <div className="ds-l-form-row ds-u-margin-top--3 ds-u-md-margin-top--0">
          <div className="ds-l-col--6 ds-l-md-col--5">
            <AtoZDrugList
              onAddDrug={(drug): void => {
                setSelectedDrug(drug);
                setAddedFromAzList(true);
              }}
              PAP={props.PAP}
              selectDrugButtonLabel={props.submitButtonText}
              toggleClassName="ds-u-text-align--left"
            />
          </div>
          {props.PAP && (
            <div className="ds-l-col--6 ds-l-md-col--5 ds-u-display--flex ds-u-justify-content--end">
              <CantFindDrugHelpDrawer
                PAP={props.PAP}
                toggleClassName="ds-u-text-align--right"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DrugAutocomplete;
