import React, { FC } from "react";
import { PlanType } from "../../@types";
import { isMedicareAdvantageType, isPdPlanType } from "../../helpers";

import { FormattedMessage } from "react-intl";
import { TranslationKey } from "../../helpers/intlHooks";

export type DrugCoverageLabelProps = {
  contract_year: string;
  hasMail: boolean;
  hasRetail: boolean;
  plan_type: PlanType;
  pdpUseRetailMessaging: boolean;
};

/**
 * `pdpUseRetailMessaging` is used when `plan_type` is `PlanType.PDP`,
 * becuase it may be rendered 2x, once for MO and once for retail
 */
export const DrugCoverageLabel: FC<DrugCoverageLabelProps> = ({
  contract_year,
  hasMail,
  hasRetail,
  plan_type,
  pdpUseRetailMessaging,
}) => {
  let id: TranslationKey | null = null;

  if (isMedicareAdvantageType(plan_type)) {
    id =
      hasRetail && hasMail
        ? "what_youll_pay.lowest_cost_retail_pharmacy"
        : "what_youll_pay.lowest_cost_pharmacy";
  }

  if (isPdPlanType(plan_type)) {
    id = pdpUseRetailMessaging
      ? "what_youll_pay.retail_pharmacy"
      : "what_youll_pay.mail_pharmacy";
  }

  return id ? (
    <FormattedMessage id={id} values={{ year: contract_year }} />
  ) : null;
};
