import React, { useContext } from "react";
import swipeSVG from "../assets/swipe.svg";
import { PreviewContext, useTranslate } from "../helpers";
import { FCWithChildren } from "../@types";

interface ScrollTableProps {
  className?: string;
}

const ScrollTable: FCWithChildren<ScrollTableProps> = ({
  className,
  children,
}) => {
  const previewMode = useContext(PreviewContext);
  const t = useTranslate();
  return (
    <div className="ScrollTable">
      <table className={className}>
        {!previewMode && (
          <caption className="ScrollTable__MobileHelp" aria-hidden="true">
            <img
              aria-hidden="true"
              src={swipeSVG}
              role="presentation"
              alt=" "
            />
            <div className="ScrollTable__MobileViewMore">
              <p className="ds-u-margin-y--0">
                {t("scroll_table.swipe_to_view_more.1")}
              </p>
              <p className="ds-u-margin-y--0">
                {t("scroll_table.swipe_to_view_more.2")}
              </p>
            </div>
          </caption>
        )}
        {children}
      </table>
    </div>
  );
};

export default ScrollTable;
