import React, { useMemo } from "react";
import {
  TableInstance,
  TableState,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
} from "react-table";
import { reduceTableCellValueToString } from "../../helpers";
import { RenderedTableStateProps } from "./RenderedTable";
import { GlobalSelectFilterUI } from "./GlobalSelectFilterUI";

/**
 * A global (table-wide, not column-specific) select component for filtering a
 * table on row header values
 * @param - `react-table` Globl filter params
 * @returns - The select filter, based on CMSDS/MDS Dropdown
 */
const GlobalSelectFilter: React.FunctionComponent<
  UseGlobalFiltersInstanceProps<TableInstance> &
    TableInstance &
    UseGlobalFiltersState<TableInstance>
> = ({
  preGlobalFilteredRows,
  setGlobalFilter,
  state: tableState,
  initialState,
}) => {
  // @TODO - testing, possibly remove
  const _tableState = tableState as TableState<TableInstance> & {
    globalFilter?: string;
  };
  // For a global filter to act as though it was a column filter, we're filtering
  // on the 1st column, so this gets the id of the first column
  const id = preGlobalFilteredRows[0]?.cells[0]?.column?.id;
  const tableStateProps = initialState as RenderedTableStateProps;

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set<{ label: string; value: string }>();
    preGlobalFilteredRows.forEach(row => {
      const value = row.cells[0]?.row?.original["value"];
      const label = row.cells[0]?.row?.original["label"];
      if (value && label) {
        options.add({ value, label });
      } else {
        const cellValue = reduceTableCellValueToString(row.values[id]);
        if (cellValue) {
          options.add({ value: cellValue, label: cellValue });
        }
      }
    });
    return Array.from(options.values());
  }, [id, preGlobalFilteredRows]);

  const filterConfig = tableStateProps?.filterConfig || {};

  return (
    <GlobalSelectFilterUI
      options={options}
      globalFilterValue={_tableState.globalFilter}
      onUpdateFilter={filterValue => setGlobalFilter(filterValue)}
      filterConfig={filterConfig}
      id={id}
    />
  );
};

export default GlobalSelectFilter;
