import { Alert, Dialog } from "@cmsgov/ds-medicare-gov";
import React, { useState } from "react";
import {
  normalizeNpi,
  uppercaseFirst,
  useAppContext,
  usePharmacyIsOon,
} from "../../helpers";
import { M3PMonthlyCosts, M3pData } from "../../@types";
import { hasMailOrderPharmacy } from "../../app/store";
import { SelectWithButton } from "..";
import { MedicareGovAnchor } from "../MedicareGovAnchor";
import { FormattedMessage } from "react-intl";
import { TranslationKey, useTypedTranslate } from "../../helpers/intlHooks";
import { addParentComponentProps } from "../../app/contexts/Analytics/helpers";
import { M3PTable } from "./M3PTable";
import { SelectedPharmacy } from "./types";
import classnames from "classnames";
import { useAnalyticsImpression } from "../../app/contexts/Analytics/eventHooks/useAnalyticsImpression";

export interface M3PModalProps {
  onExit: () => void;
  m3pData: M3pData;
}

export interface MP3MonthlyCostsWithMonthNumber extends M3PMonthlyCosts {
  month: number;
}

export const m3pModalHeadingTranslationKey: TranslationKey =
  "m3p.modal.heading";

const m3pModalId = "m3p-modal";
const m3pModalTableContainerClassname =
  "mct-c-modal-m3p__select-table-container";

/**
 * M3P messaging will not render if a bene hasn't selected pharmacies
 * @see `showM3pInfo` within the `useM3pInfo` hook
 * For this reason, we can assert that each pharmacy in the array will have a set
 * or derived name and npi
 */
export const M3PModal = ({ onExit = () => {}, m3pData }: M3PModalProps) => {
  // * Context
  const {
    state: { mailOrderNetworkStatus, pharmacies, pharmacyType },
  } = useAppContext();

  // * Translation
  const t = useTypedTranslate();

  // * Analytics
  const alertHeadingText = t(
    "m3p.modal.alert.all_pharmacies_out_of_network.heading"
  );
  const alertContentText = t(
    "m3p.modal.alert.all_pharmacies_out_of_network.content.v2"
  );
  const alertRef = useAnalyticsImpression({
    event_name: "alert_impression",
    type: "informational",
    heading: alertHeadingText,
    text: alertContentText,
  });

  // * Constants
  const hasMailOrder = hasMailOrderPharmacy({ pharmacyType });
  const mailOrderNpi = normalizeNpi("");
  const mailOrderIsOON =
    !mailOrderNetworkStatus?.inNetwork && !mailOrderNetworkStatus?.preferred;
  const allPharmaciesOON =
    mailOrderIsOON && !pharmacies.some(p => p.in_network || p.preferred);

  const sortedPharmacies = [...pharmacies].sort((p1, p2) => {
    const p1NotOON = p1.in_network || p1.preferred;
    const p2NotOON = p2.in_network || p2.preferred;
    if (p1NotOON && !p2NotOON) {
      return -1;
    }
    if (p2NotOON && !p1NotOON) {
      return 1;
    }
    return 0;
  });

  // Options for select
  const pharmacyOptions: SelectedPharmacy[] = sortedPharmacies.reduce(
    (opts, p) => {
      const npi = normalizeNpi(p.npi);
      return m3pData && m3pData[npi]
        ? [
            ...opts,
            {
              label: p.name,
              value: npi,
            },
          ]
        : opts;
    },
    [] as SelectedPharmacy[]
  );

  if (hasMailOrder && m3pData && m3pData[mailOrderNpi]) {
    const action = mailOrderIsOON ? "push" : "unshift";
    pharmacyOptions[action]({
      label: t("plan_details.pharmacies.mail_order_pharmacy"),
      value: mailOrderNpi,
    });
  }

  // * State
  const [selectedPharmacy, setSelectedPharmacy] = useState<SelectedPharmacy>(
    pharmacyOptions[0] as { value: string; label: string }
  );
  const npi = selectedPharmacy.value;
  const currentPharmacyIsOon = usePharmacyIsOon(npi);

  // Nothing further to do if there are no pharmacies with m3pData
  // @TODO - Any way to type things to be sure this case isn't actually possible?
  // (No early return before all hooks are called)
  if (!m3pData[npi]) {
    return null;
  }

  const modalHeading = t(m3pModalHeadingTranslationKey);

  return (
    <Dialog
      aria-label={modalHeading}
      id={m3pModalId}
      onEnter={() => {
        // Timeout seems necessary for this to work in Safari
        setTimeout(() => {
          // Modal automatically focuses on and scrolls down to close button
          // location (I think). This shifts scrollTop back to 0.
          const modalEl = document.getElementById(m3pModalId);
          if (modalEl) {
            modalEl.scrollTop = 0;
          }
        }, 0);
      }}
      onExit={onExit}
      heading={modalHeading}
      className="ds-c-dialog--full mct-c-modal-m3p"
      headerClassName="mct-c-modal-m3p__header"
    >
      {!allPharmaciesOON && (
        <>
          <p className="ds-u-margin-y--0">{t("m3p.modal.description")}</p>
          <p className="ds-u-margin-y--1">
            <FormattedMessage
              id={"m3p.modal.description.line1"}
              values={{
                b: chunks => <strong>{chunks}</strong>,
              }}
            />
          </p>
          <p className="ds-u-margin-y--1">{t("m3p.modal.description.line2")}</p>
        </>
      )}
      <p className="ds-u-margin-y--1">
        <MedicareGovAnchor
          urlKey="prescriptionPaymentPlan"
          {...addParentComponentProps(
            t(m3pModalHeadingTranslationKey),
            "modal"
          )}
        >
          {t("m3p.modal.medicare_link_text")}
        </MedicareGovAnchor>
      </p>
      {allPharmaciesOON ? (
        <Alert heading={alertHeadingText} className="ds-u-margin-top--4">
          <p ref={alertRef}>{alertContentText}</p>
        </Alert>
      ) : (
        <>
          <div
            className={classnames(m3pModalTableContainerClassname, {
              [`${m3pModalTableContainerClassname}--oon`]:
                currentPharmacyIsOon || allPharmaciesOON,
            })}
          >
            <SelectWithButton
              buttonClassName="mct-c-modal-m3p__change-button"
              label={t("m3p.modal.select.label")}
              name="Pharmacy switch"
              options={pharmacyOptions}
              hint={t("m3p.modal.select.hint")}
              onSelectApplied={value => {
                const label = pharmacyOptions.find(
                  option => option.value === `${value}`
                )?.label as string;
                setSelectedPharmacy({ label, value: `${value}` });
              }}
            >
              {uppercaseFirst(t("change"))}
            </SelectWithButton>
            <M3PTable m3pData={m3pData} selectedPharmacy={selectedPharmacy} />
          </div>
          {!currentPharmacyIsOon && (
            <>
              <FormattedMessage
                id="m3p.modal.content.payment_limits"
                values={{
                  p: chunks => <p>{chunks}</p>,
                  ul: chunks => <ul>{chunks}</ul>,
                  li: chunks => <li>{chunks}</li>,
                }}
              />

              <p>{t("m3p.modal.content.premium_note")}</p>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};
