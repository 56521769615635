import React, { FC, useContext, useEffect } from "react";
import { AlertWithCustomIcon } from "../../AlertWithCustomIcon";
import { CalendarIcon, InfoCircleIcon } from "@cmsgov/ds-medicare-gov";
import { AlertProps } from "@cmsgov/design-system/dist/types/Alert/Alert";
import {
  formatDate,
  useAvoidPartDPenaltyAlertContent,
  useShowAvoidPartDPenaltyAlert,
  useTranslate,
} from "../../../helpers";
import { FormattedMessage } from "react-intl";
import {
  LinkAvoidPartDPenalty,
  LinkExtraHelp,
  TooltipCreditableDrugCoverage,
  TooltipExtraHelp,
} from "..";
import { HelpDrawerWrapper } from "../..";
import { AvoidPartDPenaltyHelpContent } from "./AvoidPartDPenaltyHelpContent";
import { AppContext } from "../../../app/store";
import { TooltipPartDLep } from "../TooltipPartDLep";
import subDays from "date-fns/subDays";
import { AnalyticsActionType } from "../../../app/contexts/Analytics/types";
import { useFormattedMessageRemoveCustomTags } from "../../../helpers/formattedMessageHelpers";
import { PlanType } from "../../../@types";
import { createSimpleHelpDrawerAnalytics } from "../../../helpers/simpleHelpDrawerAnalytics";

type SepEnrollmentAlert = {
  isSepEnrollmentAlert?: boolean;
  isIepEnrollmentAlert?: never;
};

type IepEnrollmentAlert = {
  isSepEnrollmentAlert?: never;
  isIepEnrollmentAlert?: boolean;
};

type AvoidPartDPenaltyProps = Omit<
  AlertProps,
  "children" | "heading" | "variation"
> &
  (SepEnrollmentAlert | IepEnrollmentAlert) & {
    planType?: PlanType;
  };

/** contains internal logic which may prevent rendering */
export const AvoidPartDPenaltyAlert: FC<AvoidPartDPenaltyProps> = ({
  isIepEnrollmentAlert,
  isSepEnrollmentAlert,
  planType,
  ...props
}) => {
  const t = useTranslate();
  const {
    state: { beneficiary },
    dispatch,
  } = useContext(AppContext);
  const { bodyKey, renderHeading } = useAvoidPartDPenaltyAlertContent({
    isIepEnrollmentAlert,
    isSepEnrollmentAlert,
  });
  const renderAlert = useShowAvoidPartDPenaltyAlert(planType);
  let iep_end_date = "";
  if (beneficiary?.medicare_info?.iep_end_date) {
    iep_end_date = formatDate(
      beneficiary?.medicare_info?.iep_end_date,
      "M/d/yyyy"
    );
  }
  const current_day_minus_sixty_three = formatDate(
    subDays(new Date(), 63),
    "M/d/yyyy"
  );

  const alertHeading = renderHeading
    ? t("ntm.alert.avoid_part_d_penalty.heading")
    : undefined;

  const alertVariation = "warn";

  const alertContentText = useFormattedMessageRemoveCustomTags(bodyKey, [
    { tag: "link_part_d_lep" },
    { tag: "tooltip_creditable_drug_coverage" },
    { tag: "tooltip_extra_help" },
    { tag: "tooltip_part_d_lep" },
    { tag: "link_extra_help" },
    { tag: "drawer_trigger_avoid_part_d_penalty" },
    { tag: "iep_end_date", isPII: true },
    { tag: "current_day_minus_sixty_three", isPII: true },
    { tag: "p" },
    { tag: "strong" },
  ]);

  useEffect(() => {
    if (renderAlert) {
      dispatch({
        type: AnalyticsActionType.SEND_ALERT_IMPRESSION_EVENT,
        settings: {
          heading: alertHeading || "",
          text: alertContentText,
          type: alertVariation,
        },
      });
    }
  }, [alertContentText, alertHeading, dispatch, renderAlert]);

  return renderAlert ? (
    <AlertWithCustomIcon
      {...props}
      heading={alertHeading}
      Icon={CalendarIcon}
      variation={alertVariation}
    >
      <FormattedMessage
        id={bodyKey}
        values={{
          link_part_d_lep: chunks => (
            <LinkAvoidPartDPenalty>{chunks}</LinkAvoidPartDPenalty>
          ),
          tooltip_creditable_drug_coverage: chunks => (
            <TooltipCreditableDrugCoverage>
              {chunks}
            </TooltipCreditableDrugCoverage>
          ),
          tooltip_extra_help: chunks => (
            <TooltipExtraHelp>{chunks}</TooltipExtraHelp>
          ),
          tooltip_part_d_lep: chunks => (
            <TooltipPartDLep>{chunks}</TooltipPartDLep>
          ),
          link_extra_help: chunks => <LinkExtraHelp>{chunks}</LinkExtraHelp>,
          drawer_trigger_avoid_part_d_penalty: chunks => (
            <HelpDrawerWrapper
              toggleText={chunks}
              toggleIcon={<InfoCircleIcon />}
              toggleClassName="ds-u-text-align--left ds-u-display--inline"
              drawerTitle={chunks}
              inlineToggle
              mctAnalytics={createSimpleHelpDrawerAnalytics({
                heading: String(chunks),
                toggleText: String(chunks),
              })}
            >
              <AvoidPartDPenaltyHelpContent />
            </HelpDrawerWrapper>
          ),
          iep_end_date,
          current_day_minus_sixty_three,
          p: chunks => (
            // Using `div`s to avoid invalid DOM-nesting issues with tooltips
            <div className="ds-u-margin-bottom--1">{chunks}</div>
          ),
          strong: chunks => <strong>{chunks}</strong>,
        }}
      />
    </AlertWithCustomIcon>
  ) : null;
};
