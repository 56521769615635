import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import { useTranslate } from "../helpers";

interface LoadingMaskProps {
  message?: string;
  loading: boolean;
  delay?: number;
}

const LoadingMask: FunctionComponent<LoadingMaskProps> = ({
  message,
  loading,
  delay,
}) => {
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [showLoading, setShowLoading] = useState(false);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;
  const t = useTranslate();

  useEffect(() => {
    if (loading && delay) {
      if (timerId) {
        clearTimeout(timerId);
        setTimerId(null);
      }

      setTimerId(
        setTimeout(() => {
          if (loadingRef.current) {
            setShowLoading(true);
          }
        }, delay)
      );
    } else if (loading) {
      setShowLoading(true);
    } else {
      if (timerId) {
        clearTimeout(timerId);
        setTimerId(null);
      }

      setShowLoading(false);
    }
  }, [loading]);

  return showLoading ? (
    <div className="LoadingMask animated fadeIn">
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube LoadingMask__cube1"></div>
        <div className="sk-cube2 sk-cube LoadingMask__cube2"></div>
        <div className="sk-cube4 sk-cube LoadingMask__cube3"></div>
        <div className="sk-cube3 sk-cube LoadingMask__cube4"></div>
      </div>
      <h1 className="ds-u-font-size--4xl ds-u-margin-top--1">
        {message || t("app.loading")}
      </h1>
    </div>
  ) : (
    <></>
  );
};

export default LoadingMask;
