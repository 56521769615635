import React, { FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useLoggedIn } from "../helpers";
import routes from "../app/routes";

/** this page will redirect to summary landing page if the user is logged in */
export const AnonRoute: FunctionComponent<RouteProps> = ({
  children,
  ...rest
}) => {
  const isLoggedIn = useLoggedIn();

  if (isLoggedIn) {
    // Authenticated, go to summary landing page
    return <Redirect to={routes.summary.landingPage} />;
  }

  return <Route {...rest}>{children}</Route>;
};
