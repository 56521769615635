import { useEffect, useRef } from "react";

/**
 * @see https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect#57706747
 * @param value - any prop or state value
 * @returns the previous value of the prop or state being tracked
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
