import { useEffect, useRef } from "react";
import { useAppContext } from "./context-hooks";
import {
  AnalyticsActionType,
  AnalyticsKey,
  Ga4Event,
  Ga4EventDimension,
  Ga4EventDimensions,
  Ga4EventType,
} from "../app/contexts/Analytics/types";
import { Ga4EventDimensionsConfig } from "../app/contexts/Analytics/constants";

export interface UseTrackImpressionParams {
  /** The event will be sent as the `event_name` dimension */
  event: Ga4Event;
  /**
   * Any other valid dimensions can be sent, but `event_type` will be hardcoded
   * as `Ga4EventType.NON_INTERACTION`
   */
  eventDimensions: Omit<
    Partial<Ga4EventDimensions>,
    Ga4EventDimension.EVENT_NAME | Ga4EventDimension.EVENT_TYPE
  >;
  /**
   * A unique string shared among instances of the component in a single page
   * render. If included, this should prevent duplicate impression tracking per
   * page view
   */
  id?: string;
  /**
   * Optionally pass a threshold for the internal IntersectionObserver
   * Defaults to 1.0 (the entire element or component must be seen)
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#threshold
   */
  threshold?: number;
}

const tracker: Record<string, unknown> = {};

const setHasBeenTracked = (id: string) => {
  tracker[id] = true;
};

export const getHasBeenTracked = (id: string) => tracker[id];

/**
 * Pass in an event (aka `Ga4Event`) and set of dimensions and get back a `ref`
 * to assign to any element or component that can take a `ref`.
 *
 * An intersection observer will track that the element/component has been
 * scrolled to in the viewport, once per page view.
 *
 * If any required event dimensions are missing, per our `Ga4EventDimensionsConfig`,
 * a notice will be logged to `console`. There is no prohibition for passing other
 * dimensions, and no errors are thrown.
 *
 * @example
 * const componentRef = useTrackImpression({
 *   event: Ga4Event.EVENTNAME,
 *   eventDimensions: [
 *     Ga4EventDimension.DIMENSION_NAME_1,
 *     Ga4EventDimension.DIMENSION_NAME_2
 *   ]
 * });
 *
 * <div ref={componentRef} ...props />
 */
export const useTrackImpression = <T extends HTMLElement>({
  event,
  eventDimensions,
  id,
  threshold = 1.0,
}: UseTrackImpressionParams): React.MutableRefObject<T | null> => {
  const eventConfig = Ga4EventDimensionsConfig[event];
  const elRef = useRef<T | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const impressionTrackedRef = useRef(false);
  const { dispatch } = useAppContext();

  useEffect(() => {
    if (!elRef.current || !eventConfig) {
      return;
    }
    const dimensionKeys = Object.keys(eventDimensions);
    const missingDimensionKeys = eventConfig.reduce(
      (result, dimension: AnalyticsKey): string[] => {
        // event type is hardcoded to `Ga4EventType.NON_INTERACTION` and
        // event name comes from the event parameter, separate from other dimensions
        if (["event_name", "event_type"].includes(dimension)) {
          return result;
        }
        if (!dimensionKeys.includes(dimension)) {
          result.push(dimension);
        }
        return result;
      },
      [] as string[]
    );
    if (missingDimensionKeys.length > 0) {
      console.debug(
        `🚧 Missing analytics dimensions for useTrackImpression event ${event}: ${missingDimensionKeys.join(
          ", "
        )}`
      );
    }
    const iocb: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !impressionTrackedRef.current) {
          const hasBeenTracked = id && getHasBeenTracked(id);
          if (hasBeenTracked) {
            return;
          }
          dispatch({
            type: AnalyticsActionType.SEND_GA4_EVENT,
            settings: {
              event_name: event,
              event_type: Ga4EventType.NON_INTERACTION,
              ...eventDimensions,
            },
          });
          impressionTrackedRef.current = true;
          if (id && !hasBeenTracked) {
            setHasBeenTracked(id);
          }
        }
      });
    };
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    observerRef.current = new IntersectionObserver(iocb, { threshold });
    observerRef.current?.observe(elRef.current);
    () => {
      observerRef.current?.disconnect();
    };
  }, [dispatch, event, eventConfig, eventDimensions, id, threshold]);

  return elRef;
};
