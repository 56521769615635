import React, { cloneElement, ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { PrintButton } from ".";
import { useTranslate } from "../helpers";
import classnames from "classnames";
import { routeReferrerIconMappings } from "./ReferrerDetector";
import { RouteReferrerIconKey } from "../app/contexts/ReferrerContext/types";

interface withPrintProps {
  // Display a print button. Attribute with no value is also `true`
  withPrint: true;
  // Event action for GA print button click event, required with `withPrint`
  printEventAction: string;
  // Optional classes to be added to the print button, if `withPrint` is also added
  printClassName?: string;
}

// If `withPrint` is false or undefined, no need for `printEventAction`
interface withoutPrintProps {
  withPrint?: false;
  printEventAction?: never;
  printClassName?: never;
}

type printProps = withPrintProps | withoutPrintProps;

type ActionsBarProps = printProps & {
  // Optional additional classes to be added to ActionsBar. Useful for adding utility classes.
  className?: string;
  // Optional content to the right of the link or between the link and print button
  extraContent?: ReactNode;
  // Optional classes for the `extraContent`
  extraContentClassName?: string;
  // Optional function to be called when the link is clicked.
  onClick?: () => void;
  // the link location
  // https://reactrouter.com/web/api/Link/to-string
  to: string;
  icon?: ReactElement;
  children?: ReactNode;
};

const baseClassName = "mct-c-actionsbar";

/**
 * ActionsBar displays a link to go back to the previous page, an optional Print
 * button, as well as optional extra content, in a grid row layout
 *
 * @examples
 * <ActionsBar to="/foo">
 *   click here
 * </ActionsBar>
 *
 * <ActionsBar to="/foo" withPrint printEventAction="Print Test Page">
 *   click here
 * </ActionsBar>
 */
export const ActionsBar: React.FunctionComponent<ActionsBarProps> = ({
  children, // `children` are rendered as the back link's text/content
  className = "",
  extraContent,
  extraContentClassName = "",
  onClick,
  printClassName = "",
  printEventAction = "",
  to,
  withPrint = false,
  icon,
  ...props
}) => {
  const t = useTranslate();
  const iconClassName = "ds-u-valign--middle ds-u-margin-right--1";
  const linkIcon = icon || routeReferrerIconMappings[RouteReferrerIconKey.Back];
  const clonedLinkIcon = cloneElement(linkIcon, {
    className: classnames(linkIcon.props.className, iconClassName),
  });
  const LinkFragment = () => (
    <Link
      to={to}
      onClick={onClick}
      className={`${baseClassName}__link ${className}`}
    >
      {clonedLinkIcon}
      {children || t("back")}
    </Link>
  );
  return (
    <div
      className={classnames(baseClassName, {
        className,
        [`${baseClassName}--no-extra-content`]: !extraContent,
        [`${baseClassName}--no-print`]: !withPrint,
      })}
      {...props}
    >
      <div className={`${baseClassName}__back`}>
        <LinkFragment />
      </div>
      {extraContent && (
        <div className={`${baseClassName}__content ${extraContentClassName}`}>
          {extraContent}
        </div>
      )}
      {withPrint && (
        <div className={`${baseClassName}__print`}>
          <PrintButton className={printClassName} />
        </div>
      )}
    </div>
  );
};
