import classNames from "classnames";
import React from "react";
import { useTranslate } from "../../helpers";
import OutOfNetworkIcon from "./OutOfNetworkIcon";

interface OutOfNetworkLabelProps {
  darkBackground?: boolean;
}

interface OutOfNetworkLabelPropsWideProps extends OutOfNetworkLabelProps {
  narrow?: never;
  wide?: boolean;
}

interface OutOfNetworkLabelPropsNarrowProps extends OutOfNetworkLabelProps {
  wide?: never;
  narrow?: boolean;
}

const OutOfNetworkLabel: React.FC<
  OutOfNetworkLabelPropsWideProps | OutOfNetworkLabelPropsNarrowProps
> = ({ narrow, wide, darkBackground }) => {
  const t = useTranslate();
  return (
    <div
      className={classNames("mct-c-out-of-network-pharmacy-label", {
        "mct-c-out-of-network-pharmacy-label--narrow": narrow,
        "mct-c-out-of-network-pharmacy-label--wide": wide,
        "mct-c-out-of-network-pharmacy-label--dark-background": darkBackground,
      })}
    >
      <div
        className={classNames("mct-c-out-of-network-pharmacy-label-body", {
          "mct-c-out-of-network-pharmacy-label-body--narrow": narrow,
          "mct-c-out-of-network-pharmacy-label-body--wide": wide,
          "mct-c-out-of-network-pharmacy-label-body--dark-background":
            darkBackground,
        })}
      >
        <OutOfNetworkIcon
          className={classNames(
            "mct-c-out-of-network-pharmacy-label-body__icon",
            {
              "mct-c-out-of-network-pharmacy-label-body__icon--dark-background":
                darkBackground,
            }
          )}
        />
        <span
          className={classNames(
            "mct-c-out-of-network-pharmacy-label-body__text",
            {
              "mct-c-out-of-network-pharmacy-label-body__text--narrow": narrow,
              "mct-c-out-of-network-pharmacy-label-body__text--wide": wide,
              "mct-c-out-of-network-pharmacy-label-body__text--dark-background":
                darkBackground,
            }
          )}
        >
          {t("pharmacy.terms.out_of_network")}
        </span>
      </div>
    </div>
  );
};

export default OutOfNetworkLabel;
