import React, { useState, SyntheticEvent, FunctionComponent } from "react";
import { Choice } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../../helpers/intlHooks";
import { LowIncomeSubsidyAmount } from "../../@types";
import { NoIconAlert } from "../../components";
import { ExtraSSChoice } from "./types";
import { useCurrentPlanYear, useYearPart } from "../../helpers";

export const extraSSHelpTestId = "extra-ss-help";
interface ExtraSSHelpProps {
  updateAmountOfSSHelp: (value: LowIncomeSubsidyAmount | null) => void;
}

const ExtraSSHelp: FunctionComponent<ExtraSSHelpProps> = ({
  updateAmountOfSSHelp,
}) => {
  const t = useTranslate();
  const [getsPartialHelp, setGetsPartialHelp] = useState(false);
  const mctCurrentPlanYear = useCurrentPlanYear();
  const { isPublicPreview, isOpenEnrollment } = useYearPart();

  /** true from Public Preview and on, until Open Enrollment Next Year Only
   * This relates to MCT-8534 and the effort to remove "Partial Extra Help" in 2024. MCT-8717 removes the downstream questions.
   */
  const showInterimContent =
    mctCurrentPlanYear < 2024 && (isPublicPreview || isOpenEnrollment);

  const handleFirstChoice = (e: SyntheticEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value === "full") {
      setGetsPartialHelp(false);
      updateAmountOfSSHelp(LowIncomeSubsidyAmount.HelpNA);
    } else {
      setGetsPartialHelp(true);
      updateAmountOfSSHelp(null);
    }
  };

  const firstChoices: ExtraSSChoice[] = [
    { label: t("landing_page.lis.ss_full_amount_of_help"), value: "full" },
    {
      label: t("landing_page.lis.ss_partial_amount_of_help"),
      value: "partial",
      // has an alert when feature flag is triggered.
      checkedDescription: showInterimContent
        ? t("landing_page.lis.ss_partial_help_description")
        : undefined,
    },
    {
      label: t("landing_page.lis.ss_partial_help_option_unsure"),
      value: "full",
      checkedDescription: t("landing_page.lis.ss_unsure_help_description"),
    },
  ];

  /** this option should not be available after October 1st, 2023 */
  const secondUnsureChoice: ExtraSSChoice = {
    label: t("landing_page.lis.ss_partial_help_option_unsure"),
    value: LowIncomeSubsidyAmount.HelpUnsure,
    checkedDescription: t("landing_page.lis.ss_unsure_help_description"),
  };

  const baseSecondChoices: ExtraSSChoice[] = [
    {
      label: t("landing_page.lis.ss_partial_help_option_0"),
      value: LowIncomeSubsidyAmount.Help0,
    },
    {
      label: t("landing_page.lis.ss_partial_help_option_25"),
      value: LowIncomeSubsidyAmount.Help25,
    },
    {
      label: t("landing_page.lis.ss_partial_help_option_50"),
      value: LowIncomeSubsidyAmount.Help50,
    },
    {
      label: t("landing_page.lis.ss_partial_help_option_75"),
      value: LowIncomeSubsidyAmount.Help75,
    },
  ];

  /** an array of options (0%, 25%, 50%, 75%, unsure)
   * which no longer includes 'unsure'
   * once we are showing interim content. */
  const secondChoices = showInterimContent
    ? baseSecondChoices
    : [...baseSecondChoices, secondUnsureChoice];

  const handleSecondChoice = (e: SyntheticEvent<HTMLInputElement>): void => {
    updateAmountOfSSHelp(e.currentTarget.value as LowIncomeSubsidyAmount);
  };

  /** Phrase key based upon feature flag. With flag, phrase will be past tense. */
  const howMuchHelpFlagged = showInterimContent
    ? "landing_page.lis.ss_how_much_help_past_year"
    : "landing_page.lis.ss_how_much_help";

  return (
    <>
      <legend
        className="ds-text-heading--xl ds-u-margin--0 ds-u-margin-top--5 ds-u-margin-bottom--1 ds-u-padding--0"
        data-testid={extraSSHelpTestId}
      >
        <h3>{t(howMuchHelpFlagged)}</h3>
      </legend>
      {firstChoices.map(choice => {
        return (
          <Choice
            name="sshelpFirstChoice"
            key={choice.label}
            type="radio"
            value={choice.value}
            onChange={handleFirstChoice}
            checkedChildren={
              choice.checkedDescription
                ? NoIconAlert("", choice.checkedDescription)
                : ""
            }
            label={choice.label}
          />
        );
      })}

      {getsPartialHelp && (
        <>
          <div className="subChoices">
            <legend className="ds-text-heading--xl ds-u-margin--0 ds-u-margin-top--5 ds-u-margin-bottom--1 ds-u-padding--0">
              <div className="ds-c-label mct-l-h3">
                {t("landing_page.lis.ss_what_percentage_of_premium")}
              </div>
            </legend>
            {secondChoices.map(choice => {
              return (
                <Choice
                  name="sshelpSecondChoice"
                  key={choice.value}
                  type="radio"
                  value={choice.value}
                  onChange={handleSecondChoice}
                  label={choice.label}
                  checkedChildren={
                    choice.checkedDescription
                      ? NoIconAlert("", choice.checkedDescription)
                      : ""
                  }
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ExtraSSHelp;
