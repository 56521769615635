import { PrescriptionDrug, PrescriptionFrequency } from "../@types";

const prescriptionDrug: PrescriptionDrug = {
  form: "DRUG_FORM_TABLET",
  frequency: PrescriptionFrequency.DAYS_30,
  insulin: false,
  is_generic: true,
  name: "Ibuprofen - IBUPROFEN",
  ndc: "23456",
  package_quantity: "2",
  package_size: "1",
  package_type: "PACKAGE_TYPE_BLISTER",
  package_units: "60",
  quantity: "60",
  rxcui: "2345",
  strength_units: "mg",
  strength: "200",
  use_package_quantity: false,
  drug_pap_id: "",
};

export default prescriptionDrug;
