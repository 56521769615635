import { RouteComponentProps } from "react-router-dom";
import {
  Action,
  ActionType,
  AppState,
  County,
  CoverageType,
  PharmacyType,
} from "../@types";
import routes from "../app/routes";
import URI from "urijs";
import { parseSearchParams } from "./objectUtilities";
import { getPlanTypeForCoverageType } from "./";

const { drugSearchPreferences, prescriptionsList } = routes;

export interface BuildRouteProps {
  fips?: string;
  planType?: string;
  nextRoutePath: string;
}

export const getSearchPlanTypeForCoverageType = (
  coverageType?: CoverageType
): string | undefined =>
  coverageType
    ? getPlanTypeForCoverageType(coverageType)?.toLowerCase()
    : undefined;

export const getQuestionsToDrugsRoute = (
  state: AppState,
  isFromSummaryPage?: boolean
) => {
  const isMissingPharmsOrDrugs =
    state.pharmacies.length === 0 || state.prescriptions.length === 0;
  if (isFromSummaryPage) {
    return isMissingPharmsOrDrugs
      ? routes.summary.drugSearchPreferences
      : routes.searchResults;
  }
  return state.prescriptions.length ? prescriptionsList : drugSearchPreferences;
};

export const buildNextQuestionsRoute = ({
  nextRoutePath,
  fips,
  planType,
}: BuildRouteProps) =>
  `${nextRoutePath}?fips=${fips}&plan_type=${planType?.toLowerCase()}`;

export const handleContinuingPharmacyUpdate = (state: AppState) => ({
  type: ActionType.UPDATE_PHARMACY_TYPE,
  payload:
    state.pharmacyType === PharmacyType.NONE ||
    state.pharmacyType === PharmacyType.RETAIL
      ? PharmacyType.RETAIL
      : PharmacyType.MAIL_AND_RETAIL,
});

export const handleContinuingPlanTypeUpdate = (coverageType: CoverageType) => ({
  type: ActionType.UPDATE_PLAN_TYPE,
  payload: getPlanTypeForCoverageType(coverageType),
});

interface HandleContinuingProps {
  dispatch: React.Dispatch<Action>;
  state: AppState;
  coverageType?: CoverageType;
  county?: County;
  history: RouteComponentProps["history"];
  location: RouteComponentProps["location"];
  isFromSummaryPage: boolean;
}

export const handleContinuingFromLISQuestions = ({
  dispatch,
  state,
  history,
  location,
  coverageType,
  county,
  isFromSummaryPage,
}: HandleContinuingProps): void => {
  const uri = new URI(location.search);
  let { planType } = parseSearchParams(uri.search(true));
  planType =
    getSearchPlanTypeForCoverageType(coverageType) ||
    (planType ? planType : "");
  const nextRoutePath = getQuestionsToDrugsRoute(state, isFromSummaryPage);
  const fips = county?.fips || "0";

  if (coverageType) {
    dispatch(handleContinuingPlanTypeUpdate(coverageType));
  }

  if (state.prescriptions.length) {
    dispatch(handleContinuingPharmacyUpdate(state));
  }

  history.push(buildNextQuestionsRoute({ nextRoutePath, fips, planType }));
};
