import { uppercaseFirst, useFormatMessage } from "../../helpers";
import { M3PMonthlyCosts } from "../../@types";

/**
 * Return headings as strings for stacked table view (mobile viewport width)
 */
const getPlanStackedHeading = ({
  withPlan = true,
  fm,
}: {
  withPlan?: boolean;
  fm: ReturnType<typeof useFormatMessage>;
}): string =>
  fm(
    { id: `m3p.modal.table.heading.plan.${withPlan ? "with" : "without"}` },
    {
      normal: chunks => chunks,
    }
  ) as string;

/**
 * Returns headings -- for both with and without M3P -- as strings, for stacked
 * table view (mobile viewport width)
 */
export const getPlanStackedHeadings = (
  fm: ReturnType<typeof useFormatMessage>
): Record<string, string> => {
  return {
    withPlan: getPlanStackedHeading({ fm }),
    withoutPlan: getPlanStackedHeading({ withPlan: false, fm }),
  };
};

/**
 * Returns a properly formatted, translated month name using a `locale` tag
 * (`Intl.UnicodeBCP47LocaleIdentifier`) and a M3P date string, returned for each
 * month from MCTAPI.
 *
 * E.g. 2025-01-01 => January (en-US) or Enero (es-MX)
 */
export const getTranslatedMonthNameFromM3pDate = ({
  date,
  locale,
}: {
  date: M3PMonthlyCosts["date"];
  /**
   * This type hint does not enforce anything (it's just `string`). however, valid
   * values are things like `en-US` and `es-MX` ({lang}-{region(UC)})
   */
  locale: Intl.UnicodeBCP47LocaleIdentifier;
}) => {
  const formatter = new Intl.DateTimeFormat(locale, {
    month: "long",
  });
  const yearMonthDay = date
    .split("-")
    .slice(0, 2) // remove the day value, which is the first of the month
    .join("-")
    // force the day to the 2nd of the month to prevent all months
    // being interpreted as the last day of the previous month, so they're rendered
    // rendered correctly in order, Jan - Dec
    // E.g. 2025-01-01 becomes 2025-01-02 => January, not December 31 at midnight
    .concat("-02");
  const dateObj = new Date(yearMonthDay);
  const monthName = uppercaseFirst(formatter.format(dateObj));
  return monthName;
};
