import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { LoadingMask } from ".";
import { ActionType, BeneficiaryAndApiHeaders } from "../@types";
import { getLoggedInBeneInfo } from "../api";
import routes from "../app/routes";
import { AppContext } from "../app/store";
import {
  beneKeysMatch,
  csrGuestAccessErrorCode,
  getIsSlsxAuthenticated,
  hasLocalSession,
  useBeneLoginAndUpdateHandler,
  useIsCsrSession,
  useLoggedIn,
  useLogout,
  useTranslate,
} from "../helpers";
import { useHandleMbpLandingRoute } from "../helpers/routeHelpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { erase } from "browser-cookies";
import { useSetLangFromRelayParam } from "../helpers/languageHelpers";

export const GlobalSessionHandler = ({ children }: { children: ReactNode }) => {
  // * Hooks
  const t = useTranslate();
  const handleMbpLandingRoute = useHandleMbpLandingRoute();
  const isLoggedIn = useLoggedIn();
  const logout = useLogout();
  useSetLangFromRelayParam();

  // * Context
  const {
    state: { beneficiary: stateBene, mbpHandoffFailed, csrGuestAccess },
    dispatch,
  } = useContext(AppContext);
  const history = useHistory();

  // * Location
  const location = useLocation();
  const isLoginCallbackRoute = location.pathname === routes.slsCallback;
  const isMbpLandingRoute = location.pathname === routes.mbpLandingPage;
  const isLogoutRoute = location.pathname === routes.logout;
  const isCsrGuestAccessRoute = location.pathname === routes.csrGuestAccess;
  const isCsr = useIsCsrSession();

  const routeShouldPreventCsrGuestAccess = !(
    [routes.csrGuestAccess, routes.logout] as string[]
  ).includes(location.pathname);

  /**
   * Short-circuit and redirect CSRs trying to use guest access (until it's allowed)
   * It should be set in `AppState` after the first time landing on the `CSRGuestAccessPage`
   */
  if (csrGuestAccess && routeShouldPreventCsrGuestAccess) {
    history.replace(routes.csrGuestAccess);
  }

  // * Flags
  const flags = useFlags();
  const { feEnableAuthentication } = flags as {
    feEnableAuthentication: boolean;
  };

  // * State
  const [loading, setLoading] = useState(
    // Start with loading state set to true for callback routes
    // Callback routes always use the `redirect_uri` to send the user somewhere else
    isLoginCallbackRoute || isMbpLandingRoute || csrGuestAccess
  );
  const [shouldLogout, setShouldLogout] = useState(false);
  /**
   * local copy of endpoint result
   * ? could potentially be removed by changing how we deal with queries
   * ? for example using `useQuery` library. */
  const [beneInfoWithHeaders, setBeneInfoWithHeaders] =
    useState<BeneficiaryAndApiHeaders>();
  const { beneLoginAndUpdateHandler, runOnce } =
    useBeneLoginAndUpdateHandler(beneInfoWithHeaders);

  // * Constants
  const hasStateBene = !!stateBene;
  const fetchedBene = beneInfoWithHeaders?.beneficiary;

  // * Memos
  const shouldUpdateStateWithFetchedBene = useMemo(
    () =>
      !isLoginCallbackRoute &&
      !!stateBene &&
      !!fetchedBene &&
      beneKeysMatch(stateBene, fetchedBene),
    [fetchedBene, isLoginCallbackRoute, stateBene]
  );

  /**
   * `skipHandling` basically bypasses the `GlobalSessionHandler`'s functions in
   * various circumstances, just letting children render without trying to check
   * for a valid SLS session and either fetching/updating bene data or clearing
   * leftover session state and passing through unauthenticated.
   *
   * If `skipHandling` is `true`, the `loading` boolean will be set to `false`, and if
   * the user isn't otherwise redirected by some other handler, this component
   * will just render its children.
   */
  const skipHandling = useMemo(
    () =>
      !feEnableAuthentication ||
      isCsr ||
      isLogoutRoute ||
      isCsrGuestAccessRoute,
    [feEnableAuthentication, isCsr, isCsrGuestAccessRoute, isLogoutRoute]
  );

  // * Refs
  const beneLoginAndUpdateHandlerRunRef = useRef(false);
  const apiResponseStatusRef = useRef<number | undefined>(undefined);
  const isLoginCallbackRouteRef = useRef(isLoginCallbackRoute);

  // * Effects
  useEffect(() => {
    if (skipHandling) {
      setLoading(false);
    }
  }, [skipHandling]);

  useEffect(() => {
    console.debug(`🐝 Inside GlobalSessionHandler, logged in? ${isLoggedIn}`);
  }, [isLoggedIn]);

  /**
   * Track login callback route with a ref so that in new tabs, when the app
   * navigates from the base route `/` to login callback `/#/sls-callback`, the
   * core effect (see #1), doesn't run 2x (because the route change isn't part
   * of the effect's dependencies)
   */
  useEffect(() => {
    isLoginCallbackRouteRef.current = isLoginCallbackRoute;
  }, [isLoginCallbackRoute]);

  /**
   * #1 - The main handler
   * If we are authenticated and have not _already_ fetched beneInfo,
   * we verify session and call `getLoggedInBeneInfo()`, otherwise handle logout
   * or continuing unauthenticated
   */
  useEffect(() => {
    let isMounted = true;
    function dismount() {
      isMounted = false;
    }
    const checkAndHandleGlobalSession = async () => {
      try {
        // Check whether the user is authenticated. If not, throwing here will
        // let the user continue and actively logout if state needs clearing
        if (
          !isLoginCallbackRouteRef.current &&
          !(await getIsSlsxAuthenticated())
        ) {
          throw new Error("No global session or error getting global session");
        }
        // @TODO - All non-CSR routes now call `getLoggedInBeneInfo` (`/beneinfo/userinfo`)
        // which needs no values from a `req_token` query param.
        // Try to unify the handling of all auth routes, as much as possible
        //
        // Get latest beneInfo
        const result = await getLoggedInBeneInfo();
        setBeneInfoWithHeaders(result);
      } catch (e) {
        console.debug(`🦄 Could not fetch a bene in GlobalSessionHandler`);
        if ((e as { info: Record<string, unknown> })?.info) {
          const { info } = e as { info: Record<string, unknown> };
          if (
            typeof info?.apiErrorCode === "number" &&
            typeof info?.apiResponseStatus === "number"
          ) {
            apiResponseStatusRef.current = info.apiResponseStatus;
            // Handle CSR Guest Access denial (until it's allowed) on first load
            // Afterward, an effect checking `AppState.csrGuestAccess` will short-
            // circuit all the rest of the handler
            if (
              info.apiResponseStatus === 403 &&
              info.apiErrorCode === csrGuestAccessErrorCode &&
              routeShouldPreventCsrGuestAccess
            ) {
              // After redirecting, `AppState.csrGuestAccess` will be set to `true`
              history.replace(routes.csrGuestAccess);
            }
          }
        }
        // If app state (store) still holds bene info, we need to actively log
        // out, locally, clear everything out, and return to non-LI landing
        if (hasStateBene) {
          return setShouldLogout(true);
        }
        if (hasLocalSession()) {
          // expire `sid` cookie, if it's still hanging around
          erase("sid");
        }
        // This likely means there is no session, and the page should proceed to
        // load unauthenticated
        // @TODO - May need to add conditional toast/routing here, even if there
        // is no bene in app state
        console.debug(`🐠 Continuing unauthenticated`);
        return setLoading(false);
      }
    };
    if (
      isMounted &&
      !skipHandling &&
      !beneInfoWithHeaders &&
      !isMbpLandingRoute
    ) {
      checkAndHandleGlobalSession();
    }
    return dismount;
  }, [
    beneInfoWithHeaders,
    dispatch,
    hasStateBene,
    history,
    isMbpLandingRoute,
    routeShouldPreventCsrGuestAccess,
    skipHandling,
  ]);

  /**
   * #2
   * When `beneInfoWithHeaders` is set, run the helper that sets beneinfo
   * and fetches related data to set in the store
   */
  useEffect(() => {
    if (
      skipHandling ||
      isMbpLandingRoute ||
      !beneInfoWithHeaders ||
      shouldUpdateStateWithFetchedBene
    ) {
      return;
    }
    let isMounted = true;
    const dismount = () => {
      isMounted = false;
    };
    (async () => {
      if (!isMounted || beneLoginAndUpdateHandlerRunRef.current) {
        return;
      }
      // At this point, the user has a session, but we may need to fully update
      // the store for a LI bene experience. This logic applies to the
      // `loginCallbackRoute` and potentially other routes, if coming to MCT for
      // the first time from an authenticated MBP session
      // @TODO - see below
      // No line after `await beneLoginAndUpdateHandler` is usually (ever?) hit
      // Should probably think about a separate navigation handler and return
      // a navigation directive from the `beneLoginAndUpdateHandler`
      if (runOnce) {
        beneLoginAndUpdateHandlerRunRef.current = true;
      }
      await beneLoginAndUpdateHandler();
      // @TODO - figure out how to deal with the fact that `beneLoginAndUpdateHandler`
      // just redirects
      // There's no reason to `setLoading(false)` here (at least most of the time)
      // because the user's just being redirected by the handler
      setLoading(false);
    })();
    return dismount;
  }, [
    // TODO: MCT-9100 - Update GlobalSessionHandler dependency arrays (https://jira.cms.gov/browse/MCT-9100)
    beneInfoWithHeaders,
    beneLoginAndUpdateHandler,
    isMbpLandingRoute,
    runOnce,
    shouldUpdateStateWithFetchedBene,
    skipHandling,
  ]);

  /**
   * #3
   * For non-login page loads, if there's bene info already in app state,
   * and the cached bene and fetched bene are the same, just update bene info and return
   */
  useEffect(() => {
    if (
      skipHandling ||
      isMbpLandingRoute ||
      !fetchedBene ||
      !shouldUpdateStateWithFetchedBene
    ) {
      return;
    }
    console.debug(`😺 updating the state with fetched bene info`);

    dispatch({
      type: ActionType.ADD_BENEFICIARY,
      payload: fetchedBene,
    });

    console.debug(
      `🌸 LI bene page reload, returning from GlobalSessionHandler, continuing authenticated`
    );
  }, [
    dispatch,
    fetchedBene,
    isMbpLandingRoute,
    shouldUpdateStateWithFetchedBene,
    skipHandling,
  ]);

  /**
   * #4
   * If along the way the `GlobalSessionHandler` finds a bene needs to be logged
   * out, this effect runs
   */
  useEffect(() => {
    if (!shouldLogout) {
      return;
    }
    console.debug(`🦞 Actively logging out`);
    logout({
      apiHasExpired: apiResponseStatusRef.current === 401,
      sessionHasExpired: true,
    });
  }, [logout, shouldLogout]);

  /**
   * Handler for MBP Landing (link from Max Global Header to saved pharms or drugs)
   * Currently this is different from the core handler
   * @TODO - eventually try to consolidate
   */
  useEffect(() => {
    if (!isMbpLandingRoute) {
      return;
    }
    let isMounted = true;
    function dismount() {
      isMounted = false;
    }
    (async () => {
      if (!isMounted) {
        return;
      }
      // @TODO - MBP Landing page should be handled similarly to login callback route,
      // and should share logic, as much as possible
      console.debug(
        `🐪 MBP landing route. mbpHandoffFailed? ${mbpHandoffFailed}`
      );
      dispatch({
        type: ActionType.RESET_STATE,
      });
      const renderChildren = await handleMbpLandingRoute();
      if (renderChildren) {
        return setLoading(false);
      }
    })();
    return dismount;
  }, [dispatch, handleMbpLandingRoute, isMbpLandingRoute, mbpHandoffFailed]);

  return loading ? (
    <LoadingMask
      loading={true}
      message={t("loading.retrieving_your_information")}
    />
  ) : (
    <>{children}</>
  );
};
