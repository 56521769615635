import React, { PropsWithChildren } from "react";
import { planTypePhraseMap } from "../constants";
import { FormattedMessage } from "react-intl";
import { sendTooltipEvent } from "../../../helpers";
import { TooltipWithClose } from "../../TooltipWithClose";
import {
  HelpDrawerToggle,
  InfoCircleOutlineIcon,
} from "@cmsgov/ds-medicare-gov";
import { CoverageSelectorCoverageType } from "../../../@types";
import { sendHelpDrawerOpenAnalyticsEvent } from "../../../app/contexts/Analytics/events";
import { TranslationKey, useTypedTranslate } from "../../../helpers/intlHooks";

type PlanTypeDescriptionProps = PropsWithChildren<{
  coverageType: CoverageSelectorCoverageType;
  newToMedicare?: boolean;
  showDrawer?: () => void;
  helpDrawerOpen?: boolean;
  setActiveTooltip: (coverageType: CoverageSelectorCoverageType) => void;
}>;

/** These phrase keys are in use here. Logged here for easy searching:
 * coverage_selector.help_drawer.medigap.body
 * coverage_selector.help_drawer.part_c.body
 * coverage_selector.help_drawer.part_d.body
 * coverage_selector.new_to_medicare.medigap.body
 * coverage_selector.new_to_medicare.medigap.warning
 * coverage_selector.new_to_medicare.part_c.body
 * coverage_selector.new_to_medicare.part_c.warning
 * coverage_selector.new_to_medicare.part_d.body
 * coverage_selector.new_to_medicare.part_d.warning
 */

/** description of plans to use with PlanTypeStep */
export const PlanTypeDescription: React.FunctionComponent<
  PlanTypeDescriptionProps
> = ({
  coverageType,
  newToMedicare,
  showDrawer,
  helpDrawerOpen,
  setActiveTooltip,
}) => {
  const t = useTypedTranslate();
  const phraseSelector2 = newToMedicare ? "new_to_medicare" : "help_drawer";
  const phraseSelector3: "part_c" | "part_d" | "medigap" =
    planTypePhraseMap[coverageType as keyof typeof planTypePhraseMap];
  const initialPhraseDescriptionKey =
    `coverage_selector.${phraseSelector2}.${phraseSelector3}.body` as TranslationKey;
  const phraseWarningKey = `coverage_selector.new_to_medicare.${phraseSelector3}.warning`;

  const phraseDescriptionKey = (() => {
    switch (initialPhraseDescriptionKey) {
      // These strings have been updated for 2024
      case "coverage_selector.help_drawer.medigap.body":
      case "coverage_selector.help_drawer.part_c.body":
      case "coverage_selector.new_to_medicare.part_c.body":
      case "coverage_selector.new_to_medicare.part_d.body":
      case "coverage_selector.new_to_medicare.medigap.body":
        return `${initialPhraseDescriptionKey}.2024` as TranslationKey;
      default:
        return initialPhraseDescriptionKey;
    }
  })();

  const toggleTextKey = "coverage_selector.new_to_medicare.medigap.when";

  function showHelpDrawer() {
    sendHelpDrawerOpenAnalyticsEvent({
      text: t(toggleTextKey),
      heading: t(toggleTextKey),
    });
    if (showDrawer) showDrawer();
  }

  /** sets parent z-index to 1 so as to have proper tooltips, without overlap. */
  function activateTooltip() {
    setActiveTooltip(coverageType);
    sendTooltipEvent({
      text: t("cw.info.om"),
    });
  }

  return (
    <>
      <div className="ds-u-margin-top--0 mct-c-coverage-selector-v2__plantype_description">
        <FormattedMessage
          id={phraseDescriptionKey}
          values={{
            originalMedicare: (
              <TooltipWithClose
                title={t("ntm.original_medicare.tooltip")}
                onOpen={activateTooltip}
                className="ds-c-tooltip__trigger-link"
                component="a"
              >
                {t("cw.info.om")}
              </TooltipWithClose>
            ),
            when: (
              <HelpDrawerToggle
                showDrawer={showHelpDrawer}
                helpDrawerOpen={!!helpDrawerOpen}
              >
                <span className="ds-u-margin-right--1">{t(toggleTextKey)}</span>
                <InfoCircleOutlineIcon />
              </HelpDrawerToggle>
            ),
          }}
        />
      </div>

      {newToMedicare && (
        <div className="ds-u-font-weight--bold ds-u-margin-top--1 ds-u-margin-bottom--2">
          <FormattedMessage
            id={phraseWarningKey}
            values={{
              and: (
                // This is <strong> because we have a stray CSS rule that takes away font-weight for spans.
                // The --bold class will keep the font-weight consistent.
                <strong className="ds-u-text-decoration--underline ds-u-font-weight--bold">
                  {t("and")}
                </strong>
              ),
              or: (
                <strong className="ds-u-text-decoration--underline ds-u-font-weight--bold">
                  {t("or")}
                </strong>
              ),
            }}
          />
        </div>
      )}
    </>
  );
};
