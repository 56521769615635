import { DrugDosage, DrugPackageUnit } from "../@types";

const dosages: DrugDosage[] = [
  {
    rxcui: "197316",
    form: "DRUG_FORM_TABLET",
    strength: "2",
    strength_units: "MG",
    has_duplicate_name: false,
    name: "Test",
    packages: [
      {
        ndc: "00378025501",
        type: "PACKAGE_TYPE_AMPULE",
        size: 0,
        unit: DrugPackageUnit["N/A"],
        quantity: 0,
        use_quantity: false,
        default_quantity_30: 30,
        default_quantity_90: 90,
        volume: 120,
      },
    ],
  },
  {
    rxcui: "359145",
    form: "DRUG_FORM_TABLET_EXTENDED_RELEASE_12_HOUR",
    strength: "8",
    strength_units: "MG",
    has_duplicate_name: false,
    name: "Test",
    packages: [
      {
        ndc: "00378412401",
        type: "PACKAGE_TYPE_AMPULE",
        size: 0,
        unit: DrugPackageUnit[DrugPackageUnit["N/A"]],
        quantity: 0,
        use_quantity: false,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
    ],
  },
  {
    rxcui: "755497",
    form: "DRUG_FORM_SYRUP",
    strength: "2",
    strength_units: "MG/5ML",
    has_duplicate_name: false,
    name: "Test",
    packages: [
      {
        ndc: "00093066116",
        type: "PACKAGE_TYPE_BOTTLE",
        size: 473,
        unit: DrugPackageUnit.ML,
        quantity: 1,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "63629182802",
        type: "PACKAGE_TYPE_BOTTLE",
        size: 240,
        unit: DrugPackageUnit.ML,
        quantity: 1,
        use_quantity: true,
        default_quantity_30: 15,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "52959015306",
        type: "PACKAGE_TYPE_BOTTLE",
        size: 180,
        unit: DrugPackageUnit.ML,
        quantity: 1,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "52959015309",
        type: "PACKAGE_TYPE_BOTTLE",
        size: 480,
        unit: DrugPackageUnit.ML,
        quantity: 1,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "52959015303",
        type: "PACKAGE_TYPE_BOTTLE",
        size: 120,
        unit: DrugPackageUnit.ML,
        quantity: 1,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
    ],
  },
  {
    rxcui: "359144",
    form: "DRUG_FORM_TABLET_EXTENDED_RELEASE_12_HOUR",
    strength: "4",
    strength_units: "MG",
    has_duplicate_name: false,
    name: "Test",
    packages: [
      {
        ndc: "00378412201",
        type: "PACKAGE_TYPE_AMPULE",
        size: 0,
        unit: DrugPackageUnit["N/A"],
        quantity: 0,
        use_quantity: false,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
    ],
  },
  {
    rxcui: "351137",
    form: "DRUG_FORM_NEBULIZATION_SOLUTION",
    strength: "0.63",
    strength_units: "MG/3ML",
    has_duplicate_name: false,
    name: "Test",
    packages: [
      {
        ndc: "00378699152",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 25,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "00487030101",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 30,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
    ],
  },
  {
    rxcui: "245314",
    form: "DRUG_FORM_NEBULIZATION_SOLUTION",
    strength: "(5 MG/ML)",
    strength_units: "0.5%",
    has_duplicate_name: false,
    name: "Test",
    packages: [
      {
        ndc: "00487990130",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 1,
        unit: DrugPackageUnit.EA,
        quantity: 30,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "24208034720",
        type: "PACKAGE_TYPE_BOTTLE",
        size: 20,
        unit: DrugPackageUnit.ML,
        quantity: 1,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
    ],
  },
  {
    rxcui: "351136",
    form: "DRUG_FORM_NEBULIZATION_SOLUTION",
    strength: "1.25",
    strength_units: "MG/3ML",
    has_duplicate_name: false,
    name: "Test",
    packages: [
      {
        ndc: "00378699298",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 5,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "00487990401",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 30,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "00378699252",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 25,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
    ],
  },
  {
    rxcui: "197318",
    form: "DRUG_FORM_TABLET",
    strength: "4",
    strength_units: "MG",
    has_duplicate_name: false,
    name: "Test",
    packages: [
      {
        ndc: "00378057201",
        type: "PACKAGE_TYPE_AMPULE",
        size: 0,
        unit: DrugPackageUnit["N/A"],
        quantity: 0,
        use_quantity: false,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
    ],
  },
  {
    rxcui: "630208",
    form: "DRUG_FORM_NEBULIZATION_SOLUTION",
    strength: "(2.5 MG/3ML)",
    strength_units: "0.083%",
    has_duplicate_name: false,
    name: "Test",
    packages: [
      {
        ndc: "60687039579",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 1,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "00378827052",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 25,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "00378827055",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 30,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "00378827091",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 60,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "50090051601",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 125,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
      {
        ndc: "55154435905",
        type: "PACKAGE_TYPE_PLASTIC_CONTAINER",
        size: 3,
        unit: DrugPackageUnit.ML,
        quantity: 5,
        use_quantity: true,
        default_quantity_30: 0,
        default_quantity_90: 0,
        volume: 0,
      },
    ],
  },
];

export default dosages;
