import React, { FC, useContext } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { AppContext } from "../../app/store";
import { alertMessageConditionTopicMap } from "./constants";
import {
  AlertMessageConditions,
  AlertMessageContext,
} from "../../app/contexts/AlertMessaging/types";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../../app/contexts/Analytics/types";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { getRouteName, trackLdEvent } from "../../helpers";

export interface AlertMessageLinkProps extends LinkProps {
  condition: AlertMessageConditions;
  treatAsButton?: boolean;
}

export const ldTrackMessageEngagement = (
  alertMessageContext: AlertMessageContext & {
    mctAlertTopic: string;
    currentLocation: string;
  }
) => {
  const { ldClient } = alertMessageContext;
  trackLdEvent({
    ldClient,
    data: alertMessageContext,
    eventName: "cmas_coachmark.lcp.messages.engagement",
  });
};

export const AlertMessageLink: FC<AlertMessageLinkProps> = ({
  onClick,
  condition,
  to,
  treatAsButton = false,
  children,
  ...props
}) => {
  const { dispatch } = useContext(AppContext);
  const location = useLocation();
  const currentLocation = getRouteName(location.pathname);
  const ldClient = useLDClient();
  const mct_alert_topic = alertMessageConditionTopicMap[condition];
  const cmasAlertCondition =
    condition &&
    ![
      AlertMessageConditions.STALE_ACCOUNT_DRUGS,
      AlertMessageConditions.STALE_ACCOUNT_PHARMACIES,
    ].includes(condition);
  const onClickHandler: React.MouseEventHandler<HTMLAnchorElement> = event => {
    // Analytics
    let pathname;
    if (typeof to === "object") {
      pathname = to.pathname;
    }
    const defaultTranslation = event.currentTarget.textContent;
    if (treatAsButton) {
      dispatch({
        type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
        settings: {
          button: {
            text: defaultTranslation || "",
            buttonStyle: AnalyticsButtonStyle.PRIMARY,
            buttonType: AnalyticsButtonType.BUTTON,
          },
          linkUrl: pathname || "",
          custom: { mct_alert_topic },
        },
      });
    } else {
      dispatch({
        type: AnalyticsActionType.SEND_INTERNAL_LINK_CLICKED_EVENT,
        settings: {
          linkText: defaultTranslation || "",
          linkUrl: pathname || "",
          custom: { mct_alert_topic },
        },
      });
    }
    if (cmasAlertCondition) {
      ldTrackMessageEngagement({
        mctAlertTopic: mct_alert_topic,
        currentLocation,
        ldClient,
      });
    }
    if (onClick) onClick(event);
  };

  return (
    <Link onClick={onClickHandler} to={to} {...props}>
      {children}
    </Link>
  );
};
