import React, { FunctionComponent, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import {
  Alert,
  Choice,
  HelpDrawerToggle,
  HelpDrawer,
  CloseIconThin,
} from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../../helpers/intlHooks";
import { LowIncomeSubsidyOption } from "../../@types";
import { NoIconAlert } from "../../components";
import {
  CONSTANTS,
  TealiumConversions,
  linkToContacts,
} from "../../helpers/CONSTANTS";
import { AppContext } from "../../app/store";
import {
  Ga4Event,
  AnalyticsActionType,
} from "../../app/contexts/Analytics/types";
import { ExternalLink } from "../ExternalLink";
import { sendHelpDrawerOpenAnalyticsEvent } from "../../app/contexts/Analytics/events";

interface HowDoYouGetHelpProps {
  answeredTypeOfHelp: (value: LowIncomeSubsidyOption) => void;
}

const HowDoYouGetHelp: FunctionComponent<HowDoYouGetHelpProps> = ({
  answeredTypeOfHelp,
}) => {
  const t = useTranslate();
  const {
    state: { language },
    dispatch,
  } = useContext(AppContext);
  const [showHelp, setShowHelp] = useState<boolean>(false);

  const handleHelpSelection = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const selectedHelpType = e.target.value as LowIncomeSubsidyOption;

    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: "Find Plans - Choose Plans",
        event_label: TealiumConversions[selectedHelpType],
      },
    });
    dispatch({
      type: AnalyticsActionType.SEND_GA4_EVENT,
      settings: {
        event_name: Ga4Event.RADIO_BUTTON_SELECTED,
        text: TealiumConversions[selectedHelpType],
        heading: t("landing_page.lis.do_you_get_help"),
      },
    });

    answeredTypeOfHelp(selectedHelpType);
  };

  return (
    <>
      <legend className="ds-text-heading--xl ds-u-margin--0 ds-u-margin-top--5 ds-u-margin-bottom--1 ds-u-padding--0">
        <h3>{t("landing_page.lis.do_you_get_help")}</h3>
      </legend>
      <Choice
        data-testid="medicaid-option"
        name="helpType"
        type="radio"
        value={LowIncomeSubsidyOption.Medicaid}
        onChange={handleHelpSelection}
        checkedChildren={NoIconAlert(
          "",
          t("landing_page.lis.medicaid_help_description")
        )}
        label={t("landing_page.lis.medicaid_help_label")}
      />

      <Choice
        data-testid="ssi-option"
        name="helpType"
        type="radio"
        value={LowIncomeSubsidyOption.SSI}
        onChange={handleHelpSelection}
        checkedChildren={NoIconAlert(
          "",
          t("landing_page.lis.ssi_help_description")
        )}
        label={t("landing_page.lis.ssi_help_label")}
      />

      <Choice
        name="helpType"
        type="radio"
        value={LowIncomeSubsidyOption.MSP}
        onChange={handleHelpSelection}
        checkedChildren={
          <Alert
            className="ds-u-margin-y--2 ds-u-margin-left--1"
            hideIcon={true}
          >
            {t("landing_page.lis.msp_help_description")}{" "}
            <HelpDrawerToggle
              helpDrawerOpen={showHelp}
              showDrawer={() => {
                if (!showHelp) {
                  sendHelpDrawerOpenAnalyticsEvent({
                    text: t("landing_page.lis.learn_more_about_these_programs"),
                    heading: t("landing_page.lis.msp_help_label"),
                  });
                }
                setShowHelp(true);
              }}
              inline
            >
              {t("landing_page.lis.learn_more_about_these_programs")}
            </HelpDrawerToggle>
            {showHelp && (
              <div className="mct-c-help-drawer">
                <HelpDrawer
                  heading={t("landing_page.lis.msp_help_label")}
                  onCloseClick={(): void => setShowHelp(false)}
                  closeButtonText={<CloseIconThin />}
                  ariaLabel={t("close_help_drawer")}
                >
                  <p>{t("landing_page.lis.msp_help_drawer_intro")}</p>
                  <ol>
                    <li>
                      <FormattedMessage
                        id="landing_page.lis.msp_help_drawer_QMB"
                        values={{
                          b: chunks => <b>{chunks}</b>,
                        }}
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="landing_page.lis.msp_help_drawer_SLMB"
                        values={{
                          b: chunks => <b>{chunks}</b>,
                        }}
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="landing_page.lis.msp_help_drawer_QI"
                        values={{
                          b: chunks => <b>{chunks}</b>,
                        }}
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="landing_page.lis.msp_help_drawer_QDWI"
                        values={{
                          b: chunks => <b>{chunks}</b>,
                        }}
                      />
                    </li>
                  </ol>
                  <p>
                    {t("landing_page.lis.msp_help_drawer_check_if_qualify")}
                    <FormattedMessage
                      id="landing_page.lis.msp_help_drawer_more_info"
                      tagName="span"
                      values={{
                        link: (
                          <a
                            href={linkToContacts(language)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("landing_page.lis.msp_help_drawer_link_text")}
                          </a>
                        ),
                      }}
                    />
                  </p>
                </HelpDrawer>
              </div>
            )}
          </Alert>
        }
        label={t("landing_page.lis.msp_help_label")}
      />

      <Choice
        name="helpType"
        type="radio"
        value={LowIncomeSubsidyOption.SS}
        onChange={handleHelpSelection}
        checkedChildren={NoIconAlert(
          "",
          <FormattedMessage
            id="landing_page.lis.ss_help_description2"
            values={{
              link: chunks => (
                <ExternalLink
                  href={CONSTANTS.getLinkToMedicare({
                    language,
                    mgovUrlKey: "prescriptionCostHelp",
                  })}
                  text={String(chunks)}
                />
              ),
            }}
          />
        )}
        label={t("landing_page.lis.ss_help_label")}
      />
      <Choice
        name="helpType"
        type="radio"
        value={LowIncomeSubsidyOption.Unsure}
        onChange={handleHelpSelection}
        checkedChildren={NoIconAlert(
          "",
          <FormattedMessage
            id="landing_page.lis.unsure_help_description2"
            values={{
              link: chunks => (
                <ExternalLink
                  href={CONSTANTS.getLinkToMedicare({
                    language,
                    mgovUrlKey: "costHelp",
                  })}
                  text={String(chunks)}
                />
              ),
            }}
          />
        )}
        label={t("landing_page.lis.unsure_help_Label")}
      />

      <Choice
        name="helpType"
        data-testid="lis-no-help"
        type="radio"
        value={LowIncomeSubsidyOption.None}
        onChange={handleHelpSelection}
        checkedChildren={NoIconAlert(
          "",
          <FormattedMessage
            id="landing_page.lis.no_help_description"
            values={{
              link: chunks => (
                <ExternalLink
                  href={CONSTANTS.getLinkToMedicare({
                    language,
                    mgovUrlKey: "costHelp",
                  })}
                  text={String(chunks)}
                />
              ),
            }}
          />
        )}
        label={t("landing_page.lis.no_help")}
      />
    </>
  );
};

export default HowDoYouGetHelp;
