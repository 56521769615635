import React, { FunctionComponent, useContext } from "react";
import { Button } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers";
import { AppContext } from "../app/store";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";

interface FootnoteProps {
  id: string;
  className?: string;
  text: string | JSX.Element;
  marker?: string;
  linkMarker?: boolean;
}

const FootnoteMarker: React.FC<{ link?: boolean; marker?: string }> = ({
  link = false,
  marker,
}) => {
  const markerLabel = <sup>{marker}</sup>;
  return marker ? (
    link ? (
      markerLabel
    ) : (
      <Button size="small" variation="ghost" className="Footnote__marker">
        {markerLabel}
      </Button>
    )
  ) : null;
};

const Footnote: FunctionComponent<FootnoteProps> = ({
  className = "",
  id,
  text,
  marker,
  linkMarker = true,
  ...props
}) => {
  const t = useTranslate();
  const { dispatch } = useContext(AppContext);
  const footnoteId = `${id}-footnote`;
  const markerId = `${id}-marker`;

  return (
    <>
      <dt className="Footnote__dt">Footnote {marker}</dt>
      <dd
        id={footnoteId}
        className="Footnote__dd"
        tabIndex={marker ? -1 : undefined}
        {...props}
      >
        <p className={marker ? `ds-u-margin-left--1 ${className}` : ""}>
          {text}
        </p>
        {marker && linkMarker ? (
          <Button
            size="small"
            variation="ghost"
            className="Footnote__marker Footnote__marker--linked"
            aria-label={t("footnote.return_to_content")}
            onClick={(): void => {
              const markerElement: HTMLButtonElement | null =
                document.querySelector(`#${markerId}`);
              markerElement?.scrollIntoView();
              markerElement?.focus();
              dispatch({
                type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
                settings: {
                  button: {
                    buttonStyle: AnalyticsButtonStyle.DEFAULT,
                    buttonType: AnalyticsButtonType.LINK,
                    text: marker,
                  },
                },
              });
            }}
          >
            <FootnoteMarker link={true} marker={marker} />
          </Button>
        ) : (
          <FootnoteMarker marker={marker} />
        )}
      </dd>
    </>
  );
};

export default Footnote;
