import { OecStatus, PlanType } from "../@types";

const oecStatus: OecStatus = {
  confirmation_number: "1",
  year: "1991",
  contract_id: "1234",
  plan_id: "Abcde",
  plan_name: "Acme Insurance Plan",
  plan_name_esp: "Plan de seguro Acme",
  plan_type: PlanType.MAPD,
  segment_id: "5421",
  download_date: "Tuesday",
  downloaded: true,
};

export default oecStatus;
