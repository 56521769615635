import { MedigapPlanPremiumRange } from "../../@types";
import { getMedigapPlanPremiumRanges } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { QueryKey, StaleTime } from "./types";
import { useAppContext } from "../context-hooks";
import { useMemo } from "react";

/**
 * a hook to access medigap plans, based on geographic and demographic info
 */
export const useMedigapPlanPremiumRanges = () => {
  // * Context
  const {
    state: { county, zipcode = "", demographicInfo },
  } = useAppContext();
  const stateAbbrev = county?.state || "";

  // * Queries
  const medigapPlansQuery = useQuery({
    queryKey: [
      QueryKey.MedigapPlanPremiumRanges,
      stateAbbrev,
      zipcode,
      demographicInfo,
    ],
    queryFn: () => {
      return getMedigapPlanPremiumRanges(stateAbbrev, zipcode, demographicInfo);
    },
    enabled: !!(zipcode && stateAbbrev),
    staleTime: StaleTime.Infinite,
  });

  // * Constants
  const medigapPlanPremiumRanges: MedigapPlanPremiumRange[] = useMemo(() => {
    return medigapPlansQuery.data || [];
  }, [medigapPlansQuery.data]);

  const error = medigapPlansQuery.isError;
  const loading = medigapPlansQuery.isPending;

  return {
    /** an object containing medigap plan premium ranges (from medigap/plans endpoint) */
    medigapPlanPremiumRanges,
    /** medigap plans query error */
    error,
    /** medigap plans query loading */
    loading,
  };
};
