import React from "react";
import { useCurrentPlanYear, useTypedTranslate } from "../../helpers";
import { FormattedMessage } from "react-intl";

export const ServiceAreaReductionNotice = ({
  className,
}: {
  className?: string;
}) => {
  const t = useTypedTranslate();
  const currentPlanYear = useCurrentPlanYear();

  return (
    <div className={className}>
      <p>
        <span className="ds-u-font-weight--bold">
          <FormattedMessage
            id="service_area_reduction.not_available_in_some_areas"
            values={{ year: currentPlanYear + 1 }}
          />
        </span>{" "}
        <span>
          {t("service_area_reduction.contact_plan_to_find_out_impact")}
        </span>
      </p>

      {/* <HelpDrawerWrapper
        toggleText=""
        drawerTitle=""
        toggleIcon={
          <span style={{ position: "relative", zIndex: 1 }}>
            <InfoCircleOutlineIcon />
          </span>
        }
        disableClickOutsideHandler={true}
      >
        <h4 className="ds-u-margin-bottom--0">
          
        </h4>
        <p className="ds-u-margin--0">
          
        </p>

        <h4 className="ds-u-margin-bottom--0">
          
        </h4>
        <p className="ds-u-margin--0">
          
        </p>
        <div className="ds-u-margin-top--4">
          <CollapsibleBox
            labelClassName="ds-h4 ds-u-margin--0"
            label=""
          >
            <>
              <p className="ds-u-font-weight--bold">
                
              </p>
              <p>
                
              </p>
              <p>
                
              </p>
              <p className="ds-u-font-weight--bold">
                
              </p>
              <p>
                
              </p>
              <Alert heading="" variation="warn" hideIcon={true}>
                <ul>
                  <li>
                    
                  </li>
                  <li>
                    
                  </li>
                  <li>
                    
                  </li>
                </ul>
              </Alert>
            </>
          </CollapsibleBox>
        </div>
      </HelpDrawerWrapper> */}
    </div>
  );
};
