import { useContext, useMemo } from "react";
import {
  DrivingDistanceInfo,
  Pharmacy,
  PharmacyType,
  UseTranslateTypeStrict,
} from "../@types";
import { AppStore } from "../app/store";
import t from "../translations/en-US.json";
import { mailOrderNpi } from "./CONSTANTS";
import { RouteComponentProps, useLocation } from "react-router-dom";
import {
  convertUriToLocation,
  createTargetLocationWithReturnTo,
  DrugCostDisplayInfoByPharmacy,
  normalizeStringCase,
  stripManagedQueryParamsFromLocation,
  useAppContext,
} from ".";
import Routes from "../app/routes";
import { useReferrerContext } from "../app/contexts/ReferrerContext/context";
import URI from "urijs";
import { getPharmacyDrivingDistanceDisplay } from "./useDistanceFromPharmacy";

export const addPharmacyButtonRegExp = new RegExp(
  `${t["manage_pharmacies.actions.add_another_pharmacy"]}|${t["manage_pharmacies.actions.add_a_pharmacy"]}`
);

export const isManagePharmaciesHeading = (title: string): boolean =>
  title === t["manage_pharmacies.heading"];

/**
 * Normalizes the npi string for mail-order pharmacy, from blank to the constant
 * "mail-order"
 */
export const normalizeNpi = (npi: string) => (npi === "" ? mailOrderNpi : npi);

export const getPharmacyType = (
  pharmacies: Pharmacy[],
  mailOrder: boolean
): PharmacyType => {
  let pharmacyType = PharmacyType.NONE;
  const isRetail = pharmacies.length > 0;
  const isMail = mailOrder;

  if (isRetail && isMail) {
    pharmacyType = PharmacyType.MAIL_AND_RETAIL;
  } else if (isRetail) {
    pharmacyType = PharmacyType.RETAIL;
  } else if (isMail) {
    pharmacyType = PharmacyType.MAIL;
  }

  return pharmacyType;
};

/** hook to quickly access frequently used pharmacy helpers */
export function usePharmacies() {
  // * State
  const { state } = useContext(AppStore.AppContext);
  const { pharmacies, pharmacyType } = state;

  // * Helpers
  const hasMailOrderPharmacies = [
    PharmacyType.MAIL_AND_RETAIL,
    PharmacyType.MAIL,
  ].includes(pharmacyType);

  const hasRetailPharmacies = [
    PharmacyType.MAIL_AND_RETAIL,
    PharmacyType.RETAIL,
  ].includes(pharmacyType);

  return {
    pharmacies,
    pharmacyType,
    hasMailOrderPharmacies,
    hasRetailPharmacies,
  };
}

export const useInNetworkPharmacyLink = (
  planId: string
): RouteComponentProps["location"] => {
  const location = useLocation();
  const referrerContext = useReferrerContext();
  const { goBackTextId: searchGoBackTextId, iconKey: searchIconKey } = new URI(
    location.search
  ).search(true);
  const goBackTextId = searchGoBackTextId || referrerContext.goBackTextId;
  const iconKey = searchIconKey || referrerContext.iconKey;

  const inpfUrl = useMemo(() => {
    const { pathname, search, hash } =
      stripManagedQueryParamsFromLocation(location);
    const currentLocationUri = new URI(pathname).search(search).hash(hash);
    currentLocationUri.setSearch({
      goBackTextId: goBackTextId || undefined,
      iconKey,
    });
    const newUrl = createTargetLocationWithReturnTo({
      currentLocation: convertUriToLocation(currentLocationUri),
      targetLocation: {
        pathname: `${Routes.inNetworkPharmacy.replace(":id", planId)}`,
      },
    });
    return newUrl as RouteComponentProps["location"];
  }, [goBackTextId, iconKey, location, planId]);
  return inpfUrl;
};

/** helper to grab pharmacy distance values from a list of pharmacies */
export const getPharmacyDrivingDistance = (
  npi: string,
  pharmacies: Pharmacy[]
) => {
  const pharm = pharmacies.find(p => {
    return p.npi === npi;
  });
  return {
    driving_distance: pharm?.driving_distance || null,
    driving_distance_units: pharm?.driving_distance_units || null,
  };
};

export const getHasPharmacies = ({
  pharmacies,
  hasMailOrder,
}: {
  pharmacies: Pharmacy[];
  hasMailOrder: boolean;
}) => pharmacies.length > 0 || hasMailOrder;

export const getPharmacyDistanceAnalyticsText = ({
  t,
  pharmacy,
  tmpFeEnableCmasMapPharmacyDistance,
}: {
  t: UseTranslateTypeStrict;
  pharmacy: DrivingDistanceInfo;
  tmpFeEnableCmasMapPharmacyDistance: boolean;
}): string =>
  pharmacy.driving_distance !== null &&
  pharmacy.driving_distance_units !== null &&
  tmpFeEnableCmasMapPharmacyDistance
    ? getPharmacyDrivingDistanceDisplay(t, pharmacy, true) || "null"
    : "null";

export const getPharmacyAnalyticsText = ({
  t,
  pharmacies,
  tmpFeEnableCmasMapPharmacyDistance,
  npisToYearlyCostsMap,
}: {
  t: UseTranslateTypeStrict;
  pharmacies: Pharmacy[];
  tmpFeEnableCmasMapPharmacyDistance: boolean;
  npisToYearlyCostsMap?: DrugCostDisplayInfoByPharmacy;
}): { pharmacyCostText: string; pharmacyDistanceText: string } => {
  const pharmacyCosts = [];
  const pharmacyDistances = [];

  for (const pharmacy of pharmacies) {
    pharmacyDistances.push(
      getPharmacyDistanceAnalyticsText({
        t,
        pharmacy,
        tmpFeEnableCmasMapPharmacyDistance,
      })
    );
    pharmacyCosts.push(
      npisToYearlyCostsMap
        ? npisToYearlyCostsMap[pharmacy.npi]?.formatted || "null"
        : "null"
    );
  }
  return {
    pharmacyCostText: pharmacyCosts.join("|"),
    pharmacyDistanceText: pharmacyDistances.join("|"),
  };
};

export const pharmacyAddressBuilder = ({
  street,
  unit,
  city,
  state,
  zipcode,
}: Pharmacy): string => {
  return `${normalizeStringCase(street)}${
    unit ? ` ${normalizeStringCase(unit)}` : ""
  }, ${normalizeStringCase(city)}, ${state} ${zipcode.substring(0, 5)}`;
};

/**
 * Takes in an `npi` and determines whether the pharmacy is OON
 * For MO, the `npi` can be an empty string or the `mailOrderNpi` constant
 */
export const usePharmacyIsOon = (npi: string) => {
  const {
    state: { mailOrderNetworkStatus, pharmacies },
  } = useAppContext();
  const currentPharmacy = pharmacies.find(p => p.npi === npi);
  const mailOrderIsOON =
    !mailOrderNetworkStatus?.inNetwork && !mailOrderNetworkStatus?.preferred;
  const currentPharmacyIsOon = !!(
    (normalizeNpi(npi) === mailOrderNpi && mailOrderIsOON) ||
    (currentPharmacy &&
      !currentPharmacy.in_network &&
      !currentPharmacy.preferred)
  );
  return currentPharmacyIsOon;
};
