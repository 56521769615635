import React, { FunctionComponent, useEffect, useRef } from "react";
import { County } from "../@types";
import states from "../helpers/states";
import { ChoiceList } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers";

interface StateChoiceListProps {
  className?: string;
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
  countyList: County[];
  onSingleState?: (selection: County) => void;
  onStateSelection: (selection: County) => void;
}

const getSetOfStatesFromCounties = (countyList: County[]): Set<string> =>
  new Set(countyList.map(county => county.state));

const StateChoiceList: FunctionComponent<StateChoiceListProps> = ({
  className = "",
  label = "",
  hasError = false,
  errorMessage = "",
  countyList,
  onSingleState,
  onStateSelection,
}) => {
  const t = useTranslate();
  const choiceListElement = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const setSize = getSetOfStatesFromCounties(countyList).size;

    if (setSize === 1 && choiceListElement.current) {
      const selection = JSON.parse(choiceListElement.current?.input?.value);
      if (onSingleState) {
        onSingleState(selection);
      } else {
        onStateSelection(selection);
      }
    }
  }, [countyList]);

  return (
    <ChoiceList
      type="radio"
      name="state"
      label={label}
      className={`StateChoiceList ${className}`}
      choices={Array.from(getSetOfStatesFromCounties(countyList)).map(
        (state, _, list) => ({
          label: states.find(s => s.abbreviation === state)?.name || "",
          value: JSON.stringify(
            countyList.find((county: County) => county.state === state)
          ),
          defaultChecked: list.length === 1,
          ref: choiceListElement,
        })
      )}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
        onStateSelection(JSON.parse(e.target.value))
      }
      errorMessage={
        hasError
          ? errorMessage ||
            t("state_picker.modal.error_message.state_not_selected")
          : undefined
      }
    />
  );
};

export default StateChoiceList;
