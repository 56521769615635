import React from "react";
import { Stars } from "@cmsgov/ds-medicare-gov";
import lowPerformerIcon from "../assets/star-rating-low-performer.svg";
import highPerformerIcon from "../assets/star-rating-high-performer.svg";
import { PlanDetailsLink } from ".";
import { FormattedMessage } from "react-intl";
import { useTranslate, hideStarRatings, useCurrentPlanYear } from "../helpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  StarRatingError,
  StarRating as StarRatingType,
  SearchResultPlan,
  Plan,
  PlanDetailsPageSection,
} from "../@types";
import { TooltipWithClose } from "./TooltipWithClose";
import { StarRatingErr } from "./StarRatingErr";
import { sendPlanTooltipEvent } from "../helpers/analytics-helpers/tooltip-analytics";

export interface StarRatingProps {
  includeLabel?: boolean;
  plan?: Plan | SearchResultPlan;
  stars?: StarRatingType;
  hideTooltip?: boolean;
  highPerforming?: boolean;
  lowPerforming?: boolean;
  planYear: string;
}

const StarRating: React.FunctionComponent<StarRatingProps> = ({
  includeLabel: originalIncludeLabel,
  plan,
  stars: originalStars,
  hideTooltip,
  highPerforming,
  lowPerforming,
  planYear,
  ...props
}) => {
  const { enableNextPlanYearStarRatings } = useFlags();
  const mctCurrentPlanYear = useCurrentPlanYear();
  const t = useTranslate();

  if (
    hideStarRatings(planYear, {
      enableNextPlanYearStarRatings,
      mctCurrentPlanYear,
    })
  ) {
    return (
      <div className="StarRating" {...props}>
        <div className="StarRating__explanationContainer">
          <span className="StarRating__title">{`${t("star_rating.title")}: ${t(
            "coming_soon"
          )}`}</span>
        </div>
      </div>
    );
  }

  const stars = originalStars || { rating: 0 };
  const includeLabel = originalIncludeLabel !== false;

  const showStars =
    originalStars &&
    !highPerforming &&
    !lowPerforming &&
    originalStars.error === StarRatingError.STAR_ERROR_NO_ERROR &&
    // temp work around for data issue
    originalStars.rating !== 0;

  const showError =
    (originalStars &&
      originalStars.error !== StarRatingError.STAR_ERROR_NO_ERROR) ||
    // temp work around for data issue
    (originalStars &&
      originalStars.rating === 0 &&
      originalStars.error === StarRatingError.STAR_ERROR_NO_ERROR);

  return (
    <div className="StarRating" {...props}>
      {originalStars ? (
        <>
          {includeLabel && (
            <div className="StarRating__explanationContainer">
              {hideTooltip ? (
                <span className="StarRating__title">
                  {t("star_rating.title")}:
                </span>
              ) : (
                <TooltipWithClose
                  onOpen={() => {
                    if (plan) {
                      sendPlanTooltipEvent(plan, t("star_rating.title"));
                    }
                  }}
                  headerText={t("star_rating.title")}
                  title={
                    <>
                      <p className="ds-u-font-size--sm">
                        {t("star_rating.description")}
                      </p>
                      <hr />
                      {plan && (
                        <p className="ds-u-font-size--sm">
                          <PlanDetailsLink
                            section={PlanDetailsPageSection.STAR_RATINGS}
                            label={t("star_rating.learn_more")}
                            plan={plan}
                          />
                        </p>
                      )}
                    </>
                  }
                >
                  {`${t("star_rating.title")}:`}
                </TooltipWithClose>
              )}
            </div>
          )}

          {(lowPerforming || highPerforming) && (
            <div
              className={`StarRating__performer ds-u-font-size--sm ${
                includeLabel ? "" : "StarRating__performer--full"
              }`}
            >
              <img
                className="StarRating__performerIcon e2e-star-rating"
                data-stars={stars.rating}
                src={lowPerforming ? lowPerformerIcon : highPerformerIcon}
                alt={
                  lowPerforming
                    ? t("plan_stars.alt.low_performer")
                    : t("plan_stars.alt.high_performer")
                }
              />
              <span className="mct-u-align-self--end">
                <FormattedMessage
                  id={
                    lowPerforming
                      ? "star_rating.low_performing"
                      : "star_rating.high_performing"
                  }
                  values={{
                    b: chunks => <b>{chunks}</b>,
                  }}
                />
              </span>
            </div>
          )}

          {showStars && (
            <span
              className="StarRating__stars e2e-star-rating"
              data-stars={stars.rating}
            >
              <Stars number={stars.rating} ariaHidden={true} />
              <span className="ds-u-visibility--screen-reader">
                <FormattedMessage
                  id="star_rating.sr_only"
                  values={{
                    totalStars: <strong>{stars.rating}</strong>,
                  }}
                />
              </span>
            </span>
          )}

          {showError && <StarRatingErr plan={plan} stars={originalStars} />}
        </>
      ) : (
        <span
          data-testid="star-rating-not-available"
          className="ds-u-font-size--sm ds-u-color--gray StarRating__message"
        >
          {t("star_rating.not_available")}
        </span>
      )}
    </div>
  );
};

export default StarRating;
