import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
} from "react";
import { Button } from "@cmsgov/ds-medicare-gov";
import { useTranslate } from "../helpers";
import { County } from "../@types";
import { AppStore } from "../app/store";
import { ChangeLocationModal } from "../components";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
  TealiumEventProps,
} from "../app/contexts/Analytics/types";

interface ChangeLocationProps {
  inline?: boolean;
  label?: string;
  open?: boolean;
  receiveCountyZip?: (county: County, zip: string) => void;
  showLocation?: boolean;
  tealiumOpenProps?: TealiumEventProps;
  tealiumSaveProps?: TealiumEventProps;
  onClick?: () => void;
}

const ChangeLocation: FunctionComponent<ChangeLocationProps> = ({
  inline,
  label,
  open,
  receiveCountyZip,
  showLocation,
  tealiumOpenProps,
  tealiumSaveProps,
  onClick,
}) => {
  const t = useTranslate();
  const { state, dispatch } = useContext(AppStore.AppContext);
  const [showModal, setShowModal] = useState(open || false);

  useEffect(() => {
    if (open !== undefined) {
      setShowModal(open);
    }
  }, [open]);

  const closeModal = (): void => setShowModal(false);
  const openModal = (): void => {
    onClick && onClick();

    if (tealiumOpenProps) {
      const { event_action, event_label } = tealiumOpenProps;
      dispatch({
        type: AnalyticsActionType.SEND_TEALIUM_EVENT,
        settings: {
          event_action,
          event_label,
        },
      });
    }
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.TRANSPARENT,
          buttonType: AnalyticsButtonType.BUTTON,
          text: label || t("pharmacy_selection.change_location.button"),
        },
      },
    });
    setShowModal(true);
  };

  return (
    <div
      className={`ChangeLocation ${inline ? "ds-u-display--inline-block" : ""}`}
    >
      <div className="ChangeLocation__buttonGroup">
        {state.county && showLocation && (
          <div className="e2e-current-location ds-u-md-margin-right--2 ChangeLocation__current-location">{`${state.county.name}, ${state.county.state}`}</div>
        )}
        <Button
          variation="ghost"
          className="LocationEditor__toggleButton mct-hide-print"
          onClick={openModal}
        >
          {label || t("pharmacy_selection.change_location.button")}
        </Button>
      </div>

      {showModal && (
        <ChangeLocationModal
          onModalClose={closeModal}
          receiveCountyZip={receiveCountyZip}
          tealiumSaveProps={tealiumSaveProps}
        />
      )}
    </div>
  );
};

export default ChangeLocation;
