import React, { FunctionComponent, useContext } from "react";
import { Button } from "@cmsgov/ds-medicare-gov";
import { AppStore } from "../app/store";
import { ActionType, UserLanguage } from "../@types";
import URI from "urijs";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
} from "../app/contexts/Analytics/types";

interface ToggleLanguageButtonProps {
  className?: string;
}

const ToggleLanguageButton: FunctionComponent<
  ToggleLanguageButtonProps & RouteComponentProps
> = ({ className, history, location }) => {
  const { state, dispatch } = useContext(AppStore.AppContext);

  const isEnglish = state.language === UserLanguage.ENGLISH;

  const switchLanguage = (): void => {
    dispatch({
      type: AnalyticsActionType.SEND_TEALIUM_EVENT,
      settings: {
        event_action: "Header",
        event_label: isEnglish ? "Espanol" : "English",
      },
    });
    dispatch({
      type: ActionType.UPDATE_LANGUAGE,
      payload: isEnglish ? UserLanguage.SPANISH : UserLanguage.ENGLISH,
    });

    const uri = new URI(location.search);
    uri.setQuery(
      "lang",
      isEnglish ? UserLanguage.SPANISH : UserLanguage.ENGLISH
    );
    location.search = uri.search();
    history.replace(location);
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.DEFAULT,
          buttonType: AnalyticsButtonType.BUTTON,
          text: isEnglish ? "Español" : "English",
        },
        linkUrl: uri.toString(),
      },
    });
  };

  // Translating these two strings is not necessary
  return (
    <Button className={className} onClick={switchLanguage}>
      {isEnglish ? "Español" : "English"}
    </Button>
  );
};

export default withRouter(ToggleLanguageButton);
