import React, { useContext, useMemo } from "react";
import {
  SearchResultPlan,
  DrugCostInfo,
  PlanDetailsPageSection,
} from "../../@types";
import { useTranslate } from "../../helpers";
import { AppContext } from "../../app/store";
import { HashLink } from "react-router-hash-link";
import { PlanDetailsLink } from "..";
import { AnalyticsActionType } from "../../app/contexts/Analytics/types";

interface PharmaciesProps {
  drugCostInfo: DrugCostInfo;
  drugSelectionRoute: string;
  onGoToDrugSelection: () => void;
  plan: SearchResultPlan;
  /**
   * if `hasCostsFromPlan` is true, expect to derive `coveredPharmacies` value from
   * `plan.pharmacies`, otherwise from `drugCostInfo.costs`
   **/
  hasCostsFromPlan?: boolean;
}

export const coveredPharamciesContentTestId = "covered_pharmacies_content";

const Pharmacies: React.FunctionComponent<PharmaciesProps> = ({
  drugCostInfo,
  drugSelectionRoute,
  onGoToDrugSelection,
  plan,
  hasCostsFromPlan = false,
}) => {
  const t = useTranslate();
  const {
    state: { pharmacies },
    dispatch,
  } = useContext(AppContext);
  const { costs } = drugCostInfo;
  const coveredPharmacies = useMemo(() => {
    return hasCostsFromPlan
      ? plan.pharmacies.filter(pharmacy => pharmacy.in_network)
      : costs.filter(c => c.in_network && !c.mail_order);
  }, [costs, plan.pharmacies, hasCostsFromPlan]);

  return (
    <div className="PlanCard__section" data-testid="pharmacies">
      <h3 className="PlanCard__section_heading">
        {t("plan_card.section_heading.pharmacies")}
      </h3>
      {pharmacies.length ? (
        <div>
          <span data-testid={coveredPharamciesContentTestId}>
            <span className="PlanCard__data_small">
              {coveredPharmacies.length} {t("of")} {pharmacies.length}
            </span>{" "}
            {t("plan_card.covered_pharmacies")}
          </span>
          <div className="ds-u-margin-top--1">
            <PlanDetailsLink
              label={t("plan_card.pharmacies.view_pharmacies")}
              plan={plan}
              section={PlanDetailsPageSection.DRUG_COVERAGE}
              onClick={(): void => {
                dispatch({
                  type: AnalyticsActionType.SEND_TEALIUM_EVENT,
                  settings: {
                    event_action: "Find Plans - Available Plans",
                    event_label: "View your pharmacies",
                  },
                });
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          <HashLink to={drugSelectionRoute} onClick={onGoToDrugSelection}>
            {t("plan_card.add_your_pharmacies")}
          </HashLink>
          <p className="ds-u-margin--0">
            {t("plan_card.add_your_pharmacies_info")}
          </p>
        </div>
      )}
    </div>
  );
};

export default Pharmacies;
