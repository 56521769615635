import * as React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter as Router } from "react-router-dom";
import App from "./app/App";
import "./index.scss";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { LDFlagSet } from "launchdarkly-js-sdk-common";
import { ldProviderConfig } from "./helpers/launchDarklyHelpers";
import { getLdFlags } from "./api";
import { logError } from "./helpers";
import { ApiError } from "./helpers/errors";
import { QueryProvider } from "./app/query/QueryProvider";

/** @ignore import registerServiceWorker from "./registerServiceWorker"; */

(async () => {
  if (!window.playwright) {
    const { initializeNewRelic } = await import("./helpers/newRelicHelpers");
    initializeNewRelic();
  }

  let bootstrapFlags: LDFlagSet | undefined;

  try {
    const bootstrapResponse = await getLdFlags();
    bootstrapFlags = bootstrapResponse?.flags;
  } catch (e) {
    logError("Unable to retrieve LD bootstrap flags", e as ApiError);
  } finally {
    const LDProvider = await asyncWithLDProvider({
      ...ldProviderConfig,
      options: { ...ldProviderConfig.options, bootstrap: bootstrapFlags },
    });

    const container = document.getElementById("mpf-root") as HTMLElement;
    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <LDProvider>
          <Router>
            <QueryProvider>
              <App />
            </QueryProvider>
          </Router>
        </LDProvider>
      </React.StrictMode>
    );
  }
})();
/* @ignore registerServiceWorker();

// Unregister Service Workers, if present
navigator.serviceWorker.getRegistrations().then(registrations => {
  for (const registration of registrations) {
    registration.unregister();
  }
}); */
