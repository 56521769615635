import React, { useRef, useEffect, useState, useContext } from "react";
import { ArrowIcon } from "@cmsgov/ds-medicare-gov";
import Collapsible from "react-collapsible";
import { FormattedMessage } from "react-intl";
import {
  Ga4Event,
  Ga4EventType,
  TealiumEventProps,
} from "../app/contexts/Analytics/types";
import classNames from "classnames";
import { AppContext } from "../app/store";
import { AnalyticsActionType } from "../app/contexts/Analytics/types";
import { ExpandIcon } from ".";

export interface CollapsibleBoxProps
  extends Omit<React.ComponentProps<typeof Collapsible>, "label" | "trigger"> {
  label?: string | JSX.Element;
  labelId?: string;
  labelClassName?: string;
  titleClassname?: string;
  containerClassname?: string;
  contentClassname?: string;
  caretClassname?: string;
  open?: boolean;
  onOpening?: () => void;
  onOpen?: () => void;
  onClosing?: () => void;
  tealiumProps?: TealiumEventProps;
  ga4Props?: {
    event_name: Ga4Event;
    sub_section?: string;
    header?: string;
  };
  plusMinusIcon?: boolean;
  iconPosition?: "left" | "right";
}

const CollapsibleBox: React.FunctionComponent<CollapsibleBoxProps> = ({
  caretClassname,
  children,
  containerClassname,
  contentClassname,
  ga4Props,
  iconPosition = "right",
  label,
  labelId,
  labelClassName,
  onClosing,
  onOpen,
  onOpening,
  open,
  plusMinusIcon = false,
  tealiumProps,
  titleClassname = "",
  ...props
}) => {
  const triggerRef = useRef<HTMLParagraphElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [hideContent, setHideContent] = useState(!open);
  const { dispatch } = useContext(AppContext);
  const labelRef = useRef<HTMLSpanElement>(null);

  const LabelContent: React.FC<{
    className?: string;
    style?: Record<string, unknown>;
  }> = ({ className, style } = { style: {} }): JSX.Element => {
    const _className = classNames("Collapsible__trigger-label", className);
    if (label) {
      return (
        <span className={_className} style={style} ref={labelRef}>
          {label}
        </span>
      );
    } else if (labelId) {
      return (
        <span className={_className} style={style} ref={labelRef}>
          <FormattedMessage id={labelId} />
        </span>
      );
    }

    return <span></span>;
  };
  const labelOrder = iconPosition === "left" ? 1 : 0;
  const iconOrder = iconPosition === "left" ? 0 : 1;
  const TriggerMessage: React.FunctionComponent<{ open?: boolean }> = ({
    open,
  }) => {
    return (
      <span ref={triggerRef} className="ds-u-display--flex">
        <LabelContent
          className={labelClassName}
          style={{ order: labelOrder }}
        />{" "}
        <span
          aria-hidden
          style={{ order: iconOrder }}
          className="mct-u-display--inline-flex"
        >
          {plusMinusIcon ? (
            <ExpandIcon
              expanded={!!open}
              className="ds-u-margin-right--1 mct-c-icon--variant-semibold"
            />
          ) : (
            <ArrowIcon
              direction={open ? "up" : "down"}
              className={`ds-u-valign--middle ds-u-margin-left--1 ${
                caretClassname || ""
              }`}
            />
          )}
        </span>
      </span>
    );
  };

  const toggleAriaExpanded = (value: string): void => {
    if (triggerRef.current && triggerRef.current.parentElement) {
      triggerRef.current.parentElement.setAttribute("aria-expanded", value);
    }
    setHideContent(value === "true" ? false : true);
  };

  const updateContentVisibility = (): void => {
    if (contentRef.current) {
      const outer = contentRef.current.closest(".Collapsible__contentOuter");
      if (outer && outer.clientHeight !== 0) {
        setHideContent(false);
      } else {
        setHideContent(true);
      }
    }
  };

  useEffect(() => {
    if (triggerRef.current && triggerRef.current.parentElement) {
      triggerRef.current.parentElement.setAttribute(
        "aria-expanded",
        open ? "true" : "false"
      );
    }

    // some components artificially hide the collapsible on different
    // breakpoints, so we want to make sure the content has the correct
    // visibility on render and resize
    updateContentVisibility();

    window.addEventListener("resize", updateContentVisibility);
    return (): void =>
      window.removeEventListener("resize", updateContentVisibility);
  }, []);

  return (
    <Collapsible
      contentInnerClassName={contentClassname}
      contentOuterClassName={containerClassname}
      open={open}
      easing={"ease-in-out"}
      onOpening={(): void => {
        toggleAriaExpanded("true");
        if (onOpening) onOpening();
      }}
      onOpen={(): void => {
        if (onOpen) onOpen();
        if (tealiumProps) {
          const { event_action, event_label } = tealiumProps;
          dispatch({
            type: AnalyticsActionType.SEND_TEALIUM_EVENT,
            settings: { event_action, event_label },
          });
        }
        if (ga4Props) {
          dispatch({
            type: AnalyticsActionType.SEND_GA4_EVENT,
            settings: ga4Props,
          });
        }
        dispatch({
          type: AnalyticsActionType.SEND_GA4_EVENT,
          settings: {
            event_name: Ga4Event.ACCORDION_OPENED,
            event_type: Ga4EventType.UI_INTERACTION,
            text: labelRef.current?.textContent || "Collapsible header",
          },
        });
      }}
      onClose={(): void => {
        if (tealiumProps) {
          const { event_action, event_label } = tealiumProps;
          dispatch({
            type: AnalyticsActionType.SEND_TEALIUM_EVENT,
            settings: { event_action, event_label },
          });
        }
        dispatch({
          type: AnalyticsActionType.SEND_GA4_EVENT,
          settings: {
            event_name: Ga4Event.ACCORDION_CLOSED,
            event_type: Ga4EventType.UI_INTERACTION,
            text: labelRef.current?.textContent || "Collapsible header",
          },
        });
      }}
      onClosing={(): void => {
        toggleAriaExpanded("false");
        if (onClosing) onClosing();
      }}
      tabIndex={0}
      trigger={<TriggerMessage />}
      triggerTagName="button"
      triggerWhenOpen={<TriggerMessage open />}
      triggerClassName={`Collapsible__trigger--default ${titleClassname}`}
      triggerOpenedClassName={`Collapsible__trigger--default ${titleClassname}`}
      {...props}
    >
      <div
        ref={contentRef}
        className={
          hideContent
            ? "Collapsible__hidden_content"
            : "Collapsible__visible_content"
        }
      >
        {children}
      </div>
    </Collapsible>
  );
};

export default CollapsibleBox;
