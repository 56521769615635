import { ActionType, NewRelicPageActionNames } from "../../@types";
import {
  ApiError,
  addNewRelicPageAction,
  hasSessionAndBene,
  logError,
} from "../../helpers";
import {
  ExtendSessionParams,
  UseIdleTimeoutTestHelperParams,
  EndSessionParams,
  HandleCsrPresenceChangeParams,
  IdleTimeoutActivityState,
  HandleUserActionParams,
  SlsSessionInfo,
  HandlePresenceChangeParams,
} from "./types";
import {
  CSR_FORCE_LOGOUT_TIMEOUT_MS,
  CSR_IDLE_TIMEOUT_MS,
  FORCE_LOGOUT_TIMEOUT_MS,
} from "./constants";
import { getGlobalSessionInfo, sendKeepAlive } from "../../api";
import {
  AnalyticsActionType,
  AnalyticsButtonStyle,
  AnalyticsButtonType,
  Ga4Event,
} from "../../app/contexts/Analytics/types";
import { useEffect, useState } from "react";
import messages from "../../translations/en-US.json";

/**
 * Gets bene session timeout info from SLS and returns a time remaining value
 * (in milliseconds) by subtracting `Date.now()`
 *
 * On error, logs and returns 0, indicating the bene has no session
 */
const getBeneSessionExpiresMs = async (): Promise<number> => {
  let timeUntilSessionExpiresMs = 0;
  try {
    const session = await getGlobalSessionInfo();
    if (!session) {
      throw new Error("Could not retrieve SLS session information");
    }
    const { exp: sessionExpirationInSeconds } = session;
    const sessionExpirationInMs = sessionExpirationInSeconds * 1000;
    timeUntilSessionExpiresMs = sessionExpirationInMs - Date.now();
    console.debug(
      `⏲️ time remaining in session: ${parseFloat(
        `${timeUntilSessionExpiresMs / 1000 / 60}`
      ).toFixed(2)} minutes`
    );
  } catch (err) {
    const e = err as ApiError;
    const message = e.message || "";
    logError(`Error calling /api/sls/session/info: ${message}`, e);
  }
  return timeUntilSessionExpiresMs;
};

export const getTimeoutTimesFromSessionRemainingMs = ({
  timeUntilSessionExpiresMs,
  isCsr = false,
}: {
  timeUntilSessionExpiresMs: number;
  isCsr?: boolean;
}): SlsSessionInfo => {
  const info: SlsSessionInfo = {
    idleTimeoutMs: 0,
    forceLogoutTimeoutMs: 0,
    timeUntilSessionExpiresMs: timeUntilSessionExpiresMs,
    sessionExpired: false,
  };
  // Time's up!!
  if (timeUntilSessionExpiresMs === 0) {
    return { ...info, sessionExpired: true };
  }
  // Time's not up!!
  // Set forceLogoutTimeoutMs for benes or CSRs (configurable)
  const forceLogoutTimeoutMs = getForceLogoutTimeoutMs({ isCsr });
  /**
   * The amount of time before a bene should be shown a session-timeout warning
   * prompt. If this value is not greater than zero, a `forceLogoutTimeoutMs`
   * value will be returned, which is the amount of time before the bene
   * will be programmatically logged out if they remain idle.
   * @TODO - verify timings for CSRs, given that their force logout timeout may differ?
   */
  const idleTimeoutMs = timeUntilSessionExpiresMs - forceLogoutTimeoutMs;

  if (idleTimeoutMs > 0) {
    info.idleTimeoutMs = idleTimeoutMs;
  } else {
    info.forceLogoutTimeoutMs = timeUntilSessionExpiresMs;
  }
  return info;
};

export const getActivityStateFromSessionRemainingMs = ({
  isCsr = false,
  timeUntilSessionExpiresMs,
}: {
  isCsr?: boolean;
  timeUntilSessionExpiresMs: number;
}) => {
  const { idleTimeoutMs, forceLogoutTimeoutMs } =
    getTimeoutTimesFromSessionRemainingMs({ isCsr, timeUntilSessionExpiresMs });
  switch (true) {
    case idleTimeoutMs > 0:
      return IdleTimeoutActivityState.ACTIVE;
    case forceLogoutTimeoutMs > 0:
      return IdleTimeoutActivityState.PROMPT;
    default:
      return IdleTimeoutActivityState.IDLE;
  }
};

/**
 * Gets time remaining in bene session from SLS, and  returns an `SlsSessionInfo`
 * object
 */
const getBeneSessionInfo = async (): Promise<SlsSessionInfo> => {
  const timeUntilSessionExpiresMs = await getBeneSessionExpiresMs();
  return getTimeoutTimesFromSessionRemainingMs({ timeUntilSessionExpiresMs });
};

/**
 * Use instead of directly calling `logout` to log a session timeout (on New Relic)
 *
 * If logout should display the toast "You have successfully logged out" instead
 * of "Your session has expired," pass the optional `{sessionHasExpired: false}`
 *
 * Passing in `timeUntilSessionExpiresMs` indicates that the SLS timeout
 * time has just been fetched, and will not be fetched again
 */
export const endSession = async ({
  beneficiary,
  forceLogoutModalShownRef,
  getLastActiveTime,
  isCsr,
  isLoggedIn,
  logout,
  onBeforeLogout = () => {},
  syncWithSls,
}: EndSessionParams) => {
  const shouldGetSlsTimeoutTime = !isCsr && syncWithSls;
  let timeUntilSessionExpiresMs = 0;
  let { forceLogoutTimeoutMs, idleTimeoutMs, sessionExpired } =
    getTimeoutTimesFromSessionRemainingMs({
      timeUntilSessionExpiresMs,
      isCsr,
    });

  // @TODO - is there any reason for this? All paths that lead to calling
  // `endSession` seem to follow directly on a call to SLS
  // The active logout (button in force logout modal) currently doesn't call `endSession`
  // but uses `logout` directly
  if (shouldGetSlsTimeoutTime) {
    const {
      forceLogoutTimeoutMs: fetchedForceLogoutTimeoutMs,
      idleTimeoutMs: fetchedIdleTimeoutMs,
      sessionExpired: fetchedSessionExpired,
      timeUntilSessionExpiresMs: fetchTimeUntilSessionExpiresMs,
    } = await getBeneSessionInfo();
    sessionExpired = fetchedSessionExpired;
    forceLogoutTimeoutMs = fetchedForceLogoutTimeoutMs;
    idleTimeoutMs = fetchedIdleTimeoutMs;
    timeUntilSessionExpiresMs = fetchTimeUntilSessionExpiresMs;
  }

  if (isLoggedIn && !sessionExpired) {
    return { timeUntilSessionExpiresMs };
  }

  addNewRelicPageAction(NewRelicPageActionNames.LOGOUT, {
    activityId: beneficiary?.activity_id || "",
    beneficiaryKey: beneficiary?.meta_data?.beneficiary_key || "",
    csrId: beneficiary?.csr_id || "",
    currentTimeUtc: new Date().toUTCString(),
    forceLogoutModalShown: `${forceLogoutModalShownRef.current}`,
    forceLogoutTimeoutMs: `${forceLogoutTimeoutMs}`,
    hasSessionAndBene: `${hasSessionAndBene(beneficiary)}`,
    idleTimeoutMs: `${idleTimeoutMs}`,
    isLoggedIn: `${isLoggedIn}`,
    lastActivityUtc: getLastActiveTime()?.toUTCString() || "",
    slsSessionExpired: `${sessionExpired}`,
  });

  onBeforeLogout();

  return logout({ sessionHasExpired: true });
};

export const getForceLogoutTimeoutMs = ({ isCsr }: { isCsr: boolean }) =>
  isCsr ? CSR_FORCE_LOGOUT_TIMEOUT_MS : FORCE_LOGOUT_TIMEOUT_MS;

const handleCsrPresenceChange = ({
  presence,
}: HandleCsrPresenceChangeParams) => {
  const isIdle = presence.type === "idle";
  const isPrompted = !isIdle && presence.prompted;
  return {
    timeUntilSessionExpiresMs: isIdle
      ? 0
      : isPrompted
      ? CSR_FORCE_LOGOUT_TIMEOUT_MS
      : // isActive is the fallback here. Full session time should be set to the
        // starting values
        CSR_FORCE_LOGOUT_TIMEOUT_MS + CSR_IDLE_TIMEOUT_MS,
  };
};

export const handlePresenceChange = async ({
  activityState,
  isCsr,
  isLoggedIn,
  presence,
}: HandlePresenceChangeParams): Promise<{
  timeUntilSessionExpiresMs: number | null;
}> => {
  const nullResponse = {
    timeUntilSessionExpiresMs: null,
  };
  if (!isLoggedIn) {
    return nullResponse;
  }
  const isIdle = presence.type === "idle";
  const isActive = presence.type === "active" && !presence.prompted;
  const isPrompted =
    presence.type === "active" &&
    presence.prompted &&
    // Keep `isPrompted` presence state from looping after modal is shown
    !(activityState === IdleTimeoutActivityState.PROMPT);
  const shouldHandlePresenceChange = isActive || isPrompted || isIdle;
  if (!shouldHandlePresenceChange) {
    return nullResponse;
  }
  console.debug(
    `🐮 presence change or login event, currently timer state is ${
      !isPrompted ? presence.type : ""
    }${
      isPrompted ? "prompted" : ""
    } syncing with SLS or updating times for CSRs`
  );
  return isCsr ? handleCsrPresenceChange({ presence }) : await syncWithSls();
};

/**
 * A catch-all callback for the idle timer's presence change event
 *
 * Checks SLS session values, returns a response object that tells the `IdleTimeout`
 * component whether to update remaining session time (also provided), optionally
 * start the timer, or end the session
 */
const syncWithSls = async (): Promise<{
  timeUntilSessionExpiresMs: number;
}> => {
  const {
    idleTimeoutMs,
    forceLogoutTimeoutMs,
    sessionExpired,
    timeUntilSessionExpiresMs,
  } = await getBeneSessionInfo();
  console.debug(
    `🐝 syncWithSls called - idleTimeoutMs: ${idleTimeoutMs}, forceLogoutTimeoutMs: ${forceLogoutTimeoutMs}, timeUntilSessionExpiresMs: ${timeUntilSessionExpiresMs}, sessionExpired: ${sessionExpired}`
  );
  return {
    timeUntilSessionExpiresMs,
  };
};

const keepAlive = async () => {
  let shouldEndSession = false;
  try {
    console.debug(`🦄 requesting to update session via /beneinfo/keep-alive`);
    await sendKeepAlive();
  } catch (err) {
    const e = err as ApiError;
    const message = e.message || "";
    logError(`Error calling /beneinfo/keep-alive: ${message}`, e);
    // @TODO - is this the correct thing to do, and should we handle any differently
    // than a call directly to SLS? Is this a case when we should call `endSession`
    // without the `immediate` prop set to `true` (i.e., double-check with SLS before logout)?
    shouldEndSession = true;
  }
  return { shouldEndSession };
};

/**
 * Respond to any user action that the idle timer is keeping track of.
 * This includes many mouse and keyboard events, as well as tabbing away and back.
 * If the user is logged in and active, update last active time and call `keepAlive`
 */
export const handleUserAction = async ({
  activityState,
  dispatch,
  event,
  idleTimer,
  isCsr,
  isLoggedIn,
}: HandleUserActionParams): Promise<{
  shouldEndSession: boolean;
  shouldUpdateRemainingTime: boolean;
}> => {
  const noActionResponse = {
    shouldEndSession: false,
    shouldUpdateRemainingTime: false,
  };
  if (!isLoggedIn) {
    return noActionResponse;
  }
  const isNotPresenceActive = idleTimer?.isIdle() || idleTimer?.isPrompted();
  const isNotActivityStateActive =
    activityState !== IdleTimeoutActivityState.ACTIVE;
  const isNotActive = isCsr
    ? !!isNotPresenceActive || isNotActivityStateActive
    : isNotActivityStateActive;
  if (isNotActive) {
    return noActionResponse;
  }
  console.debug("🐸 onAction called", event?.type);
  dispatch({
    type: ActionType.UPDATE_LAST_ACTIVITY,
    payload: idleTimer?.getLastActiveTime(),
  });
  const { shouldEndSession } = await keepAlive();
  return {
    shouldEndSession,
    shouldUpdateRemainingTime: !shouldEndSession,
  };
};

/**
 * Use to declaratively extend a bene's session.
 * Do not call on every user action, in order to limit calls to SLS.
 * The timer's presence change events will trigger those calls and times will
 * be updated as needed at that point
 * - Calls `keepAlive` to extend SLS session timeout to initial value
 * - If sucessful, calls SLS to get current session times
 * - Dispatches events for analytics
 * - Returns the time until the session expires (could also be 0, which would
 * trigger the component to end the session)
 */
export const extendSession = async ({
  beneficiary,
  dispatch,
  isCsr,
  userInitiated = false,
  initialSessionTimeoutMsRef,
}: ExtendSessionParams): Promise<{
  timeUntilSessionExpiresMs: number;
}> => {
  const { shouldEndSession } = await keepAlive();
  if (shouldEndSession) {
    return { timeUntilSessionExpiresMs: 0 };
  }
  const timeUntilSessionExpiresMs = isCsr
    ? initialSessionTimeoutMsRef.current
    : (await getBeneSessionInfo()).timeUntilSessionExpiresMs;

  if (userInitiated) {
    dispatch({
      type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT,
      settings: {
        button: {
          buttonStyle: AnalyticsButtonStyle.PRIMARY,
          buttonType: AnalyticsButtonType.BUTTON,
          text: messages["user_activity.continue_session"],
        },
      },
    });
  }

  dispatch({
    type: AnalyticsActionType.SEND_GA4_EVENT,
    settings: {
      event_name: Ga4Event.CONTINUE_IDLE_SESSION,
    },
  });

  dispatch({
    type: AnalyticsActionType.SEND_TEALIUM_EVENT,
    settings: {
      event_label: "mct_plan_finder_continue_idle_session",
      event_action: "idle session - continue session",
      other_props: {
        beneficiary_key: beneficiary?.meta_data.beneficiary_key,
        csr_id: beneficiary?.csr_id,
      },
    },
  });

  return { timeUntilSessionExpiresMs };
};

/**
 * Provides continually updating values to to optional debug info window that's
 * rendered when the env value `REACT_APP_FORCE_LOGOUT_TIMEOUT_INFO` is set to `true`
 */
export const useIdleTimeoutTestHelper = ({
  forceLogoutTimeoutMs,
  getElapsedTime,
  getLastActiveTime,
  getRemainingTime,
  isLoggedIn,
  logoutTimeoutStartTime,
  showForceLogoutModal,
}: UseIdleTimeoutTestHelperParams) => {
  const [remaining, setRemaining] = useState(0);
  const [lastActive, setLastActive] = useState<Date | null>(null);
  const [elapsed, setElapsed] = useState(0);
  const [timeToLogout, setTimeToLogout] = useState<number | null>(null);
  useEffect(() => {
    const interval = isLoggedIn
      ? setInterval(() => {
          const promptTimeLeft = showForceLogoutModal
            ? forceLogoutTimeoutMs - (Date.now() - logoutTimeoutStartTime)
            : null;
          setRemaining(getRemainingTime());
          setElapsed(getElapsedTime());
          setLastActive(getLastActiveTime());
          setTimeToLogout(promptTimeLeft);
        }, 1000)
      : (1 as unknown as NodeJS.Timeout);
    return () => {
      isLoggedIn && clearInterval(interval);
    };
  }, [
    forceLogoutTimeoutMs,
    getElapsedTime,
    getLastActiveTime,
    getRemainingTime,
    isLoggedIn,
    logoutTimeoutStartTime,
    showForceLogoutModal,
  ]);
  return { elapsed, lastActive, remaining, timeToLogout };
};
