import { Plan, SearchResultPlan } from "../../@types";
import { sendAnalyticsEvent } from "../../app/contexts/Analytics";
import {
  Ga4Event,
  Ga4EventDimensions,
  LinkType,
} from "../../app/contexts/Analytics/types";
import { makePlanLongId } from "../objectUtilities";

export function sendTooltipEvent(
  event: Omit<Ga4EventDimensions, "event_name" | "link_type">
) {
  sendAnalyticsEvent({
    ...event,
    event_name: Ga4Event.TOOLTIP_VIEWED,
    link_type: LinkType.LINK_OTHER,
  });
}

export function sendPlanTooltipEvent(
  plan: Plan | SearchResultPlan,
  text: string
) {
  sendTooltipEvent({
    text,
    star_rating: plan.overall_star_rating?.rating,
    plan_name: plan.name,
    insurance_carrier: plan.organization_name,
    plan_type: plan.plan_type,
    plan_id: makePlanLongId(plan),
  });
}
