import {
  DrugCostInfo,
  PrescriptionFrequency,
  DrugCostCoverageReason,
} from "../@types";

const drugCostInfo: DrugCostInfo = {
  costs: [
    {
      mail_order: false,
      npi: "123",
      drug_costs: [
        {
          ndc: "12345",
          quantity: 1,
          frequency: PrescriptionFrequency.DAYS_30,
          full_cost: 0.2215,
          deductible_cost: 0.2215,
          initial_cost: 0.2215,
          gap_cost: 0.2215,
          catastrophic_cost: 0.2215,
          covered: false,
          default_price_used: false,
          coverage_reason: DrugCostCoverageReason.COVERED,
          estimated_yearly_total: 1234.56,
        },
      ],
      estimated_monthly_costs: [
        {
          date: "2019-06-01",
          deductible: 0.22,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.22,
        },
        {
          date: "2019-07-01",
          deductible: 0.22,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.22,
        },
        {
          date: "2019-08-01",
          deductible: 0.22,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.22,
        },
        {
          date: "2019-09-01",
          deductible: 0.22,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.22,
        },
        {
          date: "2019-10-01",
          deductible: 0.22,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.22,
        },
        {
          date: "2019-11-01",
          deductible: 0.22,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.22,
        },
        {
          date: "2019-12-01",
          deductible: 0.22,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.22,
        },
      ],
      in_network: false,
      preferred: false,
      phase_information: {
        deductible_amount: 415,
        initial_coverage_start: "",
        gap_start: "",
        catastrophic_start: "",
      },
      estimated_yearly_total: 1.5400001,
      ltc: false,
      medicare_prescription_payment_plan: {
        estimated_monthly_costs: [
          {
            date: "2025-01-01",
            mppp_total: 166.67,
            part_d_only_total: 276.4,
          },
          {
            date: "2025-02-01",
            mppp_total: 166.68,
            part_d_only_total: 276.41,
          },
          {
            date: "2025-03-01",
            mppp_total: 166.69,
            part_d_only_total: 276.42,
          },
          {
            date: "2025-04-01",
            mppp_total: 166.7,
            part_d_only_total: 276.43,
          },
          {
            date: "2025-05-01",
            mppp_total: 166.71,
            part_d_only_total: 276.44,
          },
          {
            date: "2025-06-01",
            mppp_total: 166.72,
            part_d_only_total: 276.45,
          },
          {
            date: "2025-07-01",
            mppp_total: 166.73,
            part_d_only_total: 276.46,
          },
          {
            date: "2025-08-01",
            mppp_total: 166.74,
            part_d_only_total: 276.47,
          },
          {
            date: "2025-09-01",
            mppp_total: 166.75,
            part_d_only_total: 276.48,
          },
          {
            date: "2025-10-01",
            mppp_total: 166.76,
            part_d_only_total: 276.49,
          },
          {
            date: "2025-11-01",
            mppp_total: 166.77,
            part_d_only_total: 276.5,
          },
          {
            date: "2025-12-01",
            mppp_total: 166.78,
            part_d_only_total: 276.51,
          },
        ],
        estimated_yearly_mppp_cost: 2000.0,
        estimated_yearly_part_d_only_cost: 2000.0,
        meets_annual_cap: true,
      },
    },
    {
      mail_order: true,
      npi: "",
      drug_costs: [
        {
          ndc: "23456",
          quantity: 3,
          frequency: PrescriptionFrequency.DAYS_90,
          full_cost: 0.7089,
          deductible_cost: 0.7089,
          initial_cost: 0.7089,
          gap_cost: 0.7089,
          catastrophic_cost: 0.7089,
          covered: true,
          default_price_used: false,
          coverage_reason: DrugCostCoverageReason.COVERED,
          estimated_yearly_total: 123.44,
        },
      ],
      estimated_monthly_costs: [
        {
          date: "2019-06-01",
          deductible: 0.71,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.71,
        },
        {
          date: "2019-07-01",
          deductible: 0.71,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.71,
        },
        {
          date: "2019-08-01",
          deductible: 0.71,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.71,
        },
        {
          date: "2019-09-01",
          deductible: 0.71,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.71,
        },
        {
          date: "2019-10-01",
          deductible: 0.71,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.71,
        },
        {
          date: "2019-11-01",
          deductible: 0.71,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.71,
        },
        {
          date: "2019-12-01",
          deductible: 0.71,
          initial: 0,
          gap: 0,
          catastrophic: 0,
          total: 0.71,
        },
      ],
      in_network: false,
      preferred: false,
      phase_information: {
        deductible_amount: 415,
        initial_coverage_start: "",
        gap_start: "",
        catastrophic_start: "",
      },
      estimated_yearly_total: 4.97,
      ltc: false,
      medicare_prescription_payment_plan: {
        estimated_monthly_costs: [
          {
            date: "2025-01-01",
            mppp_total: 166.67,
            part_d_only_total: 276.4,
          },
          {
            date: "2025-02-01",
            mppp_total: 166.68,
            part_d_only_total: 276.41,
          },
          {
            date: "2025-03-01",
            mppp_total: 166.69,
            part_d_only_total: 276.42,
          },
          {
            date: "2025-04-01",
            mppp_total: 166.7,
            part_d_only_total: 276.43,
          },
          {
            date: "2025-05-01",
            mppp_total: 166.71,
            part_d_only_total: 276.44,
          },
          {
            date: "2025-06-01",
            mppp_total: 166.72,
            part_d_only_total: 276.45,
          },
          {
            date: "2025-07-01",
            mppp_total: 166.73,
            part_d_only_total: 276.46,
          },
          {
            date: "2025-08-01",
            mppp_total: 166.74,
            part_d_only_total: 276.47,
          },
          {
            date: "2025-09-01",
            mppp_total: 166.75,
            part_d_only_total: 276.48,
          },
          {
            date: "2025-10-01",
            mppp_total: 166.76,
            part_d_only_total: 276.49,
          },
          {
            date: "2025-11-01",
            mppp_total: 166.77,
            part_d_only_total: 276.5,
          },
          {
            date: "2025-12-01",
            mppp_total: 166.78,
            part_d_only_total: 276.51,
          },
        ],
        estimated_yearly_mppp_cost: 2000.0,
        estimated_yearly_part_d_only_cost: 2000.0,
        meets_annual_cap: true,
      },
    },
  ],
  cumulative_meets_annual_cap: false,
  lowest_retail_total: 1.5400001,
  lowest_mail_total: 4.97,
  restrictions: [
    {
      ndc: "123",
      conditions: [""],
      opioid: false,
      home_infusion: false,
      free_first_fill: false,
      partial_gap: false,
      limited_distribution: false,
      vaccine: true,
      insulin_price_cap: false,
      acip: false,
    },
  ],
  plan: {
    contract_id: "H1660",
    contract_year: "2019",
    plan_id: "0",
    segment_id: "0",
  },
  excluded_drugs: [""],
};

export default drugCostInfo;
