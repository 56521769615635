import { SearchResultPlan, PlanResults } from "../@types";
import searchResultPlan from "./searchResultPlan";

const searchResultPlans: SearchResultPlan[] = [];
for (let i = 0; i < 3; i++) {
  const searchResult = searchResultPlan({ id: i });
  searchResultPlans.push(searchResult);
}

const planResults: PlanResults = {
  plans: searchResultPlans,
  request_id: "1",
  total_results: 3,
  search_summary: {
    dental: 3,
    drugs: 3,
    excluded: 3,
    hearing: 3,
    national: 3,
    no_drugs: 3,
    organization_names: ["Those Guys, Inc."],
    vision: 3,
    sanctioned: 0,
    categories: {
      hmo: 6,
      ppo: 2,
      pffs: 0,
      msa: 1,
      cost: 0,
    },
    senior_savings_model: 7,
  },
};

export function getANumberOfSearchResultPlans(
  quantity: number,
  attributes?: Array<keyof Pick<SearchResultPlan, "plan_id" | "segment_id">>,
  startingValue = 0
): PlanResults {
  const searchResultPlans: SearchResultPlan[] = [];
  for (let i = startingValue; i < quantity + startingValue; i++) {
    const iteratedArgs: Record<string, unknown> = { id: i - startingValue };
    if (attributes) {
      attributes.forEach(attribute => (iteratedArgs[attribute] = `${i}`));
    }
    const searchResult = searchResultPlan(iteratedArgs);
    searchResultPlans.push(searchResult);
  }
  return { ...planResults, plans: searchResultPlans, total_results: quantity };
}

export default planResults;
